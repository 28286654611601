import React, { useEffect, useState } from 'react';

import OnBoardingContainer from '../../components/OnBoardingContainer';
import OnBoardingTemplate from '../../components/OnBoardingTemplate';
import OnBoardingButtonList from '../../components/OnBoardingButtonList';

import { onBoardingTranslate } from 'services/remoteConfig';
import { mpTrack } from 'services/mixpanel';

import { placeOfWorkButtons } from 'screen/auth/registration/onBoarding/constants/onBoardingButtonData';
import { COACH, PROFESSIONAL, REGISTRATION_DATA, RETAIL_STORE } from 'constant/constants';

function PlaceOfWork() {

  const userType = JSON.parse(
    localStorage.getItem(REGISTRATION_DATA),
  )?.userType;

  const [pageTitle, setPageTitle] = useState('');

  useEffect(() => {
    if (userType === COACH) {
      mpTrack('Web App OnBoarding Coach Place Of Work Selection Screen Viewed');
      setPageTitle(onBoardingTranslate('CoachPlaceOfWorkHeadline'));
    }
    else if (userType === PROFESSIONAL) {
      mpTrack('Web App OnBoarding Professional Place Of Work Selection Screen Viewed');
      setPageTitle(onBoardingTranslate('CoachPlaceOfWorkHeadline'));
    } else if (userType === RETAIL_STORE) {
      mpTrack('Web App OnBoarding Retail Store Place Of Work Selection Screen Viewed');
      setPageTitle(onBoardingTranslate('StoreTypeSelection'));
    }

  }, [userType]);

  return (
    <OnBoardingContainer previous>
      <OnBoardingTemplate
        title={pageTitle}
      >
        <div style={styles.buttonContainer}>
          <OnBoardingButtonList data={placeOfWorkButtons[userType]} field={'typology'} />
        </div>
      </OnBoardingTemplate>
    </OnBoardingContainer>
  );
}
const styles = {
  buttonContainer: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-evenly',
    width: '100%',
  },
};
export default PlaceOfWork;
