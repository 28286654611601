import TextComponent from "components/atoms/TextComponent";
import { BOLD, H5 } from "constant/constants";
import React from "react";
import { colors } from "themes";
import { ReactComponent as TrashIcon } from 'assets/svg/trash.svg';

function ResetFreeTrialModal({ setEditDb, modalRef, setUserInfo }) {

    const nextWeek = new Date();
    nextWeek.setDate(nextWeek.getDate() + 7);

    const usageReset = {
        monthlyCreditNumber: 10,
        freeTrialTimeStamp: nextWeek.toISOString(),
    }

    return (
        <>
            <TextComponent
                text='Are you sure reset free trial?'
                variant={H5}
                weight={BOLD}
                sx={{ color: colors.WHITE, textAlign: 'center', marginBottom: '2vh' }}
            />
            <div className={`button button-delete`} onClick={() => {

                setUserInfo((prev) => ({
                    ...prev,
                    usage: {
                        ...prev?.usage,
                        ...usageReset
                    }
                }));

                setEditDb(true);
                modalRef.current.setClose();

            }}>
                <TrashIcon className="button-icon" fill="white" />
                <TextComponent text={'Confirm reset'} sx={{ color: colors.WHITE }} />
            </div>
        </>
    )

}

export default ResetFreeTrialModal;