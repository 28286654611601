import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  athlete: {},
  search: '',
};

export const athleteSelectedSlice = createSlice({
  name: 'athleteSelected',
  initialState,
  reducers: {
    resetAthleteSelected: (state, action) => {
      state.athlete = {};
      state.listAthlete = [];
    },
    setAthleteSelected: (state, action) => {
      state.athlete = action?.payload;
    },
    setSearch: (state, action) => {
      state.search = action?.payload;
    },
  },
});

export const {resetAthleteSelected, setAthleteSelected, setSearch} =
  athleteSelectedSlice.actions;

export const selectAthleteSelected = state => state?.athleteSelected?.athlete;

export default athleteSelectedSlice.reducer;
