import React from 'react';

import AnalysisRightPanelUpdateDetail from './AnalysisRightPanelUpdateDetail';
import AnalysisRightPanelMetrics from '../analysisRightPanel/AnalysisRightPanelMetrics';
import AnalysisRightPanelGraph from '../analysisRightPanel/AnalysisRightPanelGraph';
import AnalysisRightPanelRunningStyles from '../analysisRightPanel/AnalysisRightPanelRunningStyles';
import { ANALYSISTYPE_PRONATION } from 'constant/constants';


function AnalysisRightPanelUpdated({ displayData, analysisTexts, exercices, version, overstrideData, locked, modalRef, analysisType }) {
  if (displayData === undefined) {
    return;
  }


  else if (displayData?.title || analysisType === ANALYSISTYPE_PRONATION) {
    return (
      <AnalysisRightPanelUpdateDetail
        displayData={displayData}
        exercices={exercices}
        analysisTexts={analysisTexts}
        version={version}
        overstrideData={overstrideData}
        locked={displayData?.locked}
        exercicesLocked={locked}
        modalRef={modalRef}
        analysisType={analysisType}
      />
    );
  } else if (displayData?.metricKey) {
    return <AnalysisRightPanelMetrics displayData={displayData} />;
  } else if (displayData?.graphData) {
    return <AnalysisRightPanelGraph displayData={displayData} />;
  }
  else if (displayData?.stylesExplanation) {
    return <AnalysisRightPanelRunningStyles displayData={displayData} />;
  }


}

export default AnalysisRightPanelUpdated;
