import { SCORE_GOOD } from 'constant/constants';
import React from 'react';
import { score_colors } from 'screen/analysis/AnalysisListCard/constants/analysisListConstants';

export default function KneeAdductionRightSvg({ angle, rotation, score }) {
    if (!score || angle === undefined) return null;

    // Generate a unique identifier for this instance
    const instanceId = `knee_adduction_right_${Math.random().toString(36).substr(2, 9)}`;

    const centerX = 145;
    const centerY = 130;
    const L = 100;

    // Convert both angles to radians
    const angleRad = ((angle + 180) * Math.PI) / 180;
    const rotationRad = (-rotation * Math.PI) / 180;

    // Segment BA (horizontal with rotation)
    const Ax = centerX;
    const Ay = centerY - 100;

    // Segment BC (angle + rotation)
    const Cx = centerX;
    const Cy = centerY + 100;

    const Bx = centerX + L * Math.cos(angleRad + rotationRad);
    const By = centerY;

    return (
        <svg width="100%" height="100%" viewBox="0 0 300 300" preserveAspectRatio="xMidYMid meet" >

            <defs>
                <linearGradient id={`paint0_linear_1354_75857_${instanceId}`} x1={0.429688} y1={15.5552} x2={76.376} y2={36.5469} gradientUnits="userSpaceOnUse">
                    <stop offset={0.566071} stopColor={score_colors[score]?.svgLinear} />
                    <stop offset={1} stopColor={score_colors[score]?.svgLinear} stopOpacity={0} />
                </linearGradient>
                <linearGradient id={`paint1_linear_1354_75857_${instanceId}`} x1={92.4757} y1={15.5131} x2={15.4783} y2={-13.6771} gradientUnits="userSpaceOnUse">
                    <stop offset={0.13927} stopColor={score_colors[score]?.stroke} stopOpacity={0} />
                    <stop offset={0.404022} stopColor={score_colors[score]?.stroke} />
                    <stop offset={0.676007} stopColor={score_colors[score]?.stroke} />
                    <stop offset={0.957011} stopColor={score_colors[score]?.stroke} stopOpacity={0} />
                </linearGradient>

                <linearGradient id={`paint0_linear_1354_75842_${instanceId}`} x1={84.3144} y1={12.1763} x2={1.92272} y2={-1.84268} gradientUnits="userSpaceOnUse">
                    <stop stopColor={score_colors[score]?.svgLinear} stopOpacity={0} />
                    <stop offset={0.633504} stopColor={score_colors[score]?.svgLinear} />
                </linearGradient>
                <linearGradient id={`paint1_linear_1354_75842_${instanceId}`} x1={0.499253} y1={12.7336} x2={84.0061} y2={36.6493} gradientUnits="userSpaceOnUse">
                    <stop offset={0.0417287} stopColor={score_colors[score]?.stroke} stopOpacity={0} />
                    <stop offset={0.243588} stopColor={score_colors[score]?.stroke} />
                    <stop offset={0.533825} stopColor={score_colors[score]?.stroke} />
                    <stop offset={0.825244} stopColor={score_colors[score]?.stroke} stopOpacity={0} />
                </linearGradient>
            </defs>

            {/* First Path */}
            <g transform={`translate(${Bx}, ${By}) rotate(${rotation - angle}) translate(-15, -25) scale(1.7)`}>
                <path
                    d="M30.9803 3.60577C24.0525 2.00674 16.448 4.54338 11.9011 5.72154C9.90683 6.2383 8.94343 6.09142 5.61526 6.4613C-1.00182 7.1967 -3.26332 23.7875 10.9667 22.6224C18.596 21.9977 21.5976 24.0052 29.2484 24.1933C40.3845 24.467 46.4173 21.0826 57.5368 20.3956C63.4532 20.03 68.7151 20.3777 72.9318 21.2565C76.0139 21.503 82.1781 20.4427 82.1781 14.2288C82.1781 6.4613 74.0421 8.2121 72.9318 8.60273C71.0074 9.46887 67.3512 9.80201 61.9629 9.80201C56.5953 9.80201 37.9081 5.2048 30.9803 3.60577Z"
                    fill={`url(#paint0_linear_1354_75857_${instanceId})`}
                    stroke={`url(#paint1_linear_1354_75857_${instanceId})`}
                    strokeDasharray={score === SCORE_GOOD ? 0 : 7}
                    strokeWidth={1.5}
                    strokeLinecap="round"
                />
            </g>

            {/* Second Path */}
            <g transform={`translate(${Cx}, ${By}) rotate(${rotation}) translate(-15, -19) scale(1.7)`}>
                <path
                    d="M79.8314 2.07545C77.6121 2.07573 76.0401 2.81518 76.0401 5.77421C76.0401 6.68502 71.6016 6.81852 71.6016 10.9527C71.6016 15.0869 76.0401 14.8769 76.0401 16.1313C76.0401 18.7202 78.3519 20.5694 79.8314 20.5694C81.5838 20.5694 86.8591 19.4601 87.0541 20.5696C87.249 21.679 93.1327 21.8927 93.7943 20.5696C96.0136 16.1313 96.0136 5.77421 93.7943 1.33581C93.1325 0.0123432 87.6189 0.225527 87.0541 1.3356C86.4892 2.44567 81.9225 2.0752 79.8314 2.07545Z"
                    fill="#0D0F0B"
                    fillOpacity={0.7}
                    stroke="#AAAAAA"
                    strokeOpacity={0.5}
                    strokeWidth={0.739758}
                    strokeLinecap="round"
                />
                <path
                    d="M87.1362 1.33643C87.1362 1.33643 89.3555 4.29546 89.3555 10.9533C89.3555 17.6111 87.1362 20.5701 87.1362 20.5701"
                    stroke="white"
                    strokeOpacity={0.3}
                    strokeWidth={0.739758}
                    strokeLinecap="round"
                    strokeDasharray="2.22 2.22"
                />
                <path
                    d="M78.6295 2.81494C78.6295 2.81494 83.4748 4.27557 82.7065 5.70822C81.3488 8.23983 77.6116 7.15485 77.6108 10.7714C77.61 14.388 81.3488 12.9414 82.5906 15.8896C83.2552 17.4674 78.6305 19.0896 78.6305 19.0896"
                    stroke="white"
                    strokeOpacity={0.3}
                    strokeWidth={0.739758}
                    strokeLinecap="round"
                    strokeDasharray="2.22 2.22"
                />
            </g>

            {/* Third Path */}
            <g transform={`translate(${Bx}, ${By}) rotate(${angle - rotation}) translate(-5, -19) scale(1.5)`}>
                <path
                    d="M21.8602 23.1994C38.9437 27.6205 58.6005 30.9808 81.1313 30.3529C88.5724 30.1456 96.1571 25.8135 92.6062 14.9675C88.1677 1.40998 77.0934 1.40968 71.1753 1.40997C65.2572 1.41026 59.0751 1.02132 51.5505 1.40998C38.9747 2.05954 30.2634 3.26177 19.3585 3.26177C8.45365 3.26177 11.0187 4.44406 6.27269 4.83269C3.50972 5.3143 -0.256065 9.20172 0.631645 14.8239C1.51936 20.446 3.85841 21.3639 13.4742 21.2018C16.4392 21.1518 17.7391 22.1328 21.8602 23.1994Z"
                    fill={`url(#paint0_linear_1354_75842_${instanceId})`}
                    stroke={`url(#paint1_linear_1354_75842_${instanceId})`}
                    strokeDasharray={score === SCORE_GOOD ? 0 : 7}
                    strokeWidth={1.5}
                    strokeLinecap="round"
                />
            </g>

            {/* Segment Lines */}
            <path d={`M ${Bx},${By} L ${Ax},${Ay}`} stroke={score_colors[score]?.stroke} strokeWidth={2} />
            <path d={`M ${Bx},${By} L ${Cx},${Cy}`} stroke={score_colors[score]?.stroke} strokeWidth={2} />

            {/* Circles */}
            <circle cx={Ax} cy={Ay} r="5" fill={score_colors[score]?.stroke} />
            <circle cx={Bx} cy={By} r="5" fill={score_colors[score]?.stroke} />
            <circle cx={Cx} cy={Cy} r="5" fill={score_colors[score]?.stroke} />
        </svg>
    );
}
