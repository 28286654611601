import { REGISTRATION_DATA } from 'constant/constants';
import React, { useEffect, useState } from 'react';
import OnBoardingContainer from '../components/OnBoardingContainer';
import OnBoardingTemplate from '../components/OnBoardingTemplate';
import { onBoardingTranslate } from 'services/remoteConfig';
import LoadingComponent from 'components/atoms/LoadingComponent';
import { colors } from 'themes';
import { mpTrack } from 'services/mixpanel';

function DualViewAnalysis() {
  const [isLoading, setIsLoading] = useState(true);
  const [videosLoaded, setVideosLoaded] = useState(0);

  const handleVideoLoad = () => {
    setVideosLoaded(prevCount => prevCount + 1);
    if (videosLoaded + 1 > 1) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (videosLoaded === 2) {
      setIsLoading(false);
    }
  }, [videosLoaded]);

  useEffect(() => {
    mpTrack('Web App OnBoarding Retail Store Dual View Analysis Screen Viewed')
  }, [])

  return (
    <OnBoardingContainer previous next>
      <OnBoardingTemplate
        title={onBoardingTranslate('DualViewAnalysisTitle')}
        text={onBoardingTranslate('DualViewAnalysisDescription')}>
        <div style={{ display: 'flex', position: 'relative' }}>
          {' '}
          {/* Added position relative */}
          {isLoading && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: colors.APP_BACKGROUND,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1, // Ensure loader is on top
              }}>
              <LoadingComponent size={125} />
            </div>
          )}
          <video
            muted
            autoPlay={true}
            loop={true}
            controls={false}
            onLoadedData={handleVideoLoad} // Use onLoadedData for faster loading indication
            style={{
              width: '40vh',
              height: '80vh',
              marginRight: '5px',
            }}>
            <source
              src={
                'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FOnboarding%2Fvideo%2FbackView_analysis.mp4?alt=media&token=62f5ab31-a698-4c0f-9d19-aca4cbfed45f'
              }
              type={'video/mp4'}
            />
            Your browser does not support the video tag.{' '}
            {/* Fallback message */}
          </video>
          <video
            muted
            autoPlay={true}
            loop={true}
            controls={false}
            onLoadedData={handleVideoLoad} // Use onLoadedData
            style={{ width: '40vh', height: '80vh', marginLeft: '5px' }}>
            <source
              src={
                'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FOnboarding%2Fvideo%2Fexemple_treadmill.mp4?alt=media&token=1e149b92-0bcb-4930-9ead-b691503065a5'
              }
              type={'video/mp4'}
            />
            Your browser does not support the video tag.{' '}
            {/* Fallback message */}
          </video>
        </div>
      </OnBoardingTemplate>
    </OnBoardingContainer>
  );
}

export default DualViewAnalysis;
