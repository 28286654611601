import React, {useState} from 'react';

import {getFunctions, httpsCallable} from 'firebase/functions';

import TextComponent from 'components/atoms/TextComponent';
import ClickableTextComponent from 'components/atoms/ClickableTextComponent';

import {translate} from 'services/remoteConfig';

import {formatDate} from 'utils/utils';
import {colors} from 'themes';

const MultipleSubscriptionsWarning = ({onRedirectToPortal}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleRedirect = async () => {
    setIsLoading(true);
    await onRedirectToPortal();
    setIsLoading(false);
  };

  return (
    <div style={warningStyles.container}>
      <TextComponent
        variant="h6"
        text={translate('MultipleActiveSubscriptions')}
        sx={warningStyles.title}
      />
      <TextComponent
        variant="body1"
        text={translate('MultipleActiveSubscriptionsMessage')}
        sx={warningStyles.message}
      />
      <ClickableTextComponent
        onPress={handleRedirect}
        title={
          isLoading ? translate('Loading') : translate('OpenCustomerPortal')
        }
        sx={warningStyles.link}
      />
    </div>
  );
};

export default function SubscriptionInformationPlanDetails({
  usage,
  subscription,
  hasMultipleSubscriptions,
}) {
  // Function to redirect to customer portal
  async function redirectToCustomerPortal() {
    const functions = getFunctions(undefined, 'europe-west3');
    const createPortalLink = httpsCallable(
      functions,
      'ext-firestore-stripe-payments-createPortalLink',
    );
    try {
      const {data} = await createPortalLink({
        returnUrl: window.location.origin + '/subscription',
      });
      window.location.assign(data.url);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  return (
    <div style={styles.container}>
      {/* Display warning if multiple active subscriptions */}
      {hasMultipleSubscriptions && (
        <MultipleSubscriptionsWarning
          onRedirectToPortal={redirectToCustomerPortal}
        />
      )}

      <div>
        <TextComponent
          variant="h6"
          text={translate('PlanDetails')}
          sx={styles.title}
        />
        {(usage?.yearlyPlan === true || usage.monthlyPlan === true) &&
        usage?.numberCreditToRenew ? (
          <>
            <div style={styles.subContainer}>
              <TextComponent
                variant="body1"
                text={translate('MonthlyAnalysisRemaining') + ':'}
                sx={styles.rowText}
              />
              <TextComponent
                variant="body1"
                text={
                  `${usage?.monthlyCreditNumber}` +
                  ' / ' +
                  usage?.numberCreditToRenew
                }
                sx={styles.usageText}
              />
            </div>
            {usage.monthlyCreditExpired && (
              <div style={styles.subContainer}>
                <TextComponent
                  variant="body1"
                  text={translate('MonthlyAnalysisRenewingDate') + ':'}
                  sx={styles.rowText}
                />
                <TextComponent
                  variant="body1"
                  text={formatDate(usage?.monthlyCreditExpired)}
                  sx={styles.usageText}
                />
              </div>
            )}
            {subscription?.cancel_at && (
              <div style={styles.subContainer}>
                <TextComponent
                  variant="body1"
                  text={translate('SubscriptionCanceledAt') + ':'}
                  sx={styles.rowText}
                />
                <TextComponent
                  variant="body1"
                  text={formatDate(subscription?.cancel_at)}
                  sx={styles.usageText}
                />
              </div>
            )}
            <div style={styles.subContainer}>
              <TextComponent
                variant="body1"
                text={translate('AnalysisToUseAtAnyTime') + ' : '}
                sx={styles.rowText}
              />
              <TextComponent
                variant="body1"
                text={`${usage?.creditNumber}`}
                sx={styles.usageText}
              />
            </div>
          </>
        ) : (
          <div style={styles.planContainer}>
            <div style={styles.unlimitedContainer}>
              <TextComponent
                sx={styles.unlimitedText}
                text={translate('UnlimitedAnalysis')}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
const styles = {
  planContainer: {
    alignSelf: 'center',
    marginTop: 10,
    width: '100%',
  },
  unlimitedContainer: {
    alignItems: 'center',
    backgroundColor: colors.PRIMARY,
    borderRadius: 10,
    padding: 10,
  },
  unlimitedText: {
    alignSelf: 'center',
    textAlign: 'center',
    textAlignVertical: 'center',
    fontSize: '0.8vw',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    height: '40px',
    alignItems: 'center',
    width: '40%',
    alignSelf: 'center',
    borderRadius: 5,
    backgroundColor: colors.SECONDARY,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    marginTop: 20,
  },
  buttonText: {
    fontWeight: 550,
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.BACKGROUND,
    flex: 1,
    margin: 15,
    borderRadius: 10,
    padding: 10,
  },
  rowText: {
    textAlign: 'left',
    marginTop: 2,
    color: colors.WHITE_OPACITY_60,
    width: '60%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
    alignItems: 'center',
  },
  subContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  title: {
    textAlign: 'left',
    marginTop: 2,
    fontWeight: 'bold',
  },
  usageText: {
    textAlign: 'right',
    marginTop: 2,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
};

const warningStyles = {
  container: {
    backgroundColor: 'rgba(255, 0, 0, 0.1)',
    borderWidth: 1,
    borderColor: colors.RED,
    borderStyle: 'solid',
    borderRadius: 5,
    padding: 15,
    marginBottom: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    color: colors.RED,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  message: {
    color: colors.WHITE,
    marginBottom: 10,
    textAlign: 'center',
  },
  link: {
    color: colors.SECONDARY,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
};
