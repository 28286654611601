import React, {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';

import TextInput from 'components/atoms/TextInput';
import ButtonComponent from 'components/atoms/ButtonComponent';
import TextComponent from 'components/atoms/TextComponent';
import InputAndUnity from './settings/components/InputAndUnity';

import {translate} from 'services/remoteConfig';
import {updateUserInDB} from 'services/authApi';

import {selectUser} from 'slices/userSlice';

import {feetInchToCm, kgToLbs, lbsToKg, mToftIn} from 'utils/converter';
import {
  checkUserPermission,
  getHeightinCM,
  getHeightinMeters,
} from 'utils/utils';

import {ATHLETE, B2B_USER_TYPE, BOLD, ORANGE} from 'constant/constants';

import {colors} from 'themes';

function EditProfil() {
  const userSlice = useSelector(selectUser);
  const unityHeight = useSelector(state => state?.unity?.height);
  const unityHeightComplement = useSelector(
    state => state?.unity?.heightComplement,
  );
  const unityWeight = useSelector(state => state?.unity?.weight);

  const [formData, setFormData] = useState({
    name: userSlice?.fullName,
    height:
      unityHeight === 'ft'
        ? mToftIn(userSlice?.height).ft
        : getHeightinCM(userSlice?.height),
    heightComplement:
      unityHeight === 'ft' ? mToftIn(userSlice?.height).inch : '',
    weight:
      unityWeight === 'kg'
        ? userSlice?.weight
        : kgToLbs(userSlice?.weight).toFixed(0),
  });

  useEffect(() => {
    setFormData(prevState => ({
      ...prevState,
      height:
        unityHeight === 'ft'
          ? mToftIn(userSlice?.height).ft
          : getHeightinCM(userSlice?.height),
      heightComplement:
        unityHeight === 'ft' ? mToftIn(userSlice?.height).inch : '',
      weight:
        unityWeight === 'kg'
          ? userSlice?.weight
          : kgToLbs(userSlice?.weight).toFixed(0),
    }));
  }, [unityHeight, unityHeightComplement, unityWeight, userSlice]);

  const handleSave = () => {
    const {name, height, heightComplement, weight} = formData;
    if (
      checkUserPermission([ATHLETE]) &&
      height !== '' &&
      weight !== '' &&
      name !== ''
    ) {
      let heightToUpdate, weightToUpdate;

      if (unityHeight === 'ft') {
        if (heightComplement === '') {
          alert(translate('FillAllFields'));
          return;
        }
        heightToUpdate = parseFloat(feetInchToCm(height, heightComplement));
      } else {
        heightToUpdate = parseFloat(getHeightinMeters(height));
      }
      if (unityWeight === 'lbs') {
        weightToUpdate = parseFloat(lbsToKg(weight));
      } else {
        weightToUpdate = parseFloat(weight);
      }

      updateUserInDB({
        fullName: name,
        height: heightToUpdate,
        weight: weightToUpdate,
      });
    } else if (checkUserPermission(B2B_USER_TYPE) && name) {
      updateUserInDB({fullName: name});
    } else {
      alert(translate('FillAllFields'));
    }
  };

  const handleChange = e => {
    const {name, value} = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div style={styles.container}>
      <TextComponent
        text={translate('YourProfile')}
        sx={styles.title}
        weight={BOLD}
      />
      <TextInput
        onChange={handleChange}
        title={translate('YourName')}
        id="nameTextInput"
        name="name"
        value={userSlice?.fullName}
      />
      {checkUserPermission([ATHLETE]) && (
        <>
          <InputAndUnity
            title={translate('Height')}
            value={
              unityHeight === 'ft'
                ? mToftIn(userSlice?.height).ft
                : getHeightinCM(userSlice?.height)
            }
            name="height"
            onChange={handleChange}
            unity={unityHeight}
          />
          {unityHeight === 'ft' && (
            <InputAndUnity
              title={translate('HeightComplement')}
              value={mToftIn(userSlice?.height).inch}
              name="heightComplement"
              onChange={handleChange}
              unity={unityHeightComplement}
            />
          )}
          <InputAndUnity
            title={translate('Weight')}
            value={
              unityWeight === 'kg'
                ? userSlice?.weight.toFixed(0)
                : kgToLbs(userSlice?.weight).toFixed(0)
            }
            name="weight"
            onChange={handleChange}
            unity={unityWeight}
          />
        </>
      )}
      <ButtonComponent
        type={ORANGE}
        onClick={handleSave}
        title={translate('Save')}
        sx={styles.button}
      />
    </div>
  );
}

const styles = {
  button: {
    alignSelf: 'center',
    marginTop: '2vh',
    width: '3vw',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '85%',
  },
  title: {
    color: colors.WHITE,
    marginBottom: '3vh',
    fontSize: '1.5vw',
  },
};

export default EditProfil;
