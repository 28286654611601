import React, {useCallback, useState} from 'react';
import {useEffect} from 'react';
import Lottie from 'lottie-react';
import {animated, useSpring} from '@react-spring/web';
import RoundedScoreMeter from 'screen/analysis/AnalysisListCard/components/RoundedScoreMeter';
import OnBoardingContainer from '../components/OnBoardingContainer';
import OnBoardingTemplate from '../components/OnBoardingTemplate';
import {onBoardingTranslate} from 'services/remoteConfig';
import { mpTrack } from 'services/mixpanel';

const AnalysisSharing = () => {
  const [hideSegments, setHideSegments] = useState(false);

  useEffect(() => {
    mpTrack('Web App OnBoarding Retail Store Analysis Sharing Screen Viewed');
  }, []);

  const segments = [
    {
      type: 'RightAnkle',
      score_number: 0.4002,
      score: 'score_okay',
      animate: {x: 3, y: 100},
      style: {top: 150, right: '47.5%', position: 'absolute'},
    },
    {
      type: 'RightEar',
      score_number: 0.6875,
      score: 'score_good',
      animate: {x: -200, y: 100},
      style: {right: '25%', top: '20%', position: 'absolute'},
    },
    {
      type: 'RightHip',
      score_number: 0.45,
      score: 'score_okay',
      animate: {x: 250, y: 100},
      style: {left: '25%', top: '20%', position: 'absolute'},
    },
    {
      type: 'RightElbow',
      score_number: 0.31,
      score: 'score_bad',
      animate: {x: -250, y: -100},
      style: {right: '25%', bottom: '20%', position: 'absolute'},
    },
    {
      type: 'forwardCycle',
      score_number: 0.7987755102040817,
      score: 'score_good',
      animate: {x: 250, y: -100},
      style: {left: '25%', bottom: '20%', position: 'absolute'},
    },
    {
      type: 'backCycle',
      score_number: 0.24444444444444446,
      score: 'score_bad',
      animate: {x: 3, y: -100},
      style: {bottom: 200, right: '47.5%', position: 'absolute'},
    },
  ];

  const AnimatedSegment = ({item, index, onComplete}) => {
    const [springs, api] = useSpring(() => ({
      from: {x: 0, y: 0, opacity: 1},
      to: async next => {
        await new Promise(resolve => setTimeout(resolve, 800 + index * 50));
        await next({x: item.animate.x, y: item.animate.y, opacity: 0});
        onComplete && onComplete();
      },
      config: {duration: 500},
    }));

    return (
      <animated.div
        style={{
          ...item.style,
          transform: springs.x.to(
            (x, y) => `translate(${x}px, ${springs.y.get()}px)`,
          ),
          opacity: springs.opacity,
        }}>
        <RoundedScoreMeter
          scoreNumber={item.score_number}
          scoreText={item.score}
          item={item.type}
          size={60}
        />
      </animated.div>
    );
  };

  const FadeInAnimation = ({children, delay}) => {
    const [springs] = useSpring(() => ({
      from: {opacity: 0},
      to: {opacity: 1},
      delay,
      config: {duration: 500},
    }));

    return <animated.div style={springs}>{children}</animated.div>;
  };

  return (
    <OnBoardingContainer previous next>
      <OnBoardingTemplate
        title={onBoardingTranslate('AnalysisSharingTitle')}
        text={onBoardingTranslate('AnalysisSharingDescription')}>
        <div style={styles.mainContent}>
          <div>
            {!hideSegments &&
              segments.map((item, index) => (
                <AnimatedSegment
                  key={index}
                  item={item}
                  index={index}
                  onComplete={
                    index === segments.length - 1
                      ? () => setHideSegments(true)
                      : undefined
                  }
                />
              ))}
          </div>
          <div style={styles.lottieContainer}>
            <Lottie
              animationData={require('assets/animations/sendMailAnim2.json')}
              loop={false}
              style={styles.lottieAnimation}
            />
          </div>
          <FadeInAnimation delay={2300}>
            <div style={styles.fireworksContainer}>
              <Lottie
                animationData={require('assets/animations/fireworks.json')}
                loop={true}
                style={{
                    width: '50%',
                    height: '50%',

                }}
              />
            </div>
          </FadeInAnimation>
        </div>
      </OnBoardingTemplate>
    </OnBoardingContainer>
  );
};

export default AnalysisSharing;
const styles = {
  flexContainer: {},
  header: {
    textAlign: 'center',
    marginBottom: '1rem',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  mainContent: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '5rem',
  },
  visualContainer: {
    width: '70%',
    height: '50%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  lottieContainer: {
    width: '50%',
    height: '50%',
    position: 'relative',
  },
  fireworksContainer: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    alignItems : 'center',
    justifyContent : 'center',
    display : 'flex',
    inset: 0,
    zIndex: -1,
  },
  lottieAnimation: {
    width: '100%',
    height: '100%',
  },
  description: {
    textAlign: 'center',
    marginTop: '1rem',
  },
  descriptionText: {
    fontSize: '1.125rem',
  },
};
