import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import VideoComponent from 'screen/analysis/AnalysisViewDetailsScreen/components/VideoComponent';
import CompareAnalysisScreen from './CompareAnalysisScreen';
import { ANALYSISTYPE_PRONATION } from 'constant/constants';

function ComparePortraitsAnalysis({
  firstAnalysis,
  secondAnalysis,
}) {

  const location = useLocation();
  const navigate = useNavigate();

  const [firstVideoFlip, setFirstVideoFlip] = useState(false);
  const [secondVideoFlip, setSecondVideoFlip] = useState(false);

  function handleVideoFlip(index) {
    if (index === 0) {
      setFirstVideoFlip(!firstVideoFlip);
    } else {
      setSecondVideoFlip(!secondVideoFlip);
    }
  }

  function handleAnalysisPressed(index) {
    navigate(
      `/analysis/${location?.state?.comparedAnalysis[index]?.analysis?.data?.uploadVideoId}`,
      {
        state: {
          analysis: location?.state?.comparedAnalysis[index]?.analysis,
          personalised: firstAnalysis?.personalised,
        },
      },
    );
  }
  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <div style={{ height: '100%', width: '25%' }}>
        <CompareAnalysisScreen
          backView={firstAnalysis?.analysis_type === ANALYSISTYPE_PRONATION && firstAnalysis?.json_version < 2}
          analysisData={firstAnalysis}
          handleAnalysisPressed={() => {
            handleAnalysisPressed(0);
          }}
          handleVideoFlip={() => {
            handleVideoFlip(0);
          }}
          portraits={true}
        />
      </div>
      <div style={{ width: '25%' }}>
        <VideoComponent video={firstAnalysis?.video_url} flip={firstVideoFlip} />
      </div>

      <div style={{ width: '25%' }}>
        <VideoComponent
          video={secondAnalysis?.video_url}
          flip={secondVideoFlip}
        />
      </div>
      <div style={{ height: '100%', width: '25%' }}>
        <CompareAnalysisScreen
          backView={secondAnalysis?.analysis_type === ANALYSISTYPE_PRONATION && secondAnalysis?.json_version < 2}
          analysisData={secondAnalysis}
          handleAnalysisPressed={() => {
            handleAnalysisPressed(1);
          }}
          handleVideoFlip={() => {
            handleVideoFlip(1);
          }}
          portraits={true}
        />
      </div>
    </div>
  );
}

export default ComparePortraitsAnalysis;
