import {collection, getDocs} from 'firebase/firestore';
import {firestore} from 'utils/firebaseInit';

const PARTNERS_COLLECTION = 'partners';

export const getAllPartners = async () => {
  try {
    const querySnapshot = await getDocs(
      collection(firestore, PARTNERS_COLLECTION),
    );

    const partners = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));

    return partners;
  } catch (error) {
    console.error('Erreur lors de la récupération des partenaires :', error);
    return [];
  }
};
