import React, {useEffect, useState} from 'react';
import {selectUsage} from 'slices/usageSlice';
import {useSelector} from 'react-redux';
import {
  getStripePayments,
  getProducts,
} from '@invertase/firestore-stripe-payments';
import {firebase} from 'utils/firebaseInit';

import LoadingView from 'components/molecules/LoadingView';
import ProductsWrapper from './ProductsWrapper';
import SubscriptionInformationContainer from './subscriptionInformations/SubscriptionInformationContainer';

import {colors} from 'themes';
import {
  B2B_MONTHLY_BASIC,
  B2B_YEARLY_BASIC,
  TWENTY_ANALYSIS_PACK,
} from 'constant/constants';

function ManageSubscription() {
  const usage = useSelector(selectUsage);
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState(undefined);
  const ALLOWED_PRODUCTS = [
    B2B_MONTHLY_BASIC,
    B2B_YEARLY_BASIC,
    TWENTY_ANALYSIS_PACK,
  ];

  // Initialize Stripe Payments
  const payments = getStripePayments(firebase, {
    productsCollection: 'products',
    customersCollection: 'customers',
  });

  useEffect(() => {
    const fetchProductsAndPrices = async () => {
      try {
        setLoading(true);

        // Fetch all products
        const allProducts = await getProducts(payments, {
          activeOnly: true,
          includePrices: true,
        });
        // Filter products based on ALLOWED_PRODUCTS
        const filteredProducts = allProducts?.filter(product =>
          ALLOWED_PRODUCTS?.includes(product?.id),
        );

        setProductData(filteredProducts);
      } catch (error) {
        console.error('Error fetching products and prices:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductsAndPrices();
  }, []);

  return (
    <div style={styles.container}>
      {loading && (
        <div style={styles.loadingContainer}>
          <LoadingView color={colors.BACKGROUND} />
        </div>
      )}
      <>
        <SubscriptionInformationContainer
          usage={usage}
          productData={productData}
          payments={payments}
        />

        <ProductsWrapper
          products={productData}
          usage={usage}
          payments={payments}
        />
      </>
    </div>
  );
}

const styles = {
  container: {
    position: 'relative',
    height: '100%',
    width: '100%',
    marginBottom: 40,
  },
  loadingContainer: {
    //center the loading view and put it in the middle of the page
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    marginTop: 300,
  },
};
export default ManageSubscription;
