import React from 'react';

export default function PelvicDropIcon({
    leftFill = '#424242',
    rightFill = '#424242',
    fill = '#424242',
    style,
}) {
    return (
        <svg
            viewBox="0 0 23 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
        >
            <path
                d="M17.8726 6.95731C17.392 5.80429 17.152 3.97867 17.2484 1H11.2905V18.4875C11.724 18.5525 12.0745 18.7173 12.232 18.8573C12.4288 19.0323 12.735 23.8169 12.7132 24.3007C12.6957 24.6877 16.6828 24.4619 18.4007 24.3007C18.3046 22.9555 19.1696 18.6797 19.5544 15.4128C19.8803 12.6455 18.3533 8.11033 17.8726 6.95731Z"
                fill={rightFill}
                fillOpacity={0.3}
            />
            <path
                d="M4.70745 6.95731C5.18813 5.80429 5.42805 3.97867 5.33167 1H11.2896V18.4875C10.8561 18.5525 10.5708 18.6552 10.4134 18.7952C10.2165 18.9701 9.91033 23.7548 9.9322 24.2386C9.9497 24.6256 5.89723 24.4619 4.17934 24.3007C4.27543 22.9555 3.41046 18.6797 3.02568 15.4128C2.69974 12.6455 4.22677 8.11033 4.70745 6.95731Z"
                fill={leftFill}
                fillOpacity={0.3}
            />
            <path
                d="M11.3429 1.38037L17.3008 1.38037C17.2044 4.35904 17.9021 6.18466 18.3827 7.33768C18.8634 8.4907 20.1264 13.113 19.8005 15.8804C19.4157 19.1473 18.2867 23.2737 18.3827 24.6189C16.6649 24.7802 12.6442 25.0059 12.6617 24.6189C12.6835 24.1352 13.3008 17.8804 11.3008 18.3804"
                stroke={rightFill}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.1099 1.38037H5.15198C5.24836 4.35904 5.00844 6.18466 4.52776 7.33768C4.04708 8.4907 2.52005 13.0258 2.84599 15.7932C3.23077 19.0601 4.09575 23.3358 3.99966 24.681C5.71754 24.8423 10.2663 25.0059 10.2488 24.6189C10.227 24.1352 9.80078 19.3804 11.3008 18.3804"
                stroke={leftFill}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1 8.38037H21.5718"
                stroke={fill}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="2 2"
            />
            <path
                d="M11.3008 18.3804L11.3008 1.38037"
                stroke={fill}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="2 2"
            />
        </svg>
    );
}
