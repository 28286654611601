import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import VideoComponent from '../AnalysisViewDetailsScreen/components/VideoComponent';
import { ANALYSISTYPE_PRONATION } from 'constant/constants';
import CompareAnalysisScreen from './components/CompareAnalysisScreen';
import ComparePortraitsAnalysis from './components/ComparePortraitsAnalysis';
import ComparePortraitsAndLandscapeAnalysis from './components/ComparePortraitAndLandscapeAnalysis';
import { fetchJsonData } from 'utils/analysis';

function CompareAnalysis() {
  const location = useLocation();
  const navigate = useNavigate();

  const [firstVideoFlip, setFirstVideoFlip] = useState(false);
  const [secondVideoFlip, setSecondVideoFlip] = useState(false);

  const [firstAnalysis, setFirstAnalysis] = useState(null);
  const [secondAnalysis, setSecondAnalysis] = useState(null)

  function getAngle(foot_strike, segmentKey) {
    if (segmentKey === 'foot' || segmentKey === 'total_score') return
    else if (segmentKey === 'arm') return foot_strike?.arm_mean_angle;
    else if (segmentKey === 'head') return foot_strike?.head_mean_angle;
    else if (segmentKey === 'trunk') return foot_strike?.trunk_mean_angle;
    else if (segmentKey === 'back_cycle') return foot_strike?.back_leg_mean_angle;
    else if (segmentKey === 'forward_cycle') return foot_strike?.front_leg_mean_angle
    else if (segmentKey === 'pelvic_left') return foot_strike?.left?.pelvic_drop;
    else if (segmentKey === 'pelvic_right') return foot_strike?.right?.pelvic_drop;
    else if (segmentKey === 'pronation_left') return foot_strike?.left?.pronation;
    else if (segmentKey === 'pronation_right') return foot_strike?.right?.pronation;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const firstData = await fetchJsonData(location?.state?.comparedAnalysis[0]?.analysis);
        const secondData = await fetchJsonData(location?.state?.comparedAnalysis[1]?.analysis);
        setFirstAnalysis({
          video_url: location?.state?.comparedAnalysis[0]?.analysis?.video_url,
          analysis_content: updateAnalysisContent(location?.state?.comparedAnalysis[0]?.analysis?.analysis_content, firstData?.jsonData, location?.state?.comparedAnalysis[0]?.analysis?.analysis_type),
          orientation: getVideoOrientation(location?.state?.comparedAnalysis[0]?.analysis),
          analysis_type: location?.state?.comparedAnalysis[0]?.analysis?.analysis_type,
          json_version: location?.state?.comparedAnalysis[0]?.analysis?.json_version,
        });
        setSecondAnalysis({
          video_url: location?.state?.comparedAnalysis[1]?.analysis?.video_url,
          analysis_content: updateAnalysisContent(location?.state?.comparedAnalysis[1]?.analysis?.analysis_content, secondData?.jsonData, location?.state?.comparedAnalysis[1]?.analysis?.analysis_type),
          orientation: getVideoOrientation(location?.state?.comparedAnalysis[1]?.analysis),
          analysis_type: location?.state?.comparedAnalysis[1]?.analysis?.analysis_type,
          json_version: location?.state?.comparedAnalysis[1]?.analysis?.json_version,
        })
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [location?.state?.comparedAnalysis]);

  function handleVideoFlip(index) {
    if (index === 0) {
      setFirstVideoFlip(!firstVideoFlip);
    } else {
      setSecondVideoFlip(!secondVideoFlip);
    }
  }

  function updateAnalysisContent(analysisContent, jsonData, analysis_type) {
    if (analysis_type === ANALYSISTYPE_PRONATION) {
      if (jsonData?.json_version < 2) return analysisContent;
      else {
        const order = ['pelvic_left', 'pelvic_right', 'knee_adduction_left', 'knee_adduction_right', 'pronation_left', 'pronation_right']
        const updatedContent = Object.keys(analysisContent).reduce((acc, segmentKey) => {
          if (segmentKey === 'total_score') return acc;

          const segment = analysisContent[segmentKey];
          acc[segmentKey] = {
            ...segment,
            score_text: jsonData?.analysis_content?.[segmentKey]?.texts?.score,
            angle: getAngle(jsonData?.gait_cycle?.means, segmentKey),
            position: jsonData?.analysis_content?.[segmentKey]?.texts?.search_key,
          };

          return acc;
        }, {});

        const orderedUpdatedContent = order.reduce((acc, key) => {
          if (updatedContent[key]) {
            acc[key] = updatedContent[key];
          }
          return acc;
        }, {});


        return orderedUpdatedContent;
      }
    }
    else {
      const order = ['foot', 'head', 'trunk', 'arm', 'forward_cycle', 'back_cycle'];

      const updatedContent = Object.keys(analysisContent).reduce((acc, segmentKey) => {
        if (segmentKey === 'total_score') return acc;

        const segment = analysisContent[segmentKey];
        acc[segmentKey] = {
          ...segment,
          score_text: jsonData?.analysis_content?.[segmentKey]?.texts?.score,
          angle: getAngle(jsonData?.foot_strike, segmentKey),
          position: jsonData?.analysis_content?.[segmentKey]?.texts?.position,

        };

        return acc;
      }, {});

      const orderedUpdatedContent = order.reduce((acc, key) => {
        if (updatedContent[key]) {
          acc[key] = updatedContent[key];
        }
        return acc;
      }, {});

      return orderedUpdatedContent;
    }
  };

  function getVideoOrientation(analysis) {
    if (analysis?.analysis_type === ANALYSISTYPE_PRONATION) {
      return 'portrait';
    }
    else return analysis?.video?.orientation;
  }


  function handleAnalysisPressed(index) {
    navigate(
      `/analysis/${location?.state?.comparedAnalysis[index]?.analysis?.data?.uploadVideoId}`,
      {
        state: {
          analysis: location?.state?.comparedAnalysis[index]?.analysis,
          personalised: firstAnalysis?.personalised,
        },
      },
    );
  }


  if (firstAnalysis?.orientation && secondAnalysis?.orientation) {
    if (
      firstAnalysis?.orientation === 'portrait' &&
      secondAnalysis?.orientation === 'portrait'
    ) {
      return (
        <ComparePortraitsAnalysis
          firstAnalysis={firstAnalysis}
          secondAnalysis={secondAnalysis}
        />
      );
    } else if (
      firstAnalysis?.orientation === 'portrait' ||
      secondAnalysis?.orientation === 'portrait'
    ) {
      return (
        <ComparePortraitsAndLandscapeAnalysis
          portraitAnalysis={
            firstAnalysis?.orientation === 'portrait'
              ? firstAnalysis
              : secondAnalysis
          }
          landscapeAnalysis={
            firstAnalysis?.orientation === 'portrait'
              ? secondAnalysis
              : firstAnalysis
          }
        />
      );
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '90vh',
          width: '47%',
        }}>
        <div style={{ width: '100%' }}>
          <VideoComponent
            video={location?.state?.comparedAnalysis[0]?.analysis?.video_url}
            flip={firstVideoFlip}
          />
        </div>
        <div style={{ width: '100%' }}>
          <VideoComponent
            video={location?.state?.comparedAnalysis[1]?.analysis?.video_url}
            flip={secondVideoFlip}
          />
        </div>
      </div>
      <div style={{ width: '58%' }}>
        <div style={{ height: '50%' }}>
          <CompareAnalysisScreen
            analysisData={firstAnalysis}
            handleAnalysisPressed={() => {
              handleAnalysisPressed(0);
            }}
            handleVideoFlip={() => {
              handleVideoFlip(0);
            }}
          />
        </div>
        <CompareAnalysisScreen
          analysisData={secondAnalysis}
          handleAnalysisPressed={() => {
            handleAnalysisPressed(1);
          }}
          handleVideoFlip={() => {
            handleVideoFlip(1);
          }}
        />
      </div>
    </div>
  );
}

export default CompareAnalysis;
