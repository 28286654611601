import { SCORE_GOOD } from 'constant/constants';
import React from 'react';
import { score_colors } from 'screen/analysis/AnalysisListCard/constants/analysisListConstants';

export default function KneeAdductionLeftSvg({ angle, rotation, score }) {
    if (!score || angle === undefined) return null;

    // Generate a unique identifier for this instance
    const instanceId = `knee_adduction_left_${Math.random().toString(36).substr(2, 9)}`;

    const centerX = 145;
    const centerY = 130;
    const L = 100;

    // Convert both angles to radians
    const angleRad = ((angle + 180) * Math.PI) / 180;
    const rotationRad = (rotation * Math.PI) / 180;

    // Segment BA (horizontal with rotation)
    const Ax = centerX;
    const Ay = centerY - 100;

    // Segment BC (angle + rotation)
    const Cx = centerX;
    const Cy = centerY + 100;

    const Bx = centerX + L * Math.cos(angleRad + rotationRad);
    const By = centerY;

    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 300 300"
            preserveAspectRatio="xMidYMid meet"
        >
            <defs>
                <linearGradient id={`paint0_linear_1361_75817_${instanceId}`} x1={81.8144} y1={19.2963} x2={-0.577277} y2={33.3153} gradientUnits="userSpaceOnUse">
                    <stop stopColor={score_colors[score]?.svgLinear} stopOpacity={0} />
                    <stop offset={0.633504} stopColor={score_colors[score]?.svgLinear} />
                </linearGradient>
                <linearGradient id={`paint1_linear_1361_75817_${instanceId}`} x1={-2.00075} y1={18.739} x2={81.5061} y2={-5.17661} gradientUnits="userSpaceOnUse">
                    <stop offset={0.0417287} stopColor={score_colors[score]?.stroke} stopOpacity={0} />
                    <stop offset={0.243588} stopColor={score_colors[score]?.stroke} />
                    <stop offset={0.533825} stopColor={score_colors[score]?.stroke} />
                    <stop offset={0.825244} stopColor={score_colors[score]?.stroke} stopOpacity={0} />
                </linearGradient>
            </defs>

            {/* First Path */}
            <g transform={`translate(${Bx}, ${By}) rotate(${rotation + angle}) translate(-15, -18) scale(1.7)`}>
                <path
                    d="M31.4803 21.8669C24.5525 23.4659 16.948 20.9293 12.4011 19.7511C10.4068 19.2344 9.44343 19.3812 6.11526 19.0114C-0.501821 18.276 -2.76332 1.68514 11.4667 2.8503C19.096 3.47499 22.0976 1.46742 29.7484 1.27938C40.8845 1.00568 46.9173 4.39003 58.0368 5.07707C63.9532 5.44263 69.2151 5.09495 73.4318 4.2162C76.5139 3.96961 82.6781 5.02993 82.6781 11.2439C82.6781 19.0114 74.5421 17.2606 73.4318 16.8699C71.5074 16.0038 67.8512 15.6706 62.4629 15.6706C57.0953 15.6706 38.4081 20.2679 31.4803 21.8669Z"
                    fill={`url(#paint0_linear_1361_75817_${instanceId})`}
                    stroke={`url(#paint1_linear_1361_75817_${instanceId})`}
                    strokeDasharray={score === SCORE_GOOD ? 0 : 7}
                    strokeWidth={1.5}
                    strokeLinecap="round"
                />
            </g>

            {/* Second Path */}
            <g transform={`translate(${Cx}, ${By}) rotate(${rotation}) translate(-15, -18) scale(1.7)`}>
                <path
                    d="M79.8314 2.07545C77.6121 2.07573 76.0401 2.81518 76.0401 5.77421C76.0401 6.68502 71.6016 6.81852 71.6016 10.9527C71.6016 15.0869 76.0401 14.8769 76.0401 16.1313C76.0401 18.7202 78.3519 20.5694 79.8314 20.5694C81.5838 20.5694 86.8591 19.4601 87.0541 20.5696C87.249 21.679 93.1327 21.8927 93.7943 20.5696C96.0136 16.1313 96.0136 5.77421 93.7943 1.33581C93.1325 0.0123432 87.6189 0.225527 87.0541 1.3356C86.4892 2.44567 81.9225 2.0752 79.8314 2.07545Z"
                    fill="#0D0F0B"
                    fillOpacity={0.7}
                    stroke="#AAAAAA"
                    strokeOpacity={0.5}
                    strokeWidth={0.739758}
                    strokeLinecap="round"
                />
                <path
                    d="M87.1362 1.33643C87.1362 1.33643 89.3555 4.29546 89.3555 10.9533C89.3555 17.6111 87.1362 20.5701 87.1362 20.5701"
                    stroke="white"
                    strokeOpacity={0.3}
                    strokeWidth={0.739758}
                    strokeLinecap="round"
                    strokeDasharray="2.22 2.22"
                />
                <path
                    d="M78.6295 2.81494C78.6295 2.81494 83.4748 4.27557 82.7065 5.70822C81.3488 8.23983 77.6116 7.15485 77.6108 10.7714C77.61 14.388 81.3488 12.9414 82.5906 15.8896C83.2552 17.4674 78.6305 19.0896 78.6305 19.0896"
                    stroke="white"
                    strokeOpacity={0.3}
                    strokeWidth={0.739758}
                    strokeLinecap="round"
                    strokeDasharray="2.22 2.22"
                />
            </g>

            {/* Third Path */}
            <g transform={`translate(${Bx}, ${By}) rotate(${-rotation - angle}) translate(-5, -28) scale(1.5)`}>
                <path
                    d="M19.3602 8.27329C36.4437 3.85219 56.1005 0.49189 78.6313 1.11974C86.0724 1.3271 93.6571 5.65917 90.1062 16.5052C85.6677 30.0627 74.5934 30.063 68.6753 30.0627C62.7572 30.0624 56.5751 30.4513 49.0505 30.0627C36.4747 29.4131 27.7634 28.2109 16.8585 28.2109C5.95365 28.2109 8.51871 27.0286 3.77269 26.64C1.00972 26.1584 -2.75607 22.2709 -1.86835 16.6488C-0.980644 11.0266 1.35841 10.1087 10.9742 10.2708C13.9392 10.3208 15.2391 9.33981 19.3602 8.27329Z"
                    fill={`url(#paint0_linear_1361_75817_${instanceId})`}
                    stroke={`url(#paint1_linear_1361_75817_${instanceId})`}
                    strokeDasharray={score === SCORE_GOOD ? 0 : 7}
                    strokeWidth={1.5}
                    strokeLinecap="round"
                />
            </g>

            {/* Segment Lines */}
            <path d={`M ${Bx},${By} L ${Ax},${Ay}`} stroke={score_colors[score]?.stroke} strokeWidth={2} />
            <path d={`M ${Bx},${By} L ${Cx},${Cy}`} stroke={score_colors[score]?.stroke} strokeWidth={2} />

            {/* Circles */}
            <circle cx={Ax} cy={Ay} r="5" fill={score_colors[score]?.stroke} />
            <circle cx={Bx} cy={By} r="5" fill={score_colors[score]?.stroke} />
            <circle cx={Cx} cy={Cy} r="5" fill={score_colors[score]?.stroke} />
        </svg>
    );
}
