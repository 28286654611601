import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import OnBoardingButtonList from '../components/OnBoardingButtonList';
import OnBoardingContainer from '../components/OnBoardingContainer';
import OnBoardingTemplate from '../components/OnBoardingTemplate';

import { onBoardingTranslate } from 'services/remoteConfig';
import { mpTrack } from 'services/mixpanel';

import { selectUser } from 'slices/userSlice';

import { REGISTRATION_DATA } from 'constant/constants';
import { onBoardingButtonUserType } from '../constants/onBoardingButtonData.js';

function UserTypeSelection() {
  const user = useSelector(selectUser);
  const [userTypeData, setUserTypeData] = useState(onBoardingButtonUserType);

  useEffect(() => {
    if (user?.onlyUserType?.length === 0) return;
    userTypeData.map((item, index) => {
      if (user?.onlyUserType?.includes(item?.userData?.userType)) {
        setUserTypeData(prevState => {
          const newState = [...prevState];
          newState[index].disabled = false;
          return newState;
        });
        // item.disabled = false;
      } else {
        setUserTypeData(prevState => {
          const newState = [...prevState];
          newState[index].disabled = true;
          return newState;
        });
      }
    });
  }, []);

  useEffect(() => {
    mpTrack(
      'Web App OnBoarding UserType Selection Screen Viewed',
    );
    localStorage.removeItem(REGISTRATION_DATA);
  }, []);

  return (
    <OnBoardingContainer previous={true}>
      <OnBoardingTemplate title={onBoardingTranslate('UserTypeHeadLine')}>
        <div style={styles.buttonList}>
          <OnBoardingButtonList data={onBoardingButtonUserType} />
        </div>
      </OnBoardingTemplate>

    </OnBoardingContainer>
  );
}

const styles = {
  buttonList: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-evenly',
    width: '100%',

  },
};

export default UserTypeSelection;
