import TextComponent from 'components/atoms/TextComponent';
import React from 'react'
import { score_colors } from 'screen/analysis/AnalysisListCard/constants/analysisListConstants';
import { colors } from 'themes';
import { ReactComponent as Triangle } from "assets/svg/triangle.svg";

function Ruler({ rangeGood, rangeOkay, angle, score, rangeToDisplay = [], rulerTexts = [], }) {
  if (
    !rangeGood ||
    !rangeOkay ||
    (!angle && angle !== 0) ||
    rangeGood.length < 2 ||
    rangeOkay.length < 2
  )
    return null;
  const numberOfDots = 70;
  const start = rangeToDisplay[0] ?? rangeOkay[0] - 10;
  const end = rangeToDisplay[1] ?? rangeOkay[1] + 10;
  const segmentPosition = Number(angle).toFixed(0);

  function mapValueToScale(value) {
    if (value < start) return 0;
    if (value > end) return 100;
    const ratio = (value - start) / (end - start);
    return ratio * 100;
  }

  const firstGoodPosition = mapValueToScale(rangeGood[0]);
  const secondGoodPosition = mapValueToScale(rangeGood[1]);
  const firstOkayPosition = mapValueToScale(rangeOkay[0]);
  const secondOkayPosition = mapValueToScale(rangeOkay[1]);

  const numbersToShow = [
    { number: rangeOkay[0], score: 'score_okay' },
    { number: rangeGood[0], score: 'score_good' },
    { number: rangeGood[1], score: 'score_good' },
    { number: rangeOkay[1], score: 'score_okay' },
  ];

  const getColorForRange = number => {
    const valuePercent = (number / numberOfDots) * 100;
    if (
      valuePercent >= firstGoodPosition &&
      valuePercent <= secondGoodPosition
    ) {
      return score_colors.score_good.stroke;
    }
    if (
      valuePercent >= firstOkayPosition &&
      valuePercent <= secondOkayPosition
    ) {
      return score_colors.score_okay.stroke;
    }
    return score_colors.score_bad.stroke;
  };

  const renderSegments = () => {
    let segments = [];
    for (let i = 0; i <= numberOfDots; i++) {
      const position = (i / numberOfDots) * 100;
      const valuePercent = (i / numberOfDots) * 100;
      const width =
        valuePercent >= firstGoodPosition && valuePercent <= secondGoodPosition
          ? "0.5vw"
          : "0.2vw";
      segments.push(
        <>
          {valuePercent >= firstGoodPosition &&
            valuePercent <= secondGoodPosition ? (
            <div style={{
              ...{
                backgroundColor: colors.DARK_GREEN,
                bottom: -4,
                height: 7,
                position: 'absolute',
                width: 6
              }
              , ...{ left: `${position}%` }
            }} />
          ) : null}
          <div
            key={i}
            style={{
              ...{
                height: 1.7,
                position: 'absolute',
              },
              ...{
                width: width,
                left: `${position}%`,
                backgroundColor: getColorForRange(i),
              }
            }}
          />
        </>,
      );
    }
    return segments;
  };

  const renderNumbers = () => {
    return numbersToShow.map((number, index) => {
      const position = mapValueToScale(number.number);
      if (number.number < start || number.number > end) return null;
      return (
        <div
          style={{
            ...{
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              top: '2.5rem',
            },
            ...{
              left: `${position - 1}%`,
            },
          }}
          key={index}>
          <div style={{
            display: 'flex', flexDirection: 'column', alignItems: 'center',
            backgroundColor: colors?.APP_BACKGROUND,
            zIndex: 1,
            height: 16,
            width: 17
          }}>
            <div
              style={{
                ...{
                  alignItems: 'center',
                  borderRadius: 10,
                  height: 12,
                  justifyContent: 'center',
                  width: 12,
                  display: 'flex',
                  flexDirection: 'column',
                },
                ...{ backgroundColor: score_colors[number.score].scoreBackground },
              }
              }>
              <div
                style={{
                  ...{
                    borderRadius: 10,
                    height: 5,
                    width: 5,
                  },
                  ...{ backgroundColor: score_colors[number.score].stroke },
                }
                }></div>
            </div>
          </div>

          <div
            style={{
              ...{ backgroundColor: score_colors[number.score].stroke },
              ...{
                height: 10,
                width: 3,
              },
            }}
          />

          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minWidth: 25,
          }}>
            <TextComponent text={number?.number} />
          </div>
        </div>
      );
    });
  };

  const renderAnglePosition = () => {
    let position = mapValueToScale(segmentPosition);
    if (position === 100) {
      position = 92
    }
    const angleStyle = {
      transform: `translateX(${position}%)`,
      transition: 'transform 1s ease-in-out',
    };
    return (
      <div style={angleStyle}>
        <div style={{
          minWidth: 25,
          position: 'absolute',
          top: -50, display: 'flex', alignItems: 'center', flexDirection: 'column'
        }}>
          <TextComponent
            text={segmentPosition + '°'}
            sx={{

              ...{
                color: score_colors[score]?.stroke,
              },
            }}
          />
        </div>
        <Triangle
          width={24}
          height={24}
          style={{
            position: 'absolute',
            top: -27,
          }}
          fill={score_colors[score]?.stroke}
        />
      </div>
    );
  };


  return (
    <div style={styles.container}>
      {rulerTexts?.firstText && (
        <div
          style={{
            height: '14vh',
            left: 0,
            position: 'absolute',
            maxWidth: `${Math.min(firstGoodPosition, firstOkayPosition) === 0 ?
              (Math.max(secondGoodPosition, secondOkayPosition)) :
              (Math.min(firstGoodPosition, firstOkayPosition))}%`,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            marginTop: '-16vh',
          }}
        >
          <TextComponent
            text={rulerTexts?.firstText?.title}

            sx={{
              ...styles.textRigth,
              ...{
                color: score_colors[rulerTexts?.firstText?.score]?.stroke, fontSize: '0.6vw',
                textAlign: 'left',

              },
            }}
          />
        </div>
      )
      }

      {
        rulerTexts?.secondText && (
          <div
            style={{
              height: '10vh',
              right: 0,
              position: 'absolute',
              maxWidth: `${(100 - Math.max(secondGoodPosition, secondOkayPosition)) - 3}%`,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              marginTop: '-12vh',
            }}
          >
            <TextComponent
              text={rulerTexts?.secondText?.title}

              sx={{
                ...styles.textRigth,
                ...{
                  color: score_colors[rulerTexts?.secondText?.score]?.stroke, fontSize: '0.6vw',
                  textAlign: 'right',

                },
              }}
            />
          </div>
        )
      }
      {renderSegments()}
      {renderNumbers()}
      {renderAnglePosition()}
    </div >
  );
}
const styles = {
  container: {
    position: 'relative',
    width: '100%',
    height: '6rem', // Adjust based on your needs
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  numberContainer: {
    position: 'absolute',
    textAlign: 'center',
    bottom: '-20px', // Adjust as needed
  },
  triangle: {
  },
  angleNumberContainer: {

  },
  angleNumberTextContainer: {

  },
  angleRangeBlackBackground: {

  },
  angleRangeContainer: {

  },
  angleRangeRoundBackground: {

  },
  angleRangeRoundColor: {

  },
  angleText: {

  },
  segment: {
    height: 1.7,
    position: 'absolute',
  },
  segmentContainer: {
    backgroundColor: colors.DARK_GREEN,
    bottom: -4,
    height: 7,
    position: 'absolute',
    width: 6,
  },
  textAlignCenter: {
  },

  // Add more style definitions here
};

export default Ruler