import HeightTextInput from 'components/molecules/HeightTextInput';
import WeightTextInput from 'components/molecules/WeightTextInput';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAthleteSelected } from 'slices/athleteSlice';
import { selectHeightUnity, setHeightUnity, setUnity, setUnityType, setWeightUnity } from 'slices/unitySlice';
import { feetInchToCm, kgToLbs, lbsToKg, mToftIn } from 'utils/converter';
import { CM, EN, FR, FT, KG, LBS } from 'constant/constants';

function HeightAndWeightTextInput({ heightRef, weightRef, onChange }) {
  const heightUnityRef = useRef();
  const weightUnityRef = useRef();

  const dispatch = useDispatch();
  const heightUnity = useSelector(selectHeightUnity);

  const [displayInTextInput, setDisplayInTextInput] = useState(
    heightUnity === 'ft' ? true : false,
  );

  const athleteSlice = useSelector(selectAthleteSelected);

  useEffect(() => {
    if (Object.keys(athleteSlice).length === 0) return;
    if (displayInTextInput) {
      weightRef?.current.setWeight(kgToLbs(athleteSlice?.weight)?.toFixed(0));
      const height = mToftIn(athleteSlice?.height);
      heightRef?.current?.setHeightAndCompl(height?.ft, height?.inch);
    } else {
      weightRef?.current.setWeight(athleteSlice?.weight?.toFixed(0));
      heightRef?.current.setHeight((athleteSlice?.height * 100)?.toFixed(0));
    }
  }, [displayInTextInput]);

  function convertHeightCM() {
    const heightInM = parseFloat(heightRef?.current?.getHeight()) / 100;
    const height = mToftIn(heightInM);
    heightRef?.current?.setHeightAndCompl(height?.ft, height?.inch);
  }

  function convertHeightFT() {
    const heightFT = parseFloat(heightRef?.current?.getHeight());
    const heightcmplFT = parseFloat(heightRef?.current?.getHeightComplement());
    const height = feetInchToCm(heightFT, heightcmplFT) * 100;
    heightRef?.current?.setHeight(height.toFixed(0));
  }

  function handleValueChanges(value) {
    if (value === FT) {
      weightUnityRef?.current?.setValue(LBS);
      setDisplayInTextInput(true);
      dispatch(setUnityType(EN));
      dispatch(setHeightUnity(FT));
      dispatch(setUnity(EN));
      convertHeightCM();
      weightRef?.current?.setWeightClear(
        kgToLbs(weightRef?.current?.getWeight())?.toFixed(0),
      );
    } else if (value === CM) {
      weightUnityRef?.current?.setValue(KG);
      setDisplayInTextInput(false);
      dispatch(setUnityType(FR));
      dispatch(setHeightUnity(CM));
      dispatch(setUnity(FR));
      convertHeightFT();
      weightRef?.current?.setWeightClear(
        lbsToKg(weightRef?.current?.getWeight())?.toFixed(0),
      );
    } else if (value === LBS) {
      heightUnityRef?.current?.setValue(FT);
      setDisplayInTextInput(true);
      dispatch(setUnityType(EN));
      dispatch(setWeightUnity(LBS));
      dispatch(setUnity(EN));
      convertHeightCM();
      weightRef?.current?.setWeightClear(
        kgToLbs(weightRef?.current?.getWeight())?.toFixed(0),
      );
    } else if (value === KG) {
      heightUnityRef?.current?.setValue(CM);
      setDisplayInTextInput(false);
      dispatch(setUnityType(FR));
      dispatch(setWeightUnity(KG));
      dispatch(setUnity(FR));
      convertHeightFT();
      weightRef?.current?.setWeightClear(
        lbsToKg(weightRef?.current?.getWeight())?.toFixed(0),
      );
    }
  }
  return (
    <div style={{ display: 'flex' }}>
      <HeightTextInput
        displayInTextInput={displayInTextInput}
        handleValueChanges={handleValueChanges}
        heightUnityRef={heightUnityRef}
        ref={heightRef}
        idNext="weightTextInput"
        onChange={onChange}
      />
      <div style={{ width: 20 }}></div>
      <WeightTextInput
        displayInTextInput={displayInTextInput}
        handleValueChanges={handleValueChanges}
        weightUnityRef={weightUnityRef}
        ref={weightRef}
        onChange={onChange}
      />
    </div>
  );
}

export default HeightAndWeightTextInput;
