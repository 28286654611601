import { Grid } from '@mui/material';
import TextComponent from 'components/atoms/TextComponent';
import { ANALYSISTYPE_PRONATION, BODY_1, BODY_2 } from 'constant/constants';
import React from 'react';
import RoundedScoreMeter from 'screen/analysis/AnalysisListCard/components/RoundedScoreMeter';
import { score_colors } from 'screen/analysis/AnalysisListCard/constants/analysisListConstants';

function CompareAnalysisSegments({ analysisData, portraits = false, analysisType }) {
  if (!analysisData) return;
  return (
    <Grid container spacing={1}>
      {Object.entries(analysisData)?.map(([key, analysis], index) => {
        return (
          <Grid
            item
            xs={12}
            sm={6}
            md={portraits ? 6 : 4}
            lg={portraits ? 12 : 4}
            key={index}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <RoundedScoreMeter
                scoreNumber={analysis?.score_number}
                scoreText={analysis?.score_text}
                item={analysis?.key || key}
                size={70}
                personalised={analysisType !== ANALYSISTYPE_PRONATION}
                side={analysis?.side}
              />
              <div>
                {key !== 'foot' && (
                  <TextComponent
                    text={analysis?.angle?.toFixed(0) + '°'}
                    sx={{
                      color: score_colors[analysis?.score || analysis?.score_text].stroke,
                    }}
                    variant={BODY_1}
                  />
                )}
                <TextComponent
                  text={analysis?.position}
                  variant={BODY_2}
                  sx={{ fontSize: '0.75rem' }}
                />
              </div>
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default CompareAnalysisSegments;
