import {images} from 'themes';

export const EXEMPLE_VIDEO_POSTER =
  'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FOnboarding%2Fimages%2Fposter_onTrack.jpg?alt=media&token=bd0218ec-3ead-41ad-822f-13000a188795';

export const EXEMPLE_VIDEO_URL =
  'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FOnboarding%2Fvideo%2Fperrine_with_drawing_onTrack.mp4?alt=media&token=7e7ce46f-6ea2-4aa3-8670-a09212ee56d7';

export const GOOD_EXEMPLE_VIDEO_POSTER =
  'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FOnboarding%2Fimages%2Fposter_exemple.jpg?alt=media&token=90b0893b-90eb-4ea7-9edd-e4e9cbe603f5';
export const GOOD_EXEMPLE_URL =
  'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FOnboarding%2Fvideo%2Fperrine_with_drawing.mp4?alt=media&token=e26cef2b-5935-412e-91ac-180a04d76a02';

export const EXERCISE_VIDEO_URL =
  'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fexercises%2Fvideos%2FFallingLateralLungewithBodyweightPortrait.mp4?alt=media&token=f0d59759-6ae5-4276-b521-61d9cf6e6efc';

export const TREADMILL_EXEMPLE_VIDEO_URL =
  'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FOnboarding%2Fvideo%2Fexemple_treadmill.mp4?alt=media&token=1e149b92-0bcb-4930-9ead-b691503065a5';

export const PRONATION_EXEMPLE_VIDEO_URL =
  'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FvideoTuto%2Fupload%2FanalysisTypes%2FprenationSupinationPreview.mp4?alt=media&token=63f5495d-c58c-45c0-96b3-5be44dd71dd9';

export const PELVIC_DROP_DEFINITION = {
  fr: {
    title: 'Position du bassin',
    description:
      "Position du bassin pendant la marche ou la course à pied. C'est la différence de niveau entre la hanche droite et la hanche gauche (Pipkin et al. 2016).\n\n{{Pourquoi est-il important d'avoir un bassin aligné ?}}\n\nPour chaque augmentation de 1° d'inclinaison du bassin, les risques d'être blessé augmentent de 80 % (Bramah et al. 2018).\n\nL'inclinaison du bassin est l'une des variables les plus fortement associées aux blessures liées à la course à pied (Bramah et al. 2018).\n\n{{Quand la position du bassin est-elle calculée ? }}\nLa position du bassin est mesurée pendant la phase d’appui du cycle de la foulée, lorsque le pied droit ou gauche est en contact avec le sol.",
    link: 'https://www.ochy.io/fr/blog/pelvic-drop-in-runners-what-it-is-why-it-matters-and-how-to-prevent-it',
  },
  en: {
    title: 'Pelvic drop',
    description:
      "Position of the pelvis during running or walking. It's the level difference between the right hip and left hip (Pipkin et al. 2016).\n\n{{Why pelvic drop matters?}}\nFor each 1° increase in pelvic drop, the odds of being classified as injured rose by 80%  (Bramah et al. 2018).\n\nPelvic drop is one of the variables most strongly associated with running-related injuries (Bramah et al. 2018).\n\n{{When is pelvic drop calculated?}}\nPelvic drop is measured during the stance phase of gait cycle, when either the right or left foot is in contact with the ground.",
    link: 'https://www.ochy.io/blog/pelvic-drop-in-runners-what-it-is-why-it-matters-and-how-to-prevent-it',
  },
  es: {
    title: 'Caída de la pelvis',
    description:
      'Posición de la pelvis durante la carrera o la caminata. Es la diferencia de nivel entre la cadera derecha y la cadera izquierda (Pipkin et al. 2016).\n\n{{¿Por qué es importante tener la pelvis alineada?}}\nPor cada aumento de 1° en la inclinación de la pelvis, las probabilidades de ser clasificado como lesionado aumentan en un 80% (Bramah et al. 2018).\n\nLa inclinación de la pelvis es una de las variables más fuertemente asociadas con las lesiones relacionadas con la carrera (Bramah et al. 2018).\n\n{{¿Cuándo se calcula la caída de la pelvis? }}\nLa caída de la pelvis se mide durante la fase de apoyo del ciclo de la marcha, cuando el pie derecho o izquierdo está en contacto con el suelo.',
    link: 'https://www.ochy.io/blog/pelvic-drop-in-runners-what-it-is-why-it-matters-and-how-to-prevent-it',
  },
  it: {
    title: 'Discesa del bacino',
    description:
      "Posizione del bacino durante la corsa o la camminata. È la differenza di livello tra l'anca destra e l'anca sinistra (Pipkin et al. 2016).\n\n{{Perché è importante avere il bacino allineato?}}\nPer ogni aumento di 1° nell'inclinazione del bacino, le probabilità di essere classificati come infortunati aumentano dell'80% (Bramah et al. 2018).\n\nL'inclinazione del bacino è una delle variabili più fortemente associate agli infortuni legati alla corsa (Bramah et al. 2018).\n\n{{Quando viene calcolata la discesa del bacino? }}\nLa discesa del bacino viene misurata durante la fase di appoggio del ciclo di camminata, quando il piede destro o sinistro è a contatto con il suolo.",
    link: 'https://www.ochy.io/blog/pelvic-drop-in-runners-what-it-is-why-it-matters-and-how-to-prevent-it',
  },
  pt: {
    title: 'Desvio pélvico',
    description:
      'Posição da pelve durante a corrida ou caminhada. É a diferença de nível entre o quadril direito e o quadril esquerdo (Pipkin et al. 2016).\n\n{{Por que o desvio pélvico importa?}}\nPara cada aumento de 1° no desvio pélvico, as chances de ser classificado como lesionado aumentam em 80% (Bramah et al. 2018).\n\nO desvio pélvico é uma das variáveis mais fortemente associadas a lesões relacionadas à corrida (Bramah et al. 2018).\n\n{{Quando é calculada a queda da pelve? }}\nA queda da pelve é medida durante a fase de apoio do ciclo da marcha, quando o pé direito ou esquerdo está em contato com o solo.',
    link: 'https://www.ochy.io/blog/pelvic-drop-in-runners-what-it-is-why-it-matters-and-how-to-prevent-it',
  },
  de: {
    title: 'Beckenkippung',
    description:
      'Position des Beckens während des Laufens oder Gehens. Es ist der Höhenunterschied zwischen der rechten und linken Hüfte (Pipkin et al. 2016).\n\n{{Warum ist es wichtig, ein ausgerichtetes Becken zu haben?}}\nFür jede Erhöhung von 1° in der Beckenkippe steigen die Chancen, als verletzt eingestuft zu werden, um 80% (Bramah et al. 2018).\n\nDie Beckenposition ist eine der Variablen, die am stärksten mit laufbedingten Verletzungen assoziiert ist (Bramah et al. 2018).\n\n{{Wann wird der Beckensenkung berechnet? }}\nDie Beckensenkung wird während der Standphase des Gangzyklus gemessen, wenn entweder der rechte oder der linke Fuß mit dem Boden in Kontakt ist.',
    link: 'https://www.ochy.io/blog/pelvic-drop-in-runners-what-it-is-why-it-matters-and-how-to-prevent-it',
  },
  zh: {
    title: '骨盆下垂',
    description:
      '跑步或行走时骨盆的位置。它是右侧臀部与左侧臀部之间的高度差 (Pipkin et al. 2016)。\n\n{{为什么骨盆下垂很重要？}}\n每增加 1° 的骨盆下垂，受伤的几率增加 80%（Bramah 等人，2018）。\n\n骨盆下垂是与跑步相关的伤害最强烈关联的变量之一（Bramah 等人，2018）。\n\n{{骨盆下沉何时计算？}}\n骨盆下沉在步态周期的支撑阶段测量，当右脚或左脚与地面接触时进行测量。',
    link: 'https://www.ochy.io/blog/pelvic-drop-in-runners-what-it-is-why-it-matters-and-how-to-prevent-it',
  },
  ja: {
    title: '骨盤の落ち込み',
    description:
      '走るまたは歩く際の骨盤の位置です。右の股関節と左の股関節の高さの違いです (Pipkin et al. 2016)。\n\n{{骨盤の落ち込みが重要なのはなぜですか？}}\n骨盤の傾きが1°増加するごとに、怪我と分類される確率が80%増加します（Bramah et al. 2018）。\n\n骨盤の傾きは、走ることに関連する怪我と最も強く関連している変数の1つです（Bramah et al. 2018）。\n\n{{骨盤の落下はいつ計算されますか？}}\n骨盤の落下は、歩行サイクルのスタンスフェーズ中に測定され、右足または左足が地面と接触しているときに測定されます。',
    link: 'https://www.ochy.io/blog/pelvic-drop-in-runners-what-it-is-why-it-matters-and-how-to-prevent-it',
  },
  ko: {
    title: '골반 하강',
    description:
      '달리기 또는 걷기 중 골반의 위치입니다. 오른쪽 엉덩이와 왼쪽 엉덩이 사이의 높이 차이입니다 (Pipkin et al. 2016).\n\n{{왜 골반 하강이 중요한가요?}}\n골반 하강이 1° 증가할 때마다 부상으로 분류될 확률이 80% 증가합니다 (Bramah et al. 2018).\n\n골반 하강은 달리기와 관련된 부상과 가장 강하게 연관된 변수 중 하나입니다 (Bramah et al. 2018).\n\n{{골반 드롭은 언제 계산되나요? }}\n골반 드롭은 보행 주기의 스탠스 단계에서 측정되며, 오른발 또는 왼발이 지면에 닿을 때 측정됩니다.',
    link: 'https://www.ochy.io/blog/pelvic-drop-in-runners-what-it-is-why-it-matters-and-how-to-prevent-it',
  },
};

export const PRONATION_DEFINITION = {
  fr: {
    title: 'Pronation/Supination',
    description:
      'La pronation et la supination du pied correspondent, de manière générale, à un mouvement se produisant à l’arrière du pied. La pronation et la supination du pied sont courantes dans des activités comme la course à pied (Willems et al., 2021).\n\nLe pied est une partie du corps très complexe où différents mouvements internes, tels que la pronation et la supination, se produisent. Lors de la marche et de la course, les articulations et les muscles du pied sont particulièrement sollicités. Des exercices du pied, tels que la proprioception, sont nécessaires pour le renforcer.',
    link: 'https://www.ochy.io/fr/blog/understanding-pronation-supination-and-why-it-matters',
  },
  en: {
    title: 'Pronation/Supination',
    description:
      'Foot pronation and supination corresponds, in a general sense, as a movement occurring at the back of the foot. Foot pronation and supination are common in activities such as running (Willems et al. 2021).\n\nThe foot is a very complex part of the body where different internal movements happen as pronation and supination. During walking and running, foot joints and muscles are particularly used. Foot exercising such as proprioception is needed to reinforce it.',
    link: 'https://www.ochy.io/blog/understanding-pronation-supination-and-why-it-matters',
  },
  es: {
    title: 'Pronación/Supinación',
    description:
      'La pronación y la supinación del pie corresponden, en general, a un movimiento que ocurre en la parte posterior del pie. La pronación y la supinación del pie son comunes en actividades como correr (Willems et al., 2021).\n\nEl pie es una parte del cuerpo muy compleja donde ocurren diferentes movimientos internos como la pronación y la supinación. Durante la caminata y la carrera, las articulaciones y los músculos del pie se utilizan particularmente. Los ejercicios de pie, como la propriocepción, son necesarios para reforzarlo.',
    link: 'https://www.ochy.io/blog/understanding-pronation-supination-and-why-it-matters',
  },
  it: {
    title: 'Pronazione/Supinazione',
    description:
      'La pronazione e la supinazione del piede corrispondono, in generale, a un movimento che si verifica nella parte posteriore del piede. La pronazione e la supinazione del piede sono comuni in attività come la corsa (Willems et al., 2021).\n\nIl piede è una parte del corpo molto complessa dove si verificano diversi movimenti interni, come la pronazione e la supinazione. Durante la camminata e la corsa, le articolazioni e i muscoli del piede sono particolarmente sollecitati. Gli esercizi per il piede, come la propriocezione, sono necessari per rinforzarlo.',
    link: 'https://www.ochy.io/blog/understanding-pronation-supination-and-why-it-matters',
  },
  de: {
    title: 'Pronation/Supination',
    description:
      'Fußpronation und Supination entsprechen im Allgemeinen einer Bewegung, die im hinteren Bereich des Fußes stattfindet. Fußpronation und Supination sind häufig in Aktivitäten wie Laufen zu beobachten (Willems et al. 2021).\n\nDer Fuß ist ein sehr komplexer Teil des Körpers, in dem verschiedene interne Bewegungen wie Pronation und Supination stattfinden. Beim Gehen und Laufen werden die Gelenke und Muskeln des Fußes besonders beansprucht. Fußübungen wie Propriozeption sind notwendig, um ihn zu stärken.',
    link: 'https://www.ochy.io/blog/understanding-pronation-supination-and-why-it-matters',
  },
  pt: {
    title: 'Pronação/Supinação',
    description:
      'A pronação e a supinação do pé correspondem, de maneira geral, a um movimento que ocorre na parte posterior do pé. A pronação e a supinação do pé são comuns em atividades como a corrida (Willems et al., 2021).\n\nO pé é uma parte do corpo muito complexa onde ocorrem diferentes movimentos internos, como a pronação e a supinação. Durante a caminhada e a corrida, as articulações e os músculos do pé são particularmente solicitados. Exercícios para os pés, como a propriocepção, são necessários para reforçá-los.',
    link: 'https://www.ochy.io/blog/understanding-pronation-supination-and-why-it-matters',
  },
  ja: {
    title: '足の回内/回外',
    description:
      '足の回内と回外は、一般的に足の後部で起こる動きに相当します。足の回内と回外は、ランニングなどの活動でよく見られます（Willems et al. 2021）。\n\n足は非常に複雑な体の一部で、回内や回外といったさまざまな内部の動きが起こります。歩行やランニング中に足の関節や筋肉が特に使用されます。足のエクササイズ、例えば固有受容は、足を強化するために必要です。',
    link: 'https://www.ochy.io/blog/understanding-pronation-supination-and-why-it-matters',
  },
  ko: {
    title: '프로나션/수프네이션',
    description:
      '발의 프로나션과 수프네이션은 일반적으로 발 뒤쪽에서 발생하는 움직임에 해당합니다. 발의 프로나션과 수프네이션은 달리기와 같은 활동에서 흔히 나타납니다 (Willems et al., 2021).\n\n발은 프로나션과 수프네이션과 같은 다양한 내부 움직임이 발생하는 신체의 매우 복잡한 부분입니다. 걷기와 달리기 중에 발의 관절과 근육은 특히 사용됩니다. 발 운동, 예를 들어 고유 수용성 운동은 발을 강화하는 데 필요합니다.',
    link: 'https://www.ochy.io/blog/understanding-pronation-supination-and-why-it-matters',
  },
  zh: {
    title: '足部内翻/外翻',
    description:
      '足部的内翻和外翻通常指发生在足部后部的运动。足部的内翻和外翻在跑步等活动中非常常见（Willems 等，2021）。\n\n足部是身体一个非常复杂的部分，发生着如内翻和外翻等多种内部运动。在行走和跑步时，足部的关节和肌肉特别受到使用。像本体感觉等足部运动是强化足部所需的。',
    link: 'https://www.ochy.io/blog/understanding-pronation-supination-and-why-it-matters',
  },
};

export const KNEE_ADDUCTION_DEFINITION = {
  fr: {
    title: 'Abduction/Adduction',
    description:
      "Position du genou par rapport à la ligne reliant la hanche et la cheville (Pipkin et al., 2016).\n\n\n{{Pourquoi l'abduction/adduction du genou est importante ?}}\n\nL'incapacité à maintenir un alignement dynamique adéquat du genou lors des mouvements ou des exercices physiques peut entraîner des blessures au genou (Uhlár et al., 2021).\nLe genou est le site le plus fréquent de blessures liées à la course à pied, en particulier chez les femmes (Sakaguchi et al., 2014).",
    asset: images.KNEE_ADDUCTION,
    link: 'https://www.ochy.io/fr/blog/knee-abduction-and-adduction-in-running-understanding-measuring-and-preventing-injuries',
  },
  en: {
    title: 'Abduction/Adduction',
    description:
      'Position of knee relative to a line connecting the hip and ankle (Pipkin et al. 2016).\n\n\n{{Why knee abduction/adduction matters?}}\n\nThe inability to maintain adequate dynamic knee alignment during movements or physical exercises may lead to knee injuries (Uhlár et al., 2021).\nThe knee is the most common site of running injuries, particularly prevalent in females (Sakaguchi et al., 2014).',
    asset: images.KNEE_ADDUCTION,
    link: 'https://www.ochy.io/blog/knee-abduction-and-adduction-in-running-understanding-measuring-and-preventing-injuries',
  },
  es: {
    title: 'Abducción/Adducción',
    description:
      'Posición de la rodilla en relación con una línea que conecta la cadera y el tobillo (Pipkin et al. 2016).\n\n\n{{¿Por qué importa la abducción/adducción de la rodilla?}}\n\nLa incapacidad para mantener una alineación dinámica adecuada de la rodilla durante los movimientos o los ejercicios físicos puede provocar lesiones en la rodilla (Uhlár et al., 2021).\nLa rodilla es el sitio más común de lesiones relacionadas con la carrera, especialmente prevalentes en las mujeres (Sakaguchi et al., 2014).',
    asset: images.KNEE_ADDUCTION,
    link: 'https://www.ochy.io/blog/knee-abduction-and-adduction-in-running-understanding-measuring-and-preventing-injuries',
  },
  it: {
    title: 'Abduzione/Adduzione',
    description:
      'Posizione del ginocchio rispetto a una linea che collega l’anca e la caviglia (Pipkin et al., 2016).\n\n\n{{Perché l’abduzione/adduzione del ginocchio è importante?}}\n\nL’incapacità di mantenere un allineamento dinamico adeguato del ginocchio durante i movimenti o gli esercizi fisici può portare a infortuni al ginocchio (Uhlár et al., 2021).\nIl ginocchio è il sito più comune di infortuni legati alla corsa, particolarmente prevalenti nelle donne (Sakaguchi et al., 2014).',
    asset: images.KNEE_ADDUCTION,
    link: 'https://www.ochy.io/blog/knee-abduction-and-adduction-in-running-understanding-measuring-and-preventing-injuries',
  },
  pt: {
    title: 'Abdução/Addução',
    description:
      'Posição do joelho em relação a uma linha que conecta o quadril e o tornozelo (Pipkin et al. 2016).\n\n\n{{Por que a abdução/addução do joelho é importante?}}\n\nA incapacidade de manter um alinhamento dinâmico adequado do joelho durante movimentos ou exercícios físicos pode levar a lesões no joelho (Uhlár et al., 2021).\nO joelho é o local mais comum de lesões relacionadas à corrida, particularmente prevalentes nas mulheres (Sakaguchi et al., 2014).',
    asset: images.KNEE_ADDUCTION,
    link: 'https://www.ochy.io/blog/knee-abduction-and-adduction-in-running-understanding-measuring-and-preventing-injuries',
  },
  de: {
    title: 'Abduktion/Adduktion',
    description:
      'Position des Knies relativ zu einer Linie, die die Hüfte und den Knöchel verbindet (Pipkin et al., 2016).\n\n\n{{Warum ist die Abduktion/Adduktion des Knies wichtig?}}\n\nDie Unfähigkeit, eine angemessene dynamische Ausrichtung des Knies während Bewegungen oder körperlicher Übungen aufrechtzuerhalten, kann zu Knieverletzungen führen (Uhlár et al., 2021).\nDas Knie ist der häufigste Ort für Laufverletzungen, besonders bei Frauen verbreitet (Sakaguchi et al., 2014).',
    asset: images.KNEE_ADDUCTION,
    link: 'https://www.ochy.io/blog/knee-abduction-and-adduction-in-running-understanding-measuring-and-preventing-injuries',
  },
  ja: {
    title: '膝の外転/内転',
    description:
      '股関節と足首を結ぶ線に対する膝の位置（Pipkin et al. 2016）。\n\n\n{{なぜ膝の外転/内転が重要なのか？}}\n\n運動やフィジカルトレーニング中に膝の適切な動的アライメントを維持できないと、膝の怪我を引き起こす可能性があります（Uhlár et al., 2021）。\n膝はランニングによる怪我が最も多い部位であり、特に女性に多く見られます（Sakaguchi et al., 2014）。',
    asset: images.KNEE_ADDUCTION,
    link: 'https://www.ochy.io/blog/knee-abduction-and-adduction-in-running-understanding-measuring-and-preventing-injuries',
  },
  zh: {
    title: '膝关节外展/内收',
    description:
      '膝关节相对于连接髋部和踝部的线的位置（Pipkin 等，2016）。\n\n\n{{为什么膝关节的外展/内收很重要？}}\n\n在运动或身体训练中无法保持膝关节的适当动态对齐可能导致膝盖受伤（Uhlár 等，2021）。\n膝关节是跑步运动中最常见的受伤部位，尤其在女性中更为普遍（Sakaguchi 等，2014）。',
    asset: images.KNEE_ADDUCTION,
    link: 'https://www.ochy.io/blog/knee-abduction-and-adduction-in-running-understanding-measuring-and-preventing-injuries',
  },
  ko: {
    title: '무릎 외전/내전',
    description:
      '엉덩이와 발목을 연결하는 선에 대한 무릎의 위치 (Pipkin et al., 2016).\n\n\n{{왜 무릎의 외전/내전이 중요한가?}}\n\n운동이나 신체 훈련 중 무릎의 적절한 동적 정렬을 유지하지 못하면 무릎 부상을 초래할 수 있습니다 (Uhlár et al., 2021).\n무릎은 달리기와 관련된 부상이 가장 자주 발생하는 부위로, 특히 여성에게서 더 많이 발생합니다 (Sakaguchi et al., 2014).',
    asset: images.KNEE_ADDUCTION,
    link: 'https://www.ochy.io/blog/knee-abduction-and-adduction-in-running-understanding-measuring-and-preventing-injuries',
  },
};
