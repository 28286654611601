import TextComponent from "components/atoms/TextComponent";
import { BODY_1, H5 } from "constant/constants";
import React, { useEffect } from "react";
import { GLOBAL_LOCALE, USER_LOCALE } from "services/remoteConfig";

function CardThumbnail({ analysis, thumbnail, verticale }) {
  const [thumb, setThumb] = React.useState(null);

  useEffect(() => {
    setThumb(thumbnail);
  }, [thumbnail]);

  let options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const analysisDate = analysis?.date
    ? new Date(analysis?.date).toLocaleDateString(USER_LOCALE, options)
    : "";

  return (
    <div style={{ ...styles.container, backgroundImage: `url(${thumb})` }}>
      <div
        style={{
          ...styles.backgroundOpacity,
          opacity: verticale ? 0.8 : 0.2,
        }}
      ></div>

      {verticale && (
        <div style={styles.thumbnailContainer}>
          <img src={thumb} alt="thumbnail" style={styles.thumbnailImage} />
        </div>
      )}

      <div
        style={{
          ...styles.textContainer,
          left: verticale ? "35%" : "2%",
          width: verticale ? "48%" : "auto",
        }}
      >
        <TextComponent
          variant={H5}
          sx={styles.text}
          weight={"500"}
          text={analysis?.data?.tag?.name}
        />
        <TextComponent variant={BODY_1} text={analysisDate} />
      </div>
    </div>
  );
}

const styles = {
  container: {
    backgroundSize: "cover",
    borderRadius: 30,
    height: "80%",
    position: "relative",
    width: "100%",
  },
  backgroundOpacity: {
    borderRadius: 30,
    backgroundColor: "rgb(0,0,0,1)",
    height: "100%",
    position: "absolute",
    width: "100%",
  },
  thumbnailContainer: {
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    width: "35%", // Donne 35% de la largeur de l'image pour la miniature
    borderRadius: "30px",
    overflow: "hidden",
  },
  thumbnailImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  text: {
    marginBottom: "2%",
  },
  textContainer: {
    position: "absolute",
    marginLeft: "2%",
    marginTop: "3%",
  },
};

export default CardThumbnail;
