import React, {useEffect, useState} from 'react';

import ProductFeatures from './ProductCardFeatures';
import ProductCardButtons from './ProductCardButtons';
import ProductCardHeader from './ProductCardHeader';

import { translate } from 'services/remoteConfig';

import {colors} from 'themes';

// Save badge component for yearly plans
const SaveBadge = () => (
  <div style={styles.saveBadge}>
    <span style={styles.saveBadgeText}>{translate('BestOfferSave15')}</span>
  </div>
);

export default function ProductCard({
  product,
  selected,
  currency,
  payments,
  subscriptions,
}) {
  // Use a more stable state management approach
  const [displayedPrice, setDisplayedPrice] = useState(null);
  
  // Determine if this is a yearly plan based on product data, not displayedPrice
  const isYearlyPlan = selected && product?.prices?.[0]?.interval === 'year';
  
  // Check if the product is active
  const isActive = subscriptions?.some(subscription => subscription?.product === product?.id && subscription?.status === "active");
  const productSubscription = subscriptions?.find(subscription => subscription?.product === product?.id);
  useEffect(() => {
    // Only update if product and prices are available
    if (!product?.prices?.[0]) {
      console.log('No price data available for', product.id);
      return;
    }

    // Get the first price for this product (already filtered in ListProducts)
    const productPrice = product.prices[0];
    
    // Set the price object with a small delay to prevent UI glitching
    // during transitions between yearly and monthly
    const timer = setTimeout(() => {
      setDisplayedPrice(productPrice);
    }, 50);
    
    return () => clearTimeout(timer);
  }, [product.id, product.prices, selected]);

  return (
    <div style={styles.container}>
      {isYearlyPlan && !isActive && <SaveBadge />}
      <ProductCardHeader
        displayedPrice={displayedPrice || product?.prices?.[0]}
        selected={selected}
        currency={currency}
        product={product}
      />
      <ProductCardButtons
        displayedPrice={displayedPrice || product?.prices?.[0]}
        selected={selected}
        product={product}
        currency={currency}
        payments={payments}
        isActive={isActive}
        subscription={productSubscription}
      />
      <ProductFeatures product={product} />
    </div>
  );
}
const styles = {
  container: {
    flex: 1,
    maxWidth: '30%',
    backgroundColor: colors.BLACK,
    margin: 20,
    minWidth: 0,
    borderRadius: 15,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 30,
    position: 'relative', // Added for absolute positioning of the badge
  },
  saveBadge: {
    position: 'absolute',
    top: -20,
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: colors.GREEN_VALID, // Green color
    borderRadius: '10%',
    height: 40,
    padding: '0 10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    transition: 'opacity 0.3s ease', // Add smooth transition
  },
  saveBadgeText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 12,
    textAlign: 'center',
  },
};
