import React, { useEffect, useState } from 'react';
import './styles/ImageIdealPositionDouble.css'; // Assuming your CSS is in this file
import ImageIdealPositionAssets from './ImageIdealPositionAssets';
import { CONTAINED, SCORE_BAD, SCORE_GOOD } from 'constant/constants';
import { colors } from 'themes';

import { ReactComponent as Ungrouped } from 'assets/svg/ungrouped.svg';
import { ReactComponent as Grouped } from 'assets/svg/grouped.svg';
import { Button } from '@mui/material';
import styled from '@emotion/styled';
import { getKeyName, toGoodKey } from 'utils/utils';

export default function ImageIdealPositionDouble({
  analysisKey,
  angle,
  rangeGood,
  item,
  score,
  side
}) {
  const [separated, setSeparated] = useState(false);

  useEffect(() => {
    setSeparated(false);
    setTimeout(() => {
      setSeparated(true);
    }, 1000);
  }, [analysisKey]);

  if (angle === undefined || rangeGood === undefined) return;
  const perfectKey = toGoodKey(analysisKey, angle);

  function handleButtonClicked() {
    setSeparated(!separated);
  }
  const middleValue = () => {
    if (item === 'backCycle' || item === 'LeftKnee') {
      return rangeGood[1] - 10;
    }
    if (item === 'pelvic') {
      return 0;
    }
    else return rangeGood[0] + (rangeGood[1] - rangeGood[0]) / 2;
  };
  return (
    <>
      {analysisKey === perfectKey || score === SCORE_GOOD ? (
        <div style={{ width: '50%', alignSelf: 'center' }}>
          <ImageIdealPositionAssets
            angle={middleValue()}
            item={item}
            score={SCORE_GOOD}
            analysisKey={analysisKey}
            side={side}
          />
        </div>
      ) : (
        <>
          <div className="animatedImagesContainer">
            <div
              className={`animatedImage front ${separated ? 'moveRight' : ''}`}>
              <ImageIdealPositionAssets
                angle={angle}
                item={item}
                score={score}
                analysisKey={analysisKey}
                side={side}
              />
            </div>
            <div className={`animatedImage ${separated ? 'moveLeft' : ''}`}>
              <ImageIdealPositionAssets
                angle={middleValue()}
                item={item}
                score={SCORE_GOOD}
                analysisKey={analysisKey}
                side={side}
              />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <GroupedButton
              variant={CONTAINED}
              sx={{
                width: 60,
                height: 35,
                borderRadius: 2,
                backgroundColor: colors?.APP_BACKGROUND,
              }}
              onClick={handleButtonClicked}>
              {!separated ? (
                <Ungrouped
                  color={colors.WHITE}
                  height={30}
                  width={30}
                  stroke={colors?.WHITE_OPACITY_80}
                  strokeWidth="2"
                />
              ) : (
                <Grouped
                  color={colors.WHITE}
                  height={30}
                  width={30}
                  stroke={colors?.WHITE_OPACITY_80}
                  strokeWidth="2"
                />
              )}
            </GroupedButton>
          </div>
        </>
      )}
    </>
  );
}

const GroupedButton = styled(Button)({
  '&:hover': {
    backgroundColor: colors.SECONDARY,
  },
});
