import React, { useEffect, useState, useRef, useCallback } from 'react';
import AnalysisCard from '../AnalysisListCard/AnalysisCard';
import NoVideos from './NoVideos';
import { useDispatch, useSelector } from 'react-redux';

import { selectAthleteSelected } from 'slices/athleteSlice';
import { addItemToListAnalysisData, analysisListData, loadingList, removeItemFromListAnalysisData, setLoadingList } from 'slices/analysisSlice';
import LoadingComponent from 'components/atoms/LoadingComponent';
import { useProjectChanges } from 'hooks/useProjectChanges';
import { getContentForBottomCard } from 'utils/analysis';
import { ANALYSISTYPE_PRONATION } from 'constant/constants';

function AllAnalysis({ comparedAnalysis, setComparedAnalysis }) {

  const athleteSlice = useSelector(selectAthleteSelected);
  const isLoading = useSelector(loadingList);
  const { projects, analysisDetails, projectsLoading } = useProjectChanges();
  const dispatch = useDispatch();
  const analysisListDataFromStore = useSelector(analysisListData);

  const [visibleItems, setVisibleItems] = useState(12);
  const observer = useRef(null);

  const lastItemRef = useRef();


  const handleLoadMore = useCallback(() => {
    setVisibleItems((prev) => prev + 12);
  }, []);

  useEffect(() => {
    const fetchData = async (isNewAnalysis = false) => {
      if (projectsLoading) return;
      let filteredList = [];
      if (Object.keys(athleteSlice).length > 0) {
        filteredList = analysisDetails?.filter(analysis => analysis?.data?.tag?.id === athleteSlice?.id);
      } else {
        filteredList = analysisDetails;
      }

      filteredList?.sort((a, b) => {
        const dateA = new Date(a?.date);
        const dateB = new Date(b?.date);
        if (isNaN(dateA)) return 1;
        if (isNaN(dateB)) return -1;
        return dateB - dateA;
      });

      for (const analysis of filteredList) {
        if (analysisListDataFromStore?.find(item => item?.data?.uploadVideoId === analysis?.data?.uploadVideoId)) continue;
        if (analysis?.analysis_type === ANALYSISTYPE_PRONATION && analysis?.json_version > 1) {
          dispatch(addItemToListAnalysisData({ analysis: { ...analysis, analysis_content: getContentForBottomCard(analysis?.analysis_content, analysis?.data?.tag?.full_body) }, isNewAnalysis }));
        }
        else dispatch(addItemToListAnalysisData({ analysis, isNewAnalysis }));
      }
    };

    if (projects?.analysis?.length < analysisListDataFromStore?.length && analysisDetails?.length < analysisListDataFromStore?.length) {
      const toDelete = [];

      analysisListDataFromStore?.forEach((item) => {
        const analysisId = item?.data?.uploadVideoId;
        const found = projects?.analysis?.indexOf(analysisId);
        if (found === -1) {
          toDelete.push(analysisId);
        }
      });


      toDelete?.forEach((analysisId) => {
        dispatch(
          removeItemFromListAnalysisData({
            data: { uploadVideoId: analysisId },
          }),
        );
      })
    }

    if ((projects?.analysis?.length === 0 && !projectsLoading)) {
      dispatch(setLoadingList(false));
      return;
    }

    if (analysisListDataFromStore?.length > 0) {
      dispatch(setLoadingList(false));
      fetchData(true);
      return;
    }


    fetchData();
  }, [athleteSlice, analysisDetails?.length, dispatch, analysisListDataFromStore, projects?.analysis?.length, isLoading, projectsLoading]);


  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    observer.current = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        handleLoadMore();
      }
    }, options);

    if (lastItemRef.current) {
      observer.current.observe(lastItemRef.current);
    }

    return () => {
      if (observer.current && lastItemRef.current) {
        observer.current.unobserve(lastItemRef.current);
      }
    };
  }, [analysisListDataFromStore, isLoading, projectsLoading, lastItemRef, analysisDetails, handleLoadMore]);


  if (isLoading || projectsLoading) {
    return (
      <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
          display: 'flex',
        }}
      >
        <LoadingComponent size={125} />
      </div>
    );
  }


  if (analysisListDataFromStore?.length === 0 && athleteSlice && !isLoading && !projectsLoading) return <NoVideos />;
  else {
    return (
      <>
        <div style={styles.container}>
          {analysisListDataFromStore?.slice(0, visibleItems)?.map((analysis, index) => (
            <AnalysisCard
              key={analysis?.data?.uploadVideoId}
              analysisId={analysis?.data?.uploadVideoId}
              analysisFromStore={analysis}
              comparedAnalysis={comparedAnalysis}
              setComparedAnalysis={setComparedAnalysis}
            />
          ))}
          <div ref={lastItemRef} style={{ height: '10vh' }}></div>
        </div>
      </>
    );
  }
}


const styles = {
  container: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'repeat(3, minmax(43vh, 1fr))',
    gridGap: 20,
    width: '98%',
  },
};

export default AllAnalysis;
