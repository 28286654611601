import {checkUserPermission} from 'utils/utils';
import {ATHLETE} from './constants';

export const ANALYSIS_ITEMS = {
  running: [
    {
      category: 'Segments',
      items: {
        FootLanding: 'Foot landing',
        Head: 'Head',
        Trunk: 'Trunk',
        Arm: 'Arm',
        ForwardCycle: 'Front leg',
        BackCycle: 'Back leg',
      },
    },
    {
      category: 'Metrics',
      items: {
        TimeBetweenBteps: 'Time of flight',
        GroundContactTime: 'Ground contact time',
        NumberOfStep: 'Step frequency',
        DutyFactor: 'Duty factor',
        StrideLength: 'Stride length',
        StepLength: 'Step length',
      },
    },
    {
      category: 'JointAngles',
      items: {
        Knee: 'knee',
        Hip: 'hip',
        Elbow: 'elbow',
      },
    },
    {
      category: 'Other',
      items: {
        AnalysisScore: 'Analysis score',
        RunningStyle: 'Running style',
      },
    },
  ],
  pronation: [
    {
      category: 'Stance',
      items: {
        LeftHip: 'Left pelvic',
        RightHip: 'Right pelvic',
        LeftKnee: 'Left knee',
        RightKnee: 'Right knee',
        left_foot: 'Left foot',
        right_foot: 'Right foot',
      },
    },
  ],
};

export function getAnalysisItems(analysisType) {
  const jointAnglesPermission = !checkUserPermission([ATHLETE]);

  let analysisItems = [...ANALYSIS_ITEMS[analysisType]];
  if (!jointAnglesPermission) {
    analysisItems = analysisItems.filter(
      item => item.category !== 'JointAngles',
    );
  }
  return analysisItems;
}

export const UNDER_FOOT_ASSETS = {
  neutralL:
    'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FmailsAssets%2FBackView%2FneutralL.png?alt=media&token=efc5571a-9db3-49c2-b0a7-6db8900027b0',
  neutralR:
    'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FmailsAssets%2FBackView%2FneutralR.png?alt=media&token=c78cc698-4ef7-4e75-a31a-b82045735c33',
  overpronationL:
    'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FmailsAssets%2FBackView%2FoverpronationL.png?alt=media&token=d9b566e9-3b30-422f-9061-0296e8926053',
  overpronationR:
    'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FmailsAssets%2FBackView%2FoverpronationR.png?alt=media&token=89e470fa-738c-4085-8986-f0b120c28915',
  oversupinationL:
    'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FmailsAssets%2FBackView%2FoversupinationL.png?alt=media&token=a5fa5780-9288-4c11-85ea-815b5b5d0e30',
  oversupinationR:
    'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FmailsAssets%2FBackView%2FoversupinationR.png?alt=media&token=57b7c6d7-0ad8-4873-8a03-5f0888125dd2',
  pronationL:
    'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FmailsAssets%2FBackView%2FpronationL.png?alt=media&token=ca2ec55b-d3d0-4de7-a39d-53d17002d588',
  pronationR:
    'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FmailsAssets%2FBackView%2FpronationR.png?alt=media&token=201705c7-7bee-4236-a68a-a16942bddca4',
  supinationL:
    'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FmailsAssets%2FBackView%2FsupinationL.png?alt=media&token=0f9c6ff7-cefe-4a44-9e15-dc97bb64dabb',
  supinationR:
    'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FmailsAssets%2FBackView%2FsupinationR.png?alt=media&token=34344b84-c158-49c8-b25b-29b740cb2e54',
};
