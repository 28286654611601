import React, { useState } from "react";

import ListProducts from "./ListProducts";
import ProductsSwitchButton from "./ProductsSwitchButton";

export default function ProductsWrapper({ 
  products, 
  usage, 
  payments,
}) {
  const [selected, setSelected] = useState(true);

  if (
    !products
  )
    return <></>;
  return (
    <>
      <ProductsSwitchButton selected={selected} setSelected={setSelected} />
      <ListProducts 
        products={products} 
        selected={selected} 
        setSelected={setSelected} 
        payments={payments}
      />
    </>
  );
}
