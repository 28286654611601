import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import RunningAnalysisBottomCard from './RunningAnalysisBottomCard';

import CardThumbnail from './CardThumbnail';
import './styles/SuccessCard.css';
import PronationAnalysisBottomCard from './PronationAnalysisBottomCard';
import { Box, Button } from '@mui/material';
import CompareAnalysisButton from './CompareAnalysisButton';
import { ANALYSISTYPE_PRONATION, PORTRAIT, PRONATION, RUNNING } from 'constant/constants';
import AnalysisOptionsButton from './AnalysisOptionsButton';
import PronationAnalysisBottomCardUpdated from './PronationAnalysisBottomCardUpdated';
import { useAnalysisListener } from 'hooks/useAnalysisChanges';

function SuccessCard({
  analysisFromStore,
  comparedAnalysis,
  setComparedAnalysis,
  disabled = false,
}) {
  const [data, setData] = useState(null);
  const [analysisSelected, setAnalysisSelected] = useState(false);
  const navigate = useNavigate();

  const { analysis } = useAnalysisListener(analysisFromStore?.data?.uploadVideoId, analysisFromStore?.data?.locked);

  function isComparisonDisabled() {
    return (comparedAnalysis[0]?.analysis?.analysis_type === PRONATION && analysisFromStore?.analysis_type !== PRONATION)
      || (comparedAnalysis[0] && comparedAnalysis[0]?.analysis?.analysis_type !== PRONATION && analysisFromStore?.analysis_type === PRONATION) ? true : false;
  }



  useEffect(() => {
    if (!comparedAnalysis) {
      setAnalysisSelected(false);
    }
  }, [comparedAnalysis]);

  const handleButtonClick = e => {
    if (analysisFromStore?.data?.locked === true) return;
    setComparedAnalysis(prev => {
      const newArr = [...prev];
      const analysisData = {
        analysisData: data,
        analysis: analysisFromStore,
      };
      const index = newArr.findIndex(
        item =>
          item.analysis?.data?.uploadVideoId ===
          analysisData?.analysis?.data?.uploadVideoId,
      );

      if (index !== -1) {
        newArr.splice(index, 1);
        setAnalysisSelected(false);
      } else if (newArr.length < 2) {
        newArr.push(analysisData);
        setAnalysisSelected(true);
      }
      return newArr;
    });
  };

  function handleCardClicked() {
    if (comparedAnalysis) {
      if (isComparisonDisabled()) return;
      handleButtonClick();
    } else {
      if (analysisFromStore?.id) {
        navigate(`/analysis/${analysisFromStore?.id}`, {
          state: { analysis: { ...analysisFromStore, analysisReference: null }, personalised: analysisFromStore?.personalised },
        });
      } else
        navigate(`/analysis/${analysisFromStore?.data?.uploadVideoId}`, {
          state: { analysis: { ...analysisFromStore, analysisReference: null }, personalised: analysisFromStore?.personalised },
        });
    }
  }

  if (!analysisFromStore) return <></>;
  return (
    <Box onClick={handleCardClicked} sx={styles.container}>
      <div className="success-card-container">
        {comparedAnalysis ? (
          <CompareAnalysisButton
            analysis={analysisFromStore}
            analysisSelected={analysisSelected}
            disabled={isComparisonDisabled()}
          />
        ) : (
          <AnalysisOptionsButton analysis={analysisFromStore} disabled={disabled} />
        )}
        <CardThumbnail analysis={analysisFromStore} thumbnail={analysisFromStore?.thumbnail}
          verticale={analysisFromStore?.analysis_type === ANALYSISTYPE_PRONATION ||
            (analysisFromStore?.treadmill &&
              (analysisFromStore?.video?.orientation === PORTRAIT ||
                analysisFromStore?.video?.height > analysisFromStore?.video?.width))} />

        <div style={styles.personalisedContainer}>
          {(analysisFromStore?.analysis_type === 'pronation' && analysisFromStore?.json_version < 2) ||
            (analysisFromStore?.analysis_type === 'pronation' && analysisFromStore?.data?.tag?.full_body === false) ? (
            <>
              {analysisFromStore?.json_version > 0 ? (
                <PronationAnalysisBottomCardUpdated
                  analysisData={analysisFromStore}
                  locked={analysis?.data?.locked}
                />
              ) : (
                <PronationAnalysisBottomCard
                  analysisData={analysisFromStore}
                  locked={analysis?.data?.locked}
                />
              )}
            </>
          ) : (
            <RunningAnalysisBottomCard
              analysisData={analysisFromStore}
              locked={analysis?.data?.locked}
              analysisType={analysisFromStore?.analysis_type}
            />
          )}
        </div>
      </div>
    </Box>
  );
}

export default SuccessCard;

const styles = {
  container: {
    position: 'relative',
    maxWidth: '35rem',
    textDecoration: 'none',
  },
  personalisedContainer: {
    height: '20%',
    borderBottomRightRadius: 30,
    borderBottomLeftRadius: 30,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
};
