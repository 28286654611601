import React, {useEffect} from 'react';

import OnBoardingContainer from '../components/OnBoardingContainer';
import OnBoardingTemplate from '../components/OnBoardingTemplate';
import TextComponent from 'components/atoms/TextComponent';

import {onBoardingTranslate, translate} from 'services/remoteConfig';
import {mpTrack} from 'services/mixpanel';

import {colors, images} from 'themes';
import {checkUserPermission} from 'utils/utils';
import {COACH} from 'constant/constants';

const metrics = [
  {
    title: 'GroundContactTime',
    value: '0.16 s',
  },
  {
    title: 'TimeBetweenBteps',
    value: '0.17 s',
  },
  {
    title: 'StrideLength',
    value: '1.76 m',
  },
];

function DataDrivenTraining() {
  useEffect(() => {
    checkUserPermission([COACH], true)
      ? mpTrack('Web App OnBoarding Coach Data Driven Training Screen Viewed')
      : mpTrack(
          'Web App OnBoarding Retail Store Sales Credibility Screen Viewed',
        );
  }, []);

  return (
    <OnBoardingContainer previous next>
      <OnBoardingTemplate
        title={
          checkUserPermission([COACH])
            ? onBoardingTranslate('DataDrivenTrainingTitle')
            : onBoardingTranslate('SalesCredibilityTitle')
        }
        text={
          checkUserPermission([COACH])
            ? onBoardingTranslate('DataDrivenTrainingDesc')
            : onBoardingTranslate('SalesCredibilityDescription')
        }>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            flexDirection: 'column',
          }}>
          <TextComponent
            text={translate('YourPosition')}
            sx={{
              fontSize: '1vw',
              color: colors.WHITE,
              position: 'absolute',
              top: '25%',
              left: '38%',
              width: '9vw',
              textAlign: 'center',
            }}
          />
          <img
            src={images.DATA_DRIVEN_TRAINING}
            alt="data-driven-training"
            style={{
              height: '60%',
              width: '60%',
              objectFit: 'contain',
              marginTop: '2vh',
            }}
          />
          <TextComponent
            text={translate('IdealPosition')}
            sx={{
              fontSize: '1vw',
              color: colors.WHITE,
              position: 'absolute',
              top: '25%',
              right: '38%',
              width: '9vw',
              textAlign: 'center',
            }}
          />
          {metrics?.map((metric, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '40%',
                marginTop: '2vh',
                backgroundColor: colors.BLACK,
                padding: '1vh',
                borderRadius: '2vh',
              }}>
              <TextComponent
                text={translate(metric.title)}
                sx={{fontSize: '1.2vw', marginLeft: '1vw', color: colors.WHITE}}
              />
              <TextComponent
                text={metric.value}
                sx={{
                  fontSize: '1.2vw',
                  color: colors.GREEN,
                  marginRight: '1vw',
                }}
              />
            </div>
          ))}
        </div>
      </OnBoardingTemplate>
    </OnBoardingContainer>
  );
}

export default DataDrivenTraining;
