import React from "react";

import { useDispatch, useSelector } from "react-redux";

import HeightComponent from "components/atoms/HeightComponent";
import TextComponent from "components/atoms/TextComponent";
import WeightComponent from "components/atoms/WeightComponent";

import { translate } from "services/remoteConfig";

import { resetAthleteSelected, selectAthleteSelected, setAthleteSelected } from "slices/athleteSlice";

import { colors } from "themes";
import { ReactComponent as Validate } from "assets/svg/validate.svg";

import "./styles/UploadAthleteCard.css";

function UploadAthleteCard({ item }) {

  const athleteSlice = useSelector(selectAthleteSelected);
  const dispatch = useDispatch();

  const search = useSelector((state) => state.athleteSelected.search);

  function displayName() {
    if (item?.name) return item?.name
    else if (item?.fullName) return item?.fullName
    else if (athleteSlice?.name) return athleteSlice?.name
    else if (athleteSlice?.fullName) return athleteSlice?.fullName
    else return translate("NameAndLastName")
  }
  function displayEmail() {
    if (item?.emailAddress) return item?.emailAddress
    else if (athleteSlice?.emailAddress) return athleteSlice?.emailAddress
    else return null
  }
  function displayHeight() {
    if (item?.height) return item?.height
    else if (athleteSlice?.height) return athleteSlice?.height
    else return "0"
  }
  function displayWeight() {
    if (item?.weight) return item?.weight
    else if (athleteSlice?.weight) return athleteSlice?.weight
    else return "0"
  }

  if (!item?.name?.toLowerCase().includes(search?.toLowerCase())) {
    return null
  }
  return (
    <div
      key={item?.id}
      className={
        athleteSlice?.id === item?.id
          ? "upload-athlete-card-container-selected"
          : "upload-athlete-card-container"
      }
      onClick={() => {
        if (athleteSlice?.id === item?.id) {

          dispatch(resetAthleteSelected())
        }
        else {
          dispatch(setAthleteSelected(item))
        }
      }}
    >
      <div style={{ marginLeft: "3%" }}>
        <div
          style={{
            borderRadius: 20,
            height: "1rem",
            width: "1rem",
            border:
              athleteSlice?.id === item?.id
                ? "solid 3px #FF681D"
                : "solid 3px #3F3F3F",
            backgroundColor:
              athleteSlice?.id === item?.id ? colors?.SECONDARY : null,
          }}
        >
          {athleteSlice?.id === item?.id ? <Validate stroke="white" strokeWidth={3} /> : null}
        </div>
      </div>
      <div style={{ width: "100%", marginLeft: "7%" }}>
        <TextComponent text={displayName()} />
        <TextComponent text={displayEmail()} sx={{ color: colors?.TEXT_GRAY }} />

        <div style={{ display: "flex", alignItems: "center" }}>
          <HeightComponent height={displayHeight()} />
          <WeightComponent weight={displayWeight()} />
        </div>
      </div>
    </div>
  );
}

export default UploadAthleteCard;
