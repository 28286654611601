import React from 'react';
import {score_colors} from 'screen/analysis/AnalysisListCard/constants/analysisListConstants';

export default function CircleSvg({x, y, score, r = 7.16, r2 = 3.5}) {
  if (!score || x === undefined || y === undefined) return null;

  return (
    <>
      <circle
        data-figma-bg-blur-radius={100}
        cx={x}
        cy={y}
        r={r}
        fill="black"
        fillOpacity={0.13}
        stroke={score_colors[score]?.stroke}
      />
      <circle
        cx={x}
        cy={y}
        r={r2}
        fill={score_colors[score]?.stroke}
        stroke="white"
      />
      <defs>
        <circle transform="translate(100 100)" cx={x} cy={y} r={7.16602} />
      </defs>
    </>
  );
}
