import React, {useEffect} from 'react';
import OnBoardingContainer from '../components/OnBoardingContainer';
import OnBoardingTemplate from '../components/OnBoardingTemplate';
import {onBoardingTranslate} from 'services/remoteConfig';
import {images} from 'themes';
import {mpTrack} from 'services/mixpanel';

function BrandCustomizationFeature() {
  useEffect(() => {
    mpTrack(
      'Web App OnBoarding Retail Store Brand Customization Screen Viewed',
    );
  }, []);

  return (
    <OnBoardingContainer previous next>
      <OnBoardingTemplate
        title={onBoardingTranslate('BrandCustomizationTitle')}
        text={onBoardingTranslate('BrandCustomizationDescription')}>
        <img
          src={images.RUNNING_STORE}
          alt="running-store"
          style={{height: '100%', width: '30%', objectFit: 'cover'}}
        />
      </OnBoardingTemplate>
    </OnBoardingContainer>
  );
}

export default BrandCustomizationFeature;
