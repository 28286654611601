import { Typography } from '@mui/material';
import { LIGHT } from 'constant/constants';
import React from 'react';
import { colors } from 'themes';

const DIV = 'div';

function TextComponent({
  text,
  variant,
  weight,
  sx,
  multipleText,
  className,
  center = false,
  onClick,
}) {
  if (multipleText) {
    return (
      <div className={className} style={center ? { textAlign: 'center' } : {}}>
        {multipleText.map((item, index) => (
          <Typography
            key={index}
            variant={item?.variant}
            component={DIV}
            fontWeight={item?.weight ? item?.weight : LIGHT}
            sx={{ ...styles.multipleText, ...item?.style, ...sx }}>
            {item?.text}
          </Typography>
        ))}
      </div>
    );
  }
  return (
    <Typography
      className={className}
      onClick={onClick}
      variant={variant}
      component={DIV}
      fontWeight={weight ? weight : LIGHT}
      sx={{ ...styles.text, ...sx }}>
      {text}
    </Typography>
  );
}

const styles = {
  multipleText: {
    color: colors.WHITE,
    display: "inline",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    whiteSpace: "pre-line",
  },
  text: {
    color: colors.WHITE,
    textTransform: 'none',
  },
};

export default TextComponent;
