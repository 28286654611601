import { ATHLETE, COACH, PROFESSIONAL, RETAIL_STORE } from "constant/constants";
import { ATHLETE_STACK, COACH_STACK, COMMON_STACK, PROFESSIONAL_STACK, RETAIL_STORE_STACK } from "constant/onBoardingConstants";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
export const RegistrationContext = React.createContext();

export const RegistrationProvider = ({ children }) => {
    const navigate = useNavigate();
    const [userType, setUserType] = useState(null);
    const [combinedStack, setCombinedStack] = useState(COMMON_STACK);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        if (userType === ATHLETE) {
            setCombinedStack([...COMMON_STACK, ...ATHLETE_STACK]);
        } else if (userType === COACH) {
            setCombinedStack([...COMMON_STACK, ...COACH_STACK]);
        } else if (userType === PROFESSIONAL) {
            setCombinedStack([...COMMON_STACK, ...PROFESSIONAL_STACK]);
        } else if (userType === RETAIL_STORE) {
            setCombinedStack([...COMMON_STACK, ...RETAIL_STORE_STACK]);
        }
        else {
            setCombinedStack(COMMON_STACK);
        }
    }, [userType]);

    useEffect(() => {
        userType && goToNextStep();
    }, [combinedStack]);

    async function updateUserType(newUserType) {
        setUserType(newUserType);
    }

    const goToNextStep = () => {
        console.log("currentIndex", currentIndex);
        if (currentIndex < combinedStack.length - 1) {
            setCurrentIndex(prev => prev + 1);
            navigate(combinedStack[currentIndex + 1].path);
        }
    };

    const goToPreviousStep = () => {
        if (currentIndex > 0) {
            navigate(combinedStack[currentIndex - 1].path);
            setCurrentIndex(prev => prev - 1);
        }
    };

    return (
        <RegistrationContext.Provider value={{ combinedStack, userType, currentIndex, updateUserType, goToNextStep, goToPreviousStep, setCurrentIndex, setCombinedStack }}>
            {children}
        </RegistrationContext.Provider>
    );
};
