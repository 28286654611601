import React, { useRef } from 'react';

import { useLocation } from 'react-router-dom';

import VideoValidation from './VideoValidation';


import { uploadAnalysisTypeList } from 'constant/uploadContant';
import { colors } from 'themes';
import './components/styles/ChoseAnalysisType.css';
import { useSelector } from 'react-redux';
import AnalysisTypeButton from './components/AnalysisTypeButton';
import { Grid } from '@mui/material';
import ModalComponent from 'components/molecules/ModalComponent';


export default function ChoseAnalysisType({ startUpload }) {
  const location = useLocation();
  const userSlice = useSelector(state => state.user);
  const analysisTypeList = uploadAnalysisTypeList;

  const modalRef = useRef();

  if (location?.pathname?.includes('running_treadmill')) {
    return (
      <VideoValidation
        analysisType={'running_treadmill'}
        startUpload={startUpload}
      />
    );
  }
  if (location?.pathname?.includes('running')) {
    return (
      <VideoValidation analysisType={'running'} startUpload={startUpload} />
    );
  }
  else if (location?.pathname?.includes('pronation')) {
    return (
      <VideoValidation analysisType={'pronation'} startUpload={startUpload} />
    );
  }



  return (
    <div style={styles.container}>
      <Grid container spacing={3} justifyContent="center" alignItems="center" style={styles.grid}>
        {
          Object.values(analysisTypeList)?.map((item, index) => {
            if (!item?.userType?.includes(userSlice?.userType)) return null;
            return (
              <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
                <AnalysisTypeButton
                  item={item}
                  modalRef={modalRef}
                />
              </Grid>
            );
          })
        }
      </Grid>

      <ModalComponent ref={modalRef} style={styles.modal} blur />
    </div>

  );
}

const styles = {
  container: {
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  grid: { padding: '5vh 3vw' },
  modal: { width: '50%' }

};
