import TextComponent from 'components/atoms/TextComponent';
import {
  ANALYSISTYPE_PRONATION,
  BODY_2,
  BODY_3,
  BODY_4,
  BOLD,
  H5,
  H6,
  MEDIUM,
  YELLOW,
} from 'constant/constants';
import React, { useState, useEffect } from 'react';
import { translate } from 'services/remoteConfig';
import ImageIdealPositionDouble from './ImageIdealPositionDouble';
import Ruler from './Ruler';
import BodyContainerContentRecapDisplay from './BodyContainerContentRecapDisplay';
import ExerciceContainer from 'screen/analysis/exercices/ExerciceContainer';
import BodyContainerContentFoot from './BodyContainerContentFoot';
import BodyContainerContentFootUpdated from './BodyContainerContentFootUpdated';
import MultipleButton from 'components/molecules/MultipleButton';
import CardWithRoundedIcon from 'components/molecules/CardWithRoundedIcon';

import { ReactComponent as Whistle } from 'assets/svg/whistle.svg';
import { colors } from 'themes';

function AnalysisRightPanelUpdateDetail({
  displayData,
  analysisTexts,
  exercices,
  version,
  overstrideData,
  locked,
  modalRef,
  exercicesLocked,
  onBoarding,
  analysisType,
}) {


  const [phase, setPhase] = useState(translate('Recap'));
  const [phaseContent, setPhaseContent] = useState(displayData?.phases?.impact);


  const config = [];

  if (displayData?.recap) {
    config.push({ title: translate('Recap'), text: displayData?.recap, score: displayData?.score });
  }
  if (displayData?.segmentedExplanation?.consequences) {
    config.push({ title: translate('Consequences'), text: displayData?.segmentedExplanation?.consequences, score: displayData?.score, asset: displayData?.consequencesAsset });
  }
  if (displayData?.segmentedExplanation?.correction) {
    config.push({ title: translate('Correction'), text: displayData?.segmentedExplanation?.correction, score: displayData?.score });
  }


  useEffect(() => {
    setPhaseContent(config?.find(item => item?.title === phase));
  }, [phase, displayData]);

  if (displayData?.type === 'RightAnkle') {
    if (displayData?.phases) {
      return (
        <BodyContainerContentFootUpdated
          locked={exercicesLocked}
          displayData={displayData}
          overstrideData={overstrideData}
        />
      );
    } else
      return (
        <BodyContainerContentFoot
          locked={exercicesLocked}
          displayData={displayData}
          overstrideData={overstrideData}
        />
      );
  }

  return (
    <div
      style={{
        ...{ display: 'flex', flexDirection: 'column' },
        ...(locked && exercicesLocked ? styles.lockedContainer : null),
      }}>
      <div
        style={{
          maxWidth: '40vh',
          maxHeight: '40vh',
          alignSelf: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <>
          <TextComponent
            text={displayData?.text1}
            variant={H5}
            weight={BOLD}
            sx={styles.title}
          />
          <TextComponent
            text={displayData?.title}
            sx={{ ...styles.title, ...styles.subtitle }}
          />
        </>
        <ImageIdealPositionDouble
          analysisKey={displayData?.analysisKey}
          angle={displayData?.angle}
          rangeGood={displayData?.rangeGood}
          item={displayData?.type}
          score={displayData?.score}
          side={displayData?.side}
        />
      </div>
      <div
        style={{
          marginTop: 50,
          width: '85%',
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'center',
          position: 'relative',
          marginBottom: 30,
        }}>
        <TextComponent
          text={analysisType === ANALYSISTYPE_PRONATION
            ? translate('MeanAngleFootLanding')
            : translate('Angles')}
          weight={MEDIUM}
          variant={H6}
          sx={{ marginBottom: '2vh' }}
        />
        <Ruler
          angle={displayData?.angle}
          rangeGood={displayData?.rangeGood}
          rangeOkay={displayData?.rangeOkay}
          score={displayData?.score}
          rangeToDisplay={displayData?.rangeToDisplay}
          rulerTexts={displayData?.rulerTexts}
        />
      </div>
      <div style={{ marginBottom: 40, width: '95%', alignSelf: 'center' }}>
        <MultipleButton
          config={config}
          navigation={phase}
          setNavigation={setPhase}
        />
        <BodyContainerContentRecapDisplay item={phaseContent} score={phaseContent?.score} multipleLines={true} side={displayData?.side} />
      </div>

      {displayData?.segmentedExplanation?.coach_tips &&
        <>
          <CardWithRoundedIcon icon={Whistle} type={YELLOW}>
            <TextComponent
              text={translate('CoachTips')}
              weight={BOLD}
              variant={H6}
              sx={{ textAlign: 'center', marginTop: '2.5vh' }}
            />
            <TextComponent
              text={displayData?.segmentedExplanation?.coach_tips}
              variant={BODY_2}
              weight={MEDIUM}
              sx={{ textAlign: 'center', color: colors.LIGHT_GRAY }}
            />
          </CardWithRoundedIcon>
          <div style={{ marginBottom: 50 }}>
            <div style={{ marginLeft: 20 }}>
              <TextComponent
                text={translate('Exercice')}
                weight={MEDIUM}
                variant={H6}
              />
            </div>
            <ExerciceContainer
              onBoarding={onBoarding}
              data={displayData}
              exercices={exercices}
              analysisTexts={analysisTexts}
              version={version}
              locked={exercicesLocked}
              modalRef={modalRef}
            />
          </div>
        </>}
    </div>
  );
}

const styles = {
  lockedContainer: {
    filter: 'blur(30px)',
    pointerEvents: 'none',
    userSelect: 'none',
  },
  subtitle: {
    color: colors.WHITE_OPACITY_60,
    marginBottom: '2vh',
  },
  title: {
    alignSelf: 'center',
    marginTop: '2vh',
    textAlign: 'center',
    width: '100%',
  },
};

export default AnalysisRightPanelUpdateDetail;
