import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMultipleUserInfo } from "services/userApi";
import {
  selectListItems,
  selectListItemsData,
  setLstItemsData,
} from "slices/userItemsSlice";
import UploadAthleteCard from "./UploadAthleteCard";
import {
  resetAthleteSelected,
  selectAthleteSelected,
  setAthleteSelected,
} from "slices/athleteSlice";
import { useItemChanges } from "hooks/useItemsChanges";
import "./styles/UploadAthleteList.css";
import NoAthlete from "components/template/NoAthlete";
import AddAthleteCustom from "screen/athlete/components/AddAthleteCustom";
import AthleteSearchBar from "components/atoms/AthleteSearchBar";
function UploadAthleteList({ option, setOption }) {
  useItemChanges();

  const itemList = useSelector(selectListItems);
  const listIdNoPending = [];
  const listCustomItem = [];

  const athleteSlice = useSelector(selectAthleteSelected);
  const dispatch = useDispatch();

  const userListData = useSelector(selectListItemsData);
  const copyOfUserListData = userListData ? [...userListData] : [];

  function handleButtonClick(item) {
    if (Object.keys(athleteSlice).length > 0) {
      dispatch(resetAthleteSelected());
    } else {
      dispatch(setAthleteSelected(item));
    }
  }

  const getUserData = async () => {
    const userData = await getMultipleUserInfo(listIdNoPending);
    if (listCustomItem?.length > 0) userData?.push(...listCustomItem);
    dispatch(setLstItemsData(userData));
  };

  useEffect(() => {
    for (const items in itemList) {
      if (!itemList[items]?.pending) {
        if (itemList[items]?.type === "patient") {
          listCustomItem?.push(itemList[items]);
        } else {
          listIdNoPending?.push(itemList[items]?.id);
        }
      }
    }
    getUserData();
  }, [itemList]);

  function showSortedAthelete() {
    return (
      <>
        {copyOfUserListData?.slice().reverse().map((item, index) => (
          <UploadAthleteCard
            key={index}
            item={item}
            onPress={handleButtonClick}
          />
        ))}
      </>
    );
  }

  if (option === 'AddAthlete') {
    return (
      <>
        <AddAthleteCustom setOption={setOption} />
      </>
    );
  }

  if (Object.keys(userListData).length === 0) {
    return (
      <div>
        <NoAthlete upload={true} noButton={true} />
      </div>
    );
  }
  return (
    <>

      {option === 'search' && (
        <AthleteSearchBar />
      )}
      <div className="scrollableContainer">
        <div
          style={{
            display: "grid",
            alignItems: "center",
            gridTemplateColumns: "repeat(auto-fit, minmax(18rem, 1fr))",
            gridGap: '1vh',
            width: "99%",
          }}
        >
          {showSortedAthelete()}
        </div>
      </div>

    </>
  );
}

export default UploadAthleteList;
