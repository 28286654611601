import React, {useState} from 'react';

import {CircularProgress} from '@mui/material';
import {getFunctions, httpsCallable} from 'firebase/functions';

import TextComponent from 'components/atoms/TextComponent';
import ClickableTextComponent from 'components/atoms/ClickableTextComponent';
import StatusTag from '../StatusTag';

import {translate} from 'services/remoteConfig';

import {colors} from 'themes';

export default function SubscriptionInformationPanelHeader({
  subscription,
  product,
  usage,
}) {
  const [isLoading, setIsLoading] = useState(false);

  async function redirectToCustomerPortal() {
    const functions = getFunctions(undefined, 'europe-west3');
    const createPortalLink = httpsCallable(
      functions,
      'ext-firestore-stripe-payments-createPortalLink',
    );
    try {
      const {data} = await createPortalLink({
        returnUrl: window.location.origin + '/subscription',
      });
      window.location.assign(data.url);
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
    }
  }
  const productName = product?.find(p => p?.id === subscription?.product)
    ?.metadata?.name;

  async function handleClick() {
    setIsLoading(true);
    if (subscription) {
      redirectToCustomerPortal();
    } else {
      //open intercom for support
      window.Intercom?.('show');
      setIsLoading(false);
    }
  }
  const displayTitle = () => {
    if (usage?.yearlyPlan === true || usage.monthlyPlan === true && subscription)  {
      return translate('YourPlan') + ' : ' + translate(productName);
    }
    return translate('YourPlan') + ' : ' + (usage?.yearlyPlan === true ? translate('YearlyPlan') : translate('MonthlyPlan')) + ' ' + translate('UnlimitedAnalysis');
  };
  return (
    <div style={styles.headerContainer}>
      <TextComponent variant="h6" text={displayTitle()} sx={styles.title} />
      <div style={styles.container}>
        <div style={styles.subContainer}>
          {(usage?.yearlyPlan === true || usage.monthlyPlan === true) &&
            (isLoading ? (
              <div style={styles.loaderContainer}>
                <CircularProgress
                  size={20}
                  sx={{color: 'rgba(255, 255, 255, 0.7)'}}
                />
              </div>
            ) : (
              <ClickableTextComponent
                onPress={handleClick}
                title={translate('ManageSubscription')}
                sx={{color: 'rgba(255, 255, 255, 0.7)'}}
              />
            ))}
        </div>
        <StatusTag usage={usage} subscription={subscription} />
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 10,
    alignSelf: 'flex-end',
  },
  headerContainer: {
    marginTop: 15,
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
  subContainer: {
    marginRight: 15,
    justifyContent: 'center',
    backgroundColor: colors.PRIMARY,
    borderRadius: 10,
    padding: 5,
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 8px',
  },
  title: {
    textAlign: 'left',
    marginLeft: 5,
  },
};
