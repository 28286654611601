import React, {useContext, useEffect} from 'react';
import {mpTrack} from 'services/mixpanel';
import OnBoardingContainer from '../components/OnBoardingContainer';
import OnBoardingTemplate from '../components/OnBoardingTemplate';
import {onBoardingTranslate} from 'services/remoteConfig';
import OnBoardingButtonList from '../components/OnBoardingButtonList';
import {storeAnalysisExperience} from '../constants/onBoardingButtonData';
import {RegistrationContext} from 'contexts/RegistrationContext';
import {RETAIL_STORE_EXPERIENCED_SCREEN} from 'constant/onBoardingConstants';
import {REGISTRATION_DATA} from 'constant/constants';

function AnalysisExperienceCheck() {
  const {currentIndex, setCombinedStack} = useContext(RegistrationContext);

  useEffect(() => {
    mpTrack(
      'Web App OnBoarding RetailStore Analysis Experience Selection Screen Viewed',
    );
  }, []);

  function setRetailStack(item) {
    const onBoardingUserData = JSON.parse(
      localStorage.getItem(REGISTRATION_DATA),
    );

    localStorage.setItem(
      REGISTRATION_DATA,
      JSON.stringify({
        ...onBoardingUserData,
        ...item?.userData,
      }),
    );
    mpTrack(item?.tracking);
    if (item?.text === 'No') {
      setCombinedStack(prev => {
        const stack = [...prev];
        stack?.splice(currentIndex + 1, 0, ...RETAIL_STORE_EXPERIENCED_SCREEN);
        return stack;
      });
    } else if (item?.text === 'Yes') {
      setCombinedStack(prev => {
        const stack = [...prev];
        const index = stack.findIndex(
          obj => obj === RETAIL_STORE_EXPERIENCED_SCREEN[0],
        );
        if (index !== -1) {
          stack.splice(index, RETAIL_STORE_EXPERIENCED_SCREEN?.length);
        }
        return stack;
      });
    }
  }

  return (
    <OnBoardingContainer previous={true}>
      <OnBoardingTemplate
        title={onBoardingTranslate('AnalysisExperienceCheckTitle')}>
        <div style={styles.buttonContainer}>
          <OnBoardingButtonList
            data={storeAnalysisExperience}
            mutlipleSelection={false}
            field={'analysisExperience'}
            onPress={e => {
              setRetailStack(e);
            }}
          />
        </div>
      </OnBoardingTemplate>
    </OnBoardingContainer>
  );
}

const styles = {
  buttonContainer: {
    height: '100%',
    justifyContent: 'space-evenly',
    width: '100%',
  },
};

export default AnalysisExperienceCheck;
