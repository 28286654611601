import React, {useEffect} from 'react';
import OnBoardingContainer from '../components/OnBoardingContainer';
import OnBoardingTemplate from '../components/OnBoardingTemplate';
import {onBoardingTranslate} from 'services/remoteConfig';
import {colors, images} from 'themes';
import {mpTrack} from 'services/mixpanel';
import TextComponent from 'components/atoms/TextComponent';

const logos = [
  images.ADIDAS_LOGO_WHITE,
  images.FFA_LOGO_WHITE,
  images.WVU_LOGO,
  images.BREKEKLEY_LOGO,
];
const dualLogo = [images.FLEET_FEET_LOGO, images.ORANGE_LOGO];

function CustomerUniquenessShowcase() {
  useEffect(() => {
    mpTrack(
      'Web App OnBoarding Retail Store Customer Uniqueness Showcase Screen Viewed',
    );
  }, []);
  return (
    <OnBoardingContainer previous next>
      <OnBoardingTemplate
        title={onBoardingTranslate('CustomerUniquenessShowcaseTitle')}>
        <TextComponent
          text={onBoardingTranslate('CustomerUniquenessShowcaseDescription')}
          sx={{
            marginBottom: 10,
            marginTop: 0,
            color: colors.WHITE_OPACITY_60,
            width: '60%',
            textAlign: 'center',
          }}
        />
        {logos.map((logo, index) => (
          <img
            alt="logo"
            key={index}
            src={logo}
            style={{
              height: '10vh',
            }}
            resizeMode="contain"
          />
        ))}
        <div style={styles.imageRow}>
          {dualLogo.map((logo, index) => (
            <img
              alt="logo2"
              key={index}
              src={logo}
              style={{
                height: '12vh',
              }}
              resizeMode="contain"
            />
          ))}
        </div>
      </OnBoardingTemplate>
    </OnBoardingContainer>
  );
}

const styles = {
  imageRow: {
    display: 'flex',
    width: '40%',
    justifyContent: 'space-evenly',
    marginTop: '5vh',
  },
};

export default CustomerUniquenessShowcase;
