import ButtonComponent from "components/atoms/ButtonComponent";
import TextComponent from "components/atoms/TextComponent";
import { MEDIUM, ORANGE } from "constant/constants";
import React from "react";
import { translate } from "services/remoteConfig";
import { colors } from "themes";

import { ReactComponent as ArrowBack } from "assets/svg/arrow.svg";



function ButtonBackAndNext({
    disabled,
    step,
    goToNextStep,
    goToPreviousStep,
    button,
}) {
    const containerStyle = {
        ...styles.container,
        ...{
            justifyContent: disabled?.disabledBack
                ? "flex-end"
                : "space-between",
        }
    };

    return (
        <div style={containerStyle}>
            {!disabled?.disabledBack && (
                <button
                    onClick={goToPreviousStep}
                    style={styles.touchable}
                >
                    <ArrowBack
                        height={40}
                        width={40}
                        strokeWidth={1}
                        stroke={colors.WHITE}
                        style={styles.leftArrow}
                    />
                    <TextComponent text={translate("GoBack")} weight={MEDIUM} />
                </button>
            )}
            {!disabled?.disabledNext && !disabled?.showNext ? (
                <>
                    {button?.disabled === false ? (
                        <ButtonComponent
                            disabled={disabled?.disabledFinish}
                            onClick={goToNextStep}
                            type={ORANGE}
                            title={button?.title || translate("Finish")}
                        />
                    ) : (
                        <button onClick={goToNextStep} style={styles.touchable}>
                            <TextComponent text={translate("Next")} weight={MEDIUM} />
                            <ArrowBack
                                height={40}
                                width={40}
                                strokeWidth={1}
                                stroke={colors.WHITE}
                            />
                        </button>
                    )}
                </>
            ) : !disabled?.showNext ? (
                <button onClick={goToNextStep} style={styles.touchable} disabled>
                    <TextComponent
                        text={translate("Next")}
                        weight={MEDIUM}
                        sx={{ color: colors.WHITE_DISABLED }}
                    />
                    <ArrowBack
                        height={40}
                        width={40}
                        strokeWidth={1}
                        stroke={colors.WHITE_DISABLED}
                    />
                </button>
            ) : null}
        </div>
    );
}


const styles = {
    container: {
        display: "flex",
        flexDirection: "row",
        height: "40px",
        width: "100%",
    },

    touchable: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        background: "none",
        border: "none",
        cursor: "pointer",
        backgroundColor: 'transparent',
    },
    leftArrow: {
        transform: "rotate(180deg)",
    },
};


export default ButtonBackAndNext;
