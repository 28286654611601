import React, { useEffect, useRef, useState } from 'react';

import VideoComponent from '../VideoComponent';
import PronationMetricsPanel from './PronationMetricsPanel';
import { mpTrack } from 'services/mixpanel';
import TextComponent from 'components/atoms/TextComponent';
import { translate } from 'services/remoteConfig';
import { BLUE_BORDER, BODY_3, BOLD, H6, LANDSCAPE, ORANGE } from 'constant/constants';
import { ReactComponent as Lock } from 'assets/svg/lock.svg';
import { colors } from 'themes';
import PronationAnalysisPanelUpdated from './PronationAnalysisPanelUpdated';
import ButtonComponent from 'components/atoms/ButtonComponent';
import { Grid } from '@mui/material';
import { getDataForBackViewPanel } from 'services/analysis';
import AnalysisCardContent from '../AnalysisCardContent';
import { isMobile } from 'react-device-detect';
import SidePan from 'components/molecules/SidePan';
import AnalysisRightPanelUpdated from '../analysisRightPanelUpdated/AnalysisRightPanelUpdated';
import { BACK_VIEW_PANNEL } from 'constant/analysisIdConstant';
import MultipleButton from 'components/molecules/MultipleButton';
import { createTextObjectReusable } from 'utils/utils';

export default function BackViewAnalysis({
  jsonData,
  locked,
  modalRef,
  onLockedPress,
  shared = false,
}) {
  const data = getDataForBackViewPanel(jsonData);
  const [analysisData, setAnalysisData] = useState(data[0]);
  const sidePanRef = useRef();

  const config = BACK_VIEW_PANNEL;
  const [analysisPanel, setAnalysisPanel] = useState(config[0]?.title);

  useEffect(() => {
    mpTrack('Web App Pronnation Analysis Viewed');
  }, []);

  function handleExportPDF(locked) {
    if (locked) {
      modalRef?.current?.open('locked', 'generatePDF');
    } else {
      mpTrack('Web App Export PDF button Clicked');
      modalRef?.current?.open('generatePDF');
    }
  }


  return (
    <div style={styles.container}>
      <div style={styles.videoContainer}>
        <VideoComponent video={jsonData?.videoUrl} />
        {jsonData?.json_version > 0 && !shared && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ButtonComponent
              type={BLUE_BORDER}
              title={translate('ExportToPDF')}
              onClick={() => handleExportPDF(locked)}
              sx={{ marginTop: '2vh', marginBottom: '2vh' }}
            />
          </div>
        )}
      </div>
      <div style={styles.metricsContainer}>
        <div>
          <MultipleButton
            config={config}
            navigation={analysisPanel}
            setNavigation={setAnalysisPanel}
          />
        </div>
        <div
          style={{
            ...styles.metricsSubContainer,
            ...(locked ? styles.blur : null),
          }}>
          {jsonData?.json_version === 1 || jsonData?.full_body === false ? (
            <>
              <PronationAnalysisPanelUpdated
                analysisData={data?.analysis_content?.pronation_left}
                angle={data?.gait_cycle?.means?.left?.pronation}
                json_version={jsonData?.json_version}
              />
              <div
                style={{
                  width: 2,
                  height: '100%',
                  backgroundColor: colors.WHITE_OPACITY_10,
                  marginTop: 20,
                  borderRadius: 1,
                }}
              />
              <PronationAnalysisPanelUpdated
                analysisData={data?.analysis_content?.pronation_right}
                angle={data?.gait_cycle?.means?.right?.pronation}
                json_version={jsonData?.json_version}
              />
            </>
          ) : jsonData?.json_version > 1 ? (
            <div style={{ display: 'flex', flexDirection: 'column' }} >
              <Grid container spacing={2}>
                {data?.map((analysis, index) => {
                  return (
                    <Grid item xs={12} sm={6} lg={6} key={index}>
                      <AnalysisCardContent
                        cardData={analysis}
                        locked={locked === true}
                        modalRef={modalRef}
                        setAnalysisData={setAnalysisData}
                        personalised={jsonData?.personalised}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <TextComponent
                text={analysisData?.modalContent?.title}
                weight={BOLD}
                sx={styles.modalTitle}
              />
              {analysisData?.modalContent?.asset && (
                <img
                  src={analysisData?.modalContent?.asset}
                  alt=""
                  style={{ height: '30vh', objectFit: 'contain' }}

                />
              )}
              <TextComponent
                multipleText={createTextObjectReusable(
                  analysisData?.modalContent?.description,
                  { style: { fontSize: '0.8vw' } },
                  { style: { color: colors.SECONDARY, fontSize: '1.2vw' }, weight: BOLD, },
                )}
                sx={styles.modalContent}
              />
              <ButtonComponent
                type={ORANGE}
                title={translate('ReadMore')}
                sx={{ maxWidth: '35%', alignSelf: 'center', marginBottom: '5vh', marginTop: '2vh' }}
                onClick={() => window.open(analysisData?.modalContent?.link, '_blank')}
              />
            </div>
          ) :
            (
              <>
                <PronationMetricsPanel
                  posture={jsonData?.left_foot?.posture}
                  meanAngle={jsonData?.left_foot?.mean_angle}
                  angles_list={jsonData?.left_foot?.angles_during_footlanded}
                  side={'left'}
                />
                <PronationMetricsPanel
                  posture={jsonData?.right_foot?.posture}
                  meanAngle={jsonData?.right_foot?.mean_angle}
                  angles_list={jsonData?.right_foot?.angles_during_footlanded}
                  side={'right'}
                />
              </>
            )}
        </div>
      </div>
      {locked ? (
        <div
          style={styles.lockedContainer}
          onClick={() => modalRef?.current?.open('locked')}>
          <Lock height={20} width={20} />
          <TextComponent
            text={translate('Unlock')}
            variant={H6}
            sx={styles.textUnlock}
          />
        </div>
      ) : null}
      {isMobile ? (
        <SidePan
          ref={sidePanRef}
          onButtonPressed={() => {
            sidePanRef?.current?.close();
            document.body.style.overflow = 'auto';
          }}
          fullWidth={true}>
          <div style={{ width: '100%' }}>
            {jsonData?.json_version > 0 ? (
              <AnalysisRightPanelUpdated
                // displayData={analysisData}
                analysisTexts={data}
                // exercices={exercices}
                version={jsonData?.json_version}
                // overstrideData={overStrideContent}
                locked={locked}
                modalRef={modalRef}
              />
            ) : null}
          </div>
        </SidePan>
      ) :
        jsonData?.json_version > 1 && jsonData?.full_body !== false ? (
          <div style={{ width: '55%' }}>
            <AnalysisRightPanelUpdated
              displayData={analysisData}
              analysisTexts={data}
              // exercices={exercices}
              version={jsonData?.json_version}
              locked={locked}
              modalRef={modalRef}
              analysisType={'pronation'}
            />
          </div>)
          : null}
    </div>
  );
}
const styles = {
  blur: {
    filter: 'blur(30px)',
    pointerEvents: 'none',
    userSelect: 'none',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  metricsContainer: {
    display: 'flex',
    bacgroundColor: 'red',
    flexDirection: 'column',
    width: '75%',
    marginLeft: '2vw',
    marginRight: '2vw',
  },
  metricsSubContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '2vh',
  },
  modalContent: {
    alignSelf: 'center',
    marginBottom: '2vh',
  },
  modalTitle: {
    color: colors.SECONDARY,
    marginTop: '4vh',
    width: '80%',
    fontSize: '1.2vw'
  },
  text: {
    color: '#FF884D',
    fontWeight: 'light',
  },
  videoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '25%',
    height: '90vh',
  },
  warningIcon: {
    display: 'flex',
  },
  lockedContainer: {
    display: 'flex',
    flexDirection: 'row',
    top: '15%',
    left: '55%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    cursor: 'pointer',
  },
  textUnlock: {
    color: colors.YELLOW,
    marginLeft: '1vh',
  },
};
