import TextComponent from 'components/atoms/TextComponent';
import { BODY_2, H6 } from 'constant/constants';
import React from 'react';
import RoundedScoreMeter from 'screen/analysis/AnalysisListCard/components/RoundedScoreMeter';
import ScoreTextWithBackground from './components/ScoreTextWithBackground';

function AnalysisCardTopContent({ scoreNumber, scoreText, item, personalised, side }) {

  return (
    <div style={{ display: 'flex', position: 'relative' }}>
      <div
        style={{
          display: 'flex',
          float: 'left',
        }}>
        <RoundedScoreMeter
          scoreNumber={scoreNumber}
          scoreText={scoreText}
          item={item}
          size={80}
          personalised={personalised}
          side={side}
        />
      </div>
      <div style={{ marginTop: '3%', marginLeft: 5 }}>
        <div
          style={{
            display: 'flex',
            paddingTop: '2%',
            alignItems: 'flex-end',
            marginBottom: 5,
          }}>
          {isNaN(scoreNumber) ? null : (
            <TextComponent
              text={(scoreNumber * 100)?.toFixed(0) + '%'}
              sx={{ margin: 0, fontSize: '1rem' }}
            />
          )}
          <TextComponent
            text={'score'}
            variant={BODY_2}
            sx={{
              marginLeft: 0.7,
              marginBottom: 0.3,
              color: '#737373',
            }}
          />
        </div>
        <ScoreTextWithBackground
          scoreNumber={scoreNumber ? scoreNumber : scoreText}
        />
      </div>
    </div>
  );
}

export default AnalysisCardTopContent;
