import React from "react";

import TextComponent from "components/atoms/TextComponent";

import { translate } from "services/remoteConfig";

import { ReactComponent as CheckMarkIcon } from "assets/svg/checkmark.svg";
import { colors } from "themes";

export default function ProductCardFeatures({ product }) {
  function parseCommaSeparatedString(inputString) {
    if (typeof inputString !== 'string' || inputString?.trim() === '') {
      return []; // Return an empty array for invalid input
    }
  
    return inputString?.split(',')?.map(item => item.trim());
  }
  const values = parseCommaSeparatedString(product?.metadata?.features);
  return values?.map((element, index) => {
    return (
      <div key={index} style={styles.container}>
        <div
          style={{
            ...styles.row,
            ...{
              borderTop: index !== 0 && "0.5px solid rgba(255,255,255,0.15)",
            },
          }}
        >
          <div style={{alignSelf: 'center', marginRight: 10}}>
            <CheckMarkIcon
              height={25}
              width={25}
              stroke={colors.VALID_IMAGE}
              fill={colors.VALID_IMAGE}
            />
          </div>
          <TextComponent
            variant="body1"
            text={translate(element)}
            sx={styles.body}
          />
        </div>
      </div>
    );
  });
}
const styles = {
  body: {
    textAlign: "left",
    alignSelf: "center",
    color: colors.WHITE,
  },
  container: {
    display: "flex",
    flexDirection: "row",
    minHeight: 50,
    justifyContent: "center",
    marginLeft: 25,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    width: "80%",
  },
};
