import React from 'react';

import TextComponent from 'components/atoms/TextComponent';

import {translate} from 'services/remoteConfig';
import {ReactComponent as ActiveIcon} from 'assets/svg/activeIcon.svg';
import {ReactComponent as CloseIcon} from 'assets/svg/close.svg';
import {colors} from 'themes';

import {
  SUB_STATUS_ACTIVE,
  SUB_STATUS_CANCELED,
  SUB_STATUS_INCOMPLETE,
} from 'constant/constants';

export default function StatusTag({usage, subscription}) {
  if (
    (!subscription && usage?.yearlyPlan === true) ||
    usage.monthlyPlan === true
  ) {
    return (
      <div
        style={{
          ...styles.container,
          ...{
            backgroundColor: colors.VALID_IMAGE,
          },
        }}>
        <ActiveIcon
          height={20}
          width={20}
          stroke="white"
          style={{alignSelf: 'center', marginRight: 10}}
        />
              <TextComponent
        variant="body2"
          text={translate('ActiveSubscription')}
          sx={{textAlign: 'center', fontWeight: 'bold'}}
        />
      </div>
    );
  }
  return (
    <div
      style={{
        ...styles.container,
        ...{
          backgroundColor:
            subscription?.status === 'active' && !subscription?.cancel_at
              ? colors.VALID_IMAGE
              : subscription?.status === SUB_STATUS_INCOMPLETE
              ? colors.SECONDARY
              : colors.RED,
        },
      }}>
      {subscription?.status === SUB_STATUS_CANCELED ||
      subscription?.cancel_at ? (
        <CloseIcon
          height={20}
          width={20}
          stroke="white"
          style={{alignSelf: 'center', marginRight: 10}}
        />
      ) : subscription?.status === SUB_STATUS_ACTIVE ||
        usage?.subSource === 'InApp' ? (
        <ActiveIcon
          height={20}
          width={20}
          stroke="white"
          style={{alignSelf: 'center', marginRight: 10}}
        />
      ) : (
        <></>
      )}
      <TextComponent
        variant="body2"
        text={
          subscription?.cancel_at !== null
            ? translate('canceled')
            : subscription?.status
        }
        sx={{textAlign: 'center', fontWeight: 'bold'}}
      />
    </div>
  );
}

const styles = {
  container: {
    alignSelf: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
    borderRadius: 15,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 15,
    paddingRight: 15,
  },
};
