import React, {useRef, useState} from 'react';

import {
  createCheckoutSession,
  getPrice,
} from '@invertase/firestore-stripe-payments';
import {ToggleButton, CircularProgress} from '@mui/material';

import TextComponent from 'components/atoms/TextComponent';
import ClickableTextComponent from 'components/atoms/ClickableTextComponent';
import SidePan from 'components/molecules/SidePan';
import SettingsSelector from 'screen/profile/settings/SettingsSelector';

import {translate} from 'services/remoteConfig';

import {colors} from 'themes';



export default function ProductCardButtons({
  displayedPrice,
  product,
  currency,
  selected,
  payments,
  isActive,
  subscription,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const sidePanRef = useRef();

  function handlePress(elementName) {
    if (elementName === 'ContactSales') {
      sidePanRef?.current?.close();
    }
  }

  const submitHandler = async () => {
    try {
      // If user has an active subscription and is trying to subscribe to a different plan

      setIsLoading(true);

      if (product?.type === 'OnDemand') {
        sidePanRef?.current?.open();
        setIsLoading(false);
        return;
      }

      // Find the matching price from Stripe
      if (product?.prices[0]) {
        try {
          // Create a checkout session using the Stripe Firebase extension
          // Show loading state while creating checkout session

          const price = await getPrice(
            payments,
            product.id,
            product?.prices[0].id,
          );
          //          setIsLoading(true);
          const session = await createCheckoutSession(
            payments,
            {
              price: price.id,
              success_url:
                displayedPrice?.type === 'one_time'
                  ? window.location.origin + '/payment/success'
                  : window.location.origin + '/subscription/success',
              cancel_url:
                displayedPrice?.type === 'one_time'
                  ? window.location.origin + '/payment/cancel'
                  : window.location.origin + '/subscription/cancel',
              mode:
                displayedPrice?.type === 'one_time'
                  ? 'payment'
                  : 'subscription',
              automatic_tax: true,
            },
            {
              timeoutMillis: 30000, // Increase timeout to 30 seconds
            },
          );
          // If we get here, the session was created successfully
          setIsLoading(false);
          // Redirect to the checkout page
          if (session && session.url) {
            window.location.assign(session.url);
          }
          return;
        } catch (error) {
          setIsLoading(false);
          console.error(
            'Error creating checkout session:',
            error.message || error,
          );
        }
      }
      return;
    } catch (error) {
      console.error('Error in submit handler:', error);
    } finally {
      setIsLoading(false);
    }
  };

  function handleSwitch() {}

  function getButtonTitle() {
    if (isLoading) {
      return translate('Processing');
    } else if (product?.type === 'OnDemand') {
      return translate('ContactUs');
    } else if (displayedPrice?.type === 'one_time') {
      return translate('BuyNow');
    } else if (isActive) {
      return translate('AlreadySubscribed');
    } else {
      return translate('Subscribe');
    }
  }

  return (
    <>
      <div style={styles.container}>
        <ToggleButton
          disabled={isActive || isLoading}
          style={{
            ...styles.button,
            ...{
              backgroundColor:
                isActive === true ? colors.VALID_IMAGE : colors.SECONDARY,
            },
          }}
          sx={{
            '&:hover': {
              backgroundColor: colors.HOVER,
            },
          }}
          onClick={submitHandler}>
          {isLoading ? (
            <div style={styles.loadingContainer}>
              <CircularProgress
                size={24}
                color="inherit"
                style={styles.spinner}
              />
              <TextComponent
                text={getButtonTitle()}
                sx={{fontWeight: 550, textAlign: 'center', marginLeft: '10px'}}
              />
            </div>
          ) : (
            <TextComponent
              text={getButtonTitle()}
              sx={{fontWeight: 550, textAlign: 'center'}}
            />
          )}
        </ToggleButton>
      </div>
      <div style={styles.textContainer}>
        {product?.type !== 'OnDemand' && (
          <ClickableTextComponent
            onPress={handleSwitch}
            title={
              selected
                ? translate('SwitchToMonthly')
                : translate('SwitchToYearly')
            }
          />
        )}
      </div>
      <SidePan
        ref={sidePanRef}
        onButtonPressed={() => sidePanRef?.current?.close()}
        fullWidth={false}>
        <div style={styles.sidePanContent}>
          <SettingsSelector
            name={'ContactSales'}
            onClick={elementName => handlePress(elementName)}
            sidePanRef={sidePanRef}
          />
        </div>
      </SidePan>
    </>
  );
}
const styles = {
  sidePanContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20%',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    height: '60px',
    alignItems: 'center',
    width: '80%',
    alignSelf: 'center',
    borderRadius: 5,
    marginTop: 1,
  },
  container: {
    justifyContent: 'center',
    display: 'flex',
    marginTop: '5%',
    marginBottom: 5,
  },
  textContainer: {
    justifyContent: 'center',
    display: 'flex',
    marginBottom: 25,
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinner: {
    color: colors.WHITE,
  },
};
