import React from "react";

import TextComponent from "components/atoms/TextComponent";

import { translate } from "services/remoteConfig";

import { colors } from "themes";

export default function PreventSubscription({ usage }) {
  return (
    <>
      <TextComponent
        variant="h3"
        text={translate("AlreadySubscribed")}
        sx={{ alignSelf: "center" }}
      />
      <TextComponent
        variant="h6"
        text={translate("ManageSubscriptionInStores")}
        sx={{
          alignSelf: "center",
          color: colors.WHITE_OPACITY_60,
          fontStyle: "italic",
        }}
      />
    </>
  );
}
