import PickerComponent from "components/atoms/PickerComponent";
import TextInput from "components/atoms/TextInput";
import { CM, FT, IN } from "constant/constants";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { translate } from "services/remoteConfig";
import { feetInchToCm } from "utils/converter";

const HeightTextInput = forwardRef(
  ({ displayInTextInput, handleValueChanges, heightUnityRef, idNext, onChange }, ref) => {
    const yup = require("yup");
    const [formValues, setFormValues] = useState({
      height: null,
    });

    const [formErrors, setFormErrors] = useState({});

    useImperativeHandle(ref, () => ({
      handleSubmit,
      setHeight: (height) => {
        setFormValues({
          ...formValues,
          height: height,
        });
      },
      setHeightAndCompl: (height, heightComplement) => {
        setFormValues({
          height: height,
          heightComplement: heightComplement,
        });
      },
      setHeightComplement: (heightComplement) => {
        setFormValues({
          ...formValues,
          heightComplement: heightComplement,
        });
      },
      getHeight: () => {
        return formValues?.height;
      },
      getHeightComplement: () => {
        return formValues?.heightComplement;
      },
    }));

    const handleSubmit = async (e) => {
      if (e) e.preventDefault();
      try {
        await athleteInfoSchema?.validate(formValues, { abortEarly: false });
        let validHeight;

        if (displayInTextInput) {
          validHeight = feetInchToCm(
            parseFloat(formValues.height),
            parseFloat(formValues.heightComplement)
          );
        } else validHeight = parseFloat(formValues?.height / 100);

        return validHeight;
      } catch (err) {
        if (err.inner) {
          const errors = {};
          err.inner.forEach((error) => {
            errors[error.path] = error.message;
          });
          setFormErrors(errors);
        }
      }
    };



    const athleteInfoSchema = displayInTextInput
      ? yup.object({
        height: yup
          .number(translate("HeightWarning"))
          .required(translate("HeightError"))
          .integer(translate("HeightError"))
          .typeError(translate("HeightError"))
          .min(0, translate("HeightError")),

        heightComplement: yup
          .number(translate("HeightWarning"))
          .required(translate("HeightError"))
          .integer(translate("HeightError"))
          .typeError(translate("HeightError"))
          .min(0, translate("HeightError")),
      })
      : yup.object({
        height: yup
          .number(translate("HeightWarning"))
          .integer(translate("HeightError"))
          .typeError(translate("HeightError"))
          .required(translate("HeightError"))
          .min(0, translate("HeightError")),
      });

    const handleChange = (e) => {
      console.log(e?.target?.value);
      onChange(e);
      setFormValues({
        ...formValues,
        [e.target.name]: e.target.value,
      });
      if (formErrors[e.target.name]) {
        setFormErrors({
          ...formErrors,
          [e.target.name]: "",
        });
      }
    };

    return (
      <div style={{ display: "flex" }}>
        <TextInput
          id={"heightTextInput"}
          name={"height"}
          label={displayInTextInput ? FT : CM}
          value={formValues?.height}
          onChange={handleChange}
          schema={athleteInfoSchema}
          error={!!formErrors?.height}
          helperText={formErrors?.height}
          type="number"
          handleSubmit={() => {
            if (displayInTextInput) {
              document.getElementById("heightComplementTextInput").focus();
            } else {
              idNext ? document.getElementById(idNext).focus() : handleSubmit();
            }
          }}
        />

        {displayInTextInput && (
          <>
            <div style={{ width: 20 }}></div>
            <TextInput
              id={"heightComplementTextInput"}
              name={"heightComplement"}
              label={IN}
              value={formValues?.heightComplement}
              onChange={handleChange}
              schema={athleteInfoSchema}
              error={!!formErrors?.heightComplement}
              helperText={formErrors?.heightComplement}
              type="number"
              handleSubmit={() => {
                idNext
                  ? document.getElementById(idNext).focus()
                  : handleSubmit();
              }}
            />
          </>
        )}
        <div style={{ marginLeft: 10 }}>
          <PickerComponent
            config={[FT, CM]}
            ref={heightUnityRef}
            handleValueChanges={handleValueChanges}
            type={"Metrics"}
          />
        </div>
      </div>
    );
  }
);

export default HeightTextInput;
