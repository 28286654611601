import React, { forwardRef, useImperativeHandle, useState } from "react";
import loading_runner from "assets/animations/loading_runner.json";
import Lottie from "lottie-react";

function LoadingView({ color }) {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: color,
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        zIndex: 100,
      }}
    >
      <Lottie
        animationData={loading_runner}
        loop={true}
        style={{ alignSelf: "center", width: 500, height: 500 }}
      />
    </div>
  );
}

const styles = {
  right: {
    left: -35,
    position: "absolute",
    top: "10%",
    zIndex: 10,
  },
  left: {
    right: -35,
    position: "absolute",
    top: "10%",
    zIndex: 10,
  },
};

export default LoadingView;
