import React, {useEffect, useState} from 'react';

import {firebase} from 'utils/firebaseInit';
import {
  getCurrentUserSubscriptions,
  getStripePayments,
  onCurrentUserSubscriptionUpdate,
} from '@invertase/firestore-stripe-payments';

import SubscriptionInformationPanelHeader from './SubscriptionInformationPanelHeader';
import SubscriptionInformationPlanDetails from './SubscriptionInformationPlanDetails';
import SubscriptionInformationPanelIncomplete from './SubscriptionInformationPanelIncomplete';
import SubscriptionInformationPlanFeatures from './SubscriptionInformationPlanFeatures';
import PreventSubscription from './PreventSubscription';

import {colors} from 'themes';
import {
  SUB_STATUS_INCOMPLETE,
  SUB_STATUS_INCOMPLETE_EXPIRED,
  SUB_STATUS_PAST_DUE,
} from 'constant/constants';
import UsageInfosDisplay from 'screen/profile/components/UsageInfosDisplay';

function SubscriptionInformationPanel({product, usage, payments}) {
  const [subscription, setSubscription] = useState(null);
  const [hasMultipleSubscriptions, setHasMultipleSubscriptions] =
    useState(false);

  useEffect(() => {
    getCurrentUserSubscriptions(payments, {status: 'active'}).then(
      subscriptions => {
        //return all actives subscriptions
        const activeSub = subscriptions?.filter(
          subscription =>
            subscription?.status === 'active' && !subscription?.cancel_at,
        );
        if (activeSub?.length > 1) {
          setHasMultipleSubscriptions(true);
        }
      },
    );
    onCurrentUserSubscriptionUpdate(payments, snapshot => {
      for (const change of snapshot.changes) {
        if (change.type === 'added') {
          setSubscription(change.subscription);
        }
      }
    });
  }, []);

  if (usage?.subSource === 'InApp')
    return (
      <div
        style={{
          ...styles.container,
          ...{
            justifyContent: 'center',
            display: 'flex',
            marginTop: 100,
            padding: 50,
            flexDirection: 'column',
          },
        }}>
        <PreventSubscription />
      </div>
    );

  if (product)
    return (
      <div style={styles.container}>
        <SubscriptionInformationPanelHeader
          subscription={subscription}
          product={product}
          usage={usage}
        />

        <div style={styles.infoPanel}>
          {(usage?.yearlyPlan === true || usage.monthlyPlan === true) && (
            <SubscriptionInformationPlanDetails
              usage={usage}
              subscription={subscription}
              hasMultipleSubscriptions={hasMultipleSubscriptions}
            />
          ) }
          <SubscriptionInformationPlanFeatures
            payments={payments}
            product={product}
            subscription={subscription}
          />
        </div>
      </div>
    );

  return null;
}
const styles = {
  container: {
    alignSelf: 'center',
    width: '80%',
    borderRadius: 10,
    backgroundColor: colors.CARD_BACKGROUND,
    marginTop: 50,
    flex: 1,
  },
  infoPanel: {
    flex: 5,
    display: 'flex',
    marginTop: 20,
    justifyContent: 'space-around',
    padding: 10,
  },
};
export default SubscriptionInformationPanel;
