import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSearch } from 'slices/athleteSlice';
import { translate } from 'services/remoteConfig';
import { isPro } from 'utils/utils';
import TextInput from './TextInput';

function AthleteSearchBar() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setSearch(''));
        return () => {
            dispatch(setSearch(''));
        };
    }, []);

    const handleInputChange = (event) => {
        dispatch(setSearch(event?.target?.value));
    };



    return (
        <div style={{
            alignItems: "center",
            marginBottom: "2vh",
            width: "99%",
        }}>
            <TextInput label={isPro() ? translate('SearchPatient') : translate('SearchAthlete')} onChange={handleInputChange} />
        </div>
    );
}

export default AthleteSearchBar;