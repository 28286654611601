import React, { useContext, useEffect, useState } from 'react';

import TextComponent from 'components/atoms/TextComponent';
import MultipleButton from 'components/molecules/MultipleButton';
import OnBoardingContainer from '../../components/OnBoardingContainer';
import OnBoardingTemplate from '../../components/OnBoardingTemplate';
import {
  videoTutosContent,
  videoTutoTitle,
} from '../../components/VideoTutosData';

import { translate } from 'services/remoteConfig';
import { mpTrack } from 'services/mixpanel';

import { checkUserPermission } from 'utils/utils';

import { ANALYSISTYPE_PRONATION_LOWER_BODY, ANALYSISTYPE_RUNNING, ANALYSISTYPE_RUNNING_TREADMILL, COACH, MEDIUM, REGISTRATION_DATA, RETAIL_STORE, USERTYPE_PROFESSIONAL, USERTYPE_RETAIL_STORE } from 'constant/constants';

import { colors } from 'themes';
import GoodVideoTuto from 'screen/upload/components/GoodVideoTuto';
import { goodVideoTutoContent, uploadAnalysisTypeList } from 'constant/uploadContant';
import { RegistrationContext } from 'contexts/RegistrationContext';

function OnBoardingGoodVideo() {
  const userType = JSON.parse(localStorage.getItem(REGISTRATION_DATA))?.userType;
  const startIndex = userType === USERTYPE_PROFESSIONAL ? 1 :
    userType === USERTYPE_RETAIL_STORE ? 2 : 0;

  const [item, setItem] = useState(
    uploadAnalysisTypeList[userType === USERTYPE_PROFESSIONAL ? ANALYSISTYPE_RUNNING_TREADMILL :
      userType === USERTYPE_RETAIL_STORE ? ANALYSISTYPE_PRONATION_LOWER_BODY : ANALYSISTYPE_RUNNING]);
  const { goToNextStep, goToPreviousStep } = useContext(RegistrationContext);

  const [currentIndex, setCurrentIndex] = useState(startIndex);


  useEffect(() => {
    checkUserPermission([COACH], true)
      ? mpTrack('Web App OnBoarding Coach Video Tuto Screen Viewed')
      : mpTrack('Web App OnBoarding Professional Video Tuto Screen Viewed');
  }, []);

  return (
    <OnBoardingContainer >
      <OnBoardingTemplate>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly' }}>
            {Object.values(uploadAnalysisTypeList)?.map((item, index) => {
              if (!item?.userType?.includes(userType)) return null;
              return (
                <div style={{

                  background: `radial-gradient(ellipse at top, ${index === currentIndex ? colors.SECONDARY_OPACITY_30 : 'rgba(66, 66, 66, 0.3)'} , ${index === currentIndex ? colors.SECONDARY_OPACITY_10 : 'rgba(66, 66, 66, 0.1)'}, ${colors.APP_BACKGROUND})`,
                  border: `1px solid ${index === currentIndex ? colors.SECONDARY_OPACITY_60 : colors.WHITE_OPACITY_10}`,
                  borderRadius: 10,
                  borderWidth: 1,
                  height: '15vh',
                  marginBottom: 15,
                  width: '15vh',
                  alignItems: 'center',
                  justifyContent: 'center',
                  aspectRatio: 1,
                  cursor: 'pointer',
                  display: 'flex',
                  padding: 10,

                }}
                  onClick={() => { setCurrentIndex(index); setItem(item) }}
                >
                  <img
                    src={item?.analysisAsset}
                    alt=""
                    style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'contain',
                    }}
                  />
                </div>
              );
            })}
          </div>
          <GoodVideoTuto item={item} onboarding={{
            goToNextStep: goToNextStep,
            goToPreviousStep: goToPreviousStep
          }} />
        </div>
      </OnBoardingTemplate>

    </OnBoardingContainer >
  );
}
const styles = {
  horizontal: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  vertical: {
    display: 'flex',
    flexDirection: 'row',
    width: '60%',
    alignItems: 'center',
    height: '100%',
  },
  videoHorizontal: { height: '35vh' },
  videoVertical: { width: '40%' },
};
export default OnBoardingGoodVideo;
