import React from "react";

import GradientScoreSystem from "./GradientScoreSystem";
import RoundedScoreMeter from "./RoundedScoreMeter";
import TextComponent from "components/atoms/TextComponent";
import { ANALYSISTYPE_PRONATION, BODY_1, H5 } from "constant/constants";
import { colors } from "themes";
import { translate } from "services/remoteConfig";

function RunningAnalysisBottomCard({ analysisData, locked = false, analysisType }) {

  const order = analysisType === ANALYSISTYPE_PRONATION ?
    ['pelvic_left', 'pelvic_right', 'knee_adduction_left', 'knee_adduction_right', 'pronation_left', 'pronation_right']
    : ['foot', 'head', 'trunk', 'arm', 'forward_cycle', 'back_cycle'];

  const entriesArray =
    !analysisData?.analysis_content ||
      Object.keys(analysisData?.analysis_content).length < 1
      ? []
      : Object?.entries(analysisData?.analysis_content);

  const filteredAndSorted = entriesArray
    .filter(item => item[0] !== 'total_score')
    .sort((a, b) => {
      const indexA = order.indexOf(a[0]);
      const indexB = order.indexOf(b[0]);
      return indexA - indexB;
    });


  if (locked) {
    return (
      <div style={styles.locked}
      >
        <TextComponent
          variant={H5}
          sx={{ color: colors.YELLOW }}
          text={translate('FreeAnalysis')}
        />
      </div>
    );
  }

  if (analysisData?.json_version < 1 || !analysisData?.json_version) {
    return (<div style={{ alignItems: 'center', justifyContent: 'center' }}>
      <TextComponent
        variant={BODY_1}
        sx={{ color: colors.RED, justifyContent: 'center' }}
        text={translate('OutdatedAnalysis')}
      />
    </div>)
  }

  return (
    <>
      {analysisType !== ANALYSISTYPE_PRONATION && <GradientScoreSystem scoreNumber={(analysisData?.analysis_content?.total_score * 100)?.toFixed(0)} />}
      <div style={styles.container}>
        {filteredAndSorted?.map(([key, value]) => {
          if (key === "foot" && value?.score_number === null) return null;
          return (
            <RoundedScoreMeter
              key={key}
              scoreNumber={value?.score_number}
              scoreText={value?.score ? value?.score : value?.score_number <= 0.33 ? 'score_bad' : value?.score_number < 0.66 ? 'score_okay' : 'score_good'}
              item={value?.key || key}
              size={'3.2vw'}
              personalised={analysisType !== ANALYSISTYPE_PRONATION}
              side={value?.side || key.includes('right') ? 'right' : 'left'}
            />
          );
        })}
      </div>
    </>
  );
}

const styles = {
  container: {
    display: "flex",
    right: 0,
    float: "right",
    margin: "0px 5px 0px auto",
  },
  locked: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: '100%'
  }
};

export default RunningAnalysisBottomCard;
