import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import TextComponent from 'components/atoms/TextComponent';
import ButtonComponent from 'components/atoms/ButtonComponent';
import UploadAthleteList from './UploadAthleteList';

import { translate } from 'services/remoteConfig';

import { selectAthleteSelected, } from 'slices/athleteSlice';
import { checkUserPermission, isPro } from 'utils/utils';

import {
  ANALYSISTYPE_PRONATION,
  ANALYSISTYPE_PRONATION_LOWER_BODY,
  ANALYSISTYPE_RUNNING,
  ANALYSISTYPE_RUNNING_TREADMILL,
  ATHLETE,
  BODY_2,
  BOLD,
  MEDIUM,
  ORANGE,
  RETAIL_STORE,
} from 'constant/constants';
import { VIDEO_VALIDATION_CONDITIONS } from 'constant/uploadContant';
import { colors } from 'themes';

import './styles/UploadAthleteList.css';
import RunningPaceSelect from './RunningPaceSelector';
import ClickableTextComponent from 'components/atoms/ClickableTextComponent';

import { ReactComponent as PlusIcon } from 'assets/svg/plusIcon.svg';
import { ReactComponent as LoopIcon } from 'assets/svg/loop.svg';
import BodyContainerContentRecapDisplay from 'screen/analysis/AnalysisViewDetailsScreen/components/analysisRightPanelUpdated/BodyContainerContentRecapDisplay';
import { useLocation } from 'react-router-dom';

function VideoChecker({ analysisType, selectedVideo, startUpload }) {
  const athleteSlice = useSelector(selectAthleteSelected);
  const location = useLocation();

  const [step, setStep] = React.useState(0);
  const [pace, setPace] = useState(null);

  const [buttonType, setButtonType] = useState('search');

  function isButtonDisabled() {
    if (selectedVideo) {

      if (analysisType === ANALYSISTYPE_RUNNING_TREADMILL) {
        return pace ? false : true;
      }
      return false;
    } else {
      return true;
    }
  }

  return (
    <div style={styles.container}>
      {step === 0 ? (
        <>
          <div style={styles.validationConditionsContainer}>
            <div
              style={{
                backgroundColor: colors.APP_BACKGROUND,
                padding: 10,
                borderRadius: 20,
                marginBottom: '2vh',
              }}>
              <TextComponent
                text={translate('VideoValidationTitle')}
                variant={BODY_2}
                weight={BOLD}
                sx={{ marginBottom: 2 }}
              />

              <div style={styles.validationListContainer}>
                <BodyContainerContentRecapDisplay
                  score={'score_good'}
                  item={{ text: VIDEO_VALIDATION_CONDITIONS[location?.pathname?.includes(ANALYSISTYPE_PRONATION_LOWER_BODY) ? ANALYSISTYPE_PRONATION_LOWER_BODY : analysisType] }}
                  multipleLines={true}
                />
              </div>
            </div>
            {analysisType === ANALYSISTYPE_RUNNING ||
              analysisType === ANALYSISTYPE_RUNNING_TREADMILL ? (
              <div
                style={{
                  backgroundColor: colors.APP_BACKGROUND,
                  padding: 10,
                  borderRadius: 20,
                  marginBottom: '2vh',
                }}>
                <RunningPaceSelect setPace={setPace} />
              </div>
            ) : null}
          </div>
          <>
            <div
              style={{
                alignSelf: 'center',
                alignItems: 'center',
                marginBottom: 10,
              }}>
              <ButtonComponent
                title={translate('GoodVideoButton')}
                type={ORANGE}
                onClick={() => {
                  if (checkUserPermission([ATHLETE]))
                    startUpload(selectedVideo, {
                      type: analysisType,
                      pace: pace,
                    });
                  else {
                    setStep(1);
                  }
                }}
                disabled={
                  isButtonDisabled()
                }
              />
            </div>

            {analysisType === ANALYSISTYPE_RUNNING ? (
              <div style={{ alignSelf: 'center' }}>
                <ClickableTextComponent
                  title={translate('GoodVideoNoPace')}
                  option={{ underline: true }}
                  onPress={() => {
                    if (checkUserPermission([ATHLETE]))
                      startUpload(selectedVideo, {
                        type: analysisType,
                        pace: pace,
                      });
                    else {
                      setStep(1);
                    }
                  }}
                />
              </div>
            ) : null}
          </>
        </>
      ) : (
        <div
          style={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '2vh' }}>
            <TextComponent
              text={translate(isPro() ? 'ChosePatient' : 'ChoseAthlete')}
              variant={BODY_2}
              weight={BOLD}
            />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1vh' }}>
              <LoopIcon
                className="icon"
                onClick={() => setButtonType('search')}
              />
              <PlusIcon
                className="icon"
                onClick={() => setButtonType('AddAthlete')}
              />
            </div>
          </div>
          <UploadAthleteList option={buttonType} setOption={setButtonType} />

          {buttonType === 'search' && (
            <div
              style={{
                alignSelf: 'center',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                marginTop: '2vh',
              }}
            >
              <ButtonComponent
                title={translate('RunAnalysis')}
                type={ORANGE}
                onClick={() => {
                  startUpload(selectedVideo, {
                    type: analysisType,
                    pace: pace,
                  });
                }}
                disabled={Object.keys(athleteSlice).length === 0}
              />
            </div>
          )}

          {checkUserPermission([RETAIL_STORE]) && (analysisType === ANALYSISTYPE_PRONATION || analysisType === ANALYSISTYPE_PRONATION_LOWER_BODY) && (
            <TextComponent
              text={translate('SkipAthleteSelection')}
              variant={BODY_2}
              weight={BOLD}
              onClick={() => {
                startUpload(selectedVideo, {
                  type: analysisType,
                });
              }}
              sx={{
                marginTop: '2vh',
                marginBottom: 2,
                underline: true,
                color: colors.WHITE,
                textDecoration: 'underline',
                cursor: 'pointer',
                alignSelf: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            />
          )}

        </div>
      )}
    </div>
  );
}

const styles = {
  conditionText: { display: 'flex', alignSelf: 'center' },
  container: {
    backgroundColor: colors.CARD_BACKGROUND,
    width: '25rem',
    borderRadius: 10,
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 25,
  },
  greenDotBackground: {
    display: 'flex',
    backgroundColor: 'rgba(120, 189, 50, 0.2)',
    marginRight: 10,
    height: 15,
    width: 15,
    borderRadius: 50,
    justifyContent: 'center',
  },
  greenDotCenter: {
    backgroundColor: '#78BD32',
    height: 7,
    width: 7,
    borderRadius: 50,
    alignSelf: 'center',
  },
  greenDotContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 15,
    alignItems: 'center',
  },
  validationConditionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  validationListContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
};
export default VideoChecker;
