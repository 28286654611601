import React from 'react';

import HeadSvg from '../../analysisSvg/HeadSvg';
import TrunkSvg from '../../analysisSvg/TrunkSvg';
import ArmSvg from '../../analysisSvg/ArmSvg';
import FrontLegSvg from '../../analysisSvg/FrontLeg';
import BackLegSvg from '../../analysisSvg/BackLeg';
import PelvicSvg from '../../analysisSvg/PelvicSvg';
import FootBVRightSvg from '../../analysisSvg/FootBVRightSvg';
import FootBVSvg from '../../analysisSvg/FootBVSvg';
import { SCORE_GOOD } from 'constant/constants';
import KneeAdductionLeftSvg from '../../analysisSvg/KneeAdductionLeftSvg';
import KneeAdductionRightSvg from '../../analysisSvg/KneeAdductionRightSvg';

export default function ImageIdealPositionAssets({
  analysisKey,
  angle,
  item,
  score,
  side,
}) {
  if (angle === undefined) return null;

  const getRotation = () => {
    if (angle >= -10 && angle <= 10) return 90;
    if (angle >= -15 && angle <= 15) return angle > 0 ? 95 : 85;
    else return angle > 0 ? 100 : 80;
  };

  const displayIcon = good => {
    if (item === 'head' || item === 'RightEar') {
      return (
        <div style={{}}>
          <HeadSvg rotation={0} angle={angle} score={score} />
        </div>
      );
    }

    if (item === 'trunk' || item === 'RightHip') {
      return (
        <div>
          <TrunkSvg rotation={90} angle={angle} score={score} />
        </div>
      );
    }

    if (item === 'arm' || item === 'RightElbow') {
      return (
        <div>
          <ArmSvg rotation={70} angle={angle} score={score} />
        </div>
      );
    }

    if (item === 'forwardCycle' || item === 'RightKnee') {
      return (
        <div>
          <FrontLegSvg rotation={80} angle={angle} score={score} />
        </div>
      );
    }

    if (item === 'backCycle' || item === 'LeftKnee') {
      return (
        <div>
          <BackLegSvg rotation={110} angle={angle} score={score} />
        </div>
      );
    }
    if (!analysisKey) return;

    if (item === 'pelvic') {
      return (
        <div>
          <PelvicSvg
            angle={analysisKey?.includes('PelvicR') ? -angle : angle}
            score={score}
          />
        </div>
      );
    }

    if (item === 'knee_adduction') {
      if (side === 'right') {
        return (
          <div>
            <KneeAdductionRightSvg
              angle={good ? 0 : angle}
              rotation={90}
              score={score}
            />
          </div>
        );
      } else
        return (
          <div>
            <KneeAdductionLeftSvg
              angle={good ? 0 : angle}
              rotation={90}
              score={score}
            />
          </div>
        );
    }

    if (analysisKey.includes('R')) {
      return (
        <div>
          <FootBVRightSvg
            angle={good ? 0 : -angle}
            rotation={good ? 90 : getRotation()}
            score={score}
          />
        </div>
      );
    }
    if (analysisKey.includes('L')) {

      return (
        <div>
          <FootBVSvg
            angle={good ? 0 : angle}
            rotation={good ? 90 : getRotation()}
            score={score}
          />
        </div>
      );
    }

    return null;
  };
  return displayIcon(score === SCORE_GOOD);
}
