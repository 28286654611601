import React from 'react';

import {useSelector} from 'react-redux';

import SubscriptionPageHeader from './SubscriptionPageHeader';
import SubscriptionInformationPanel from './SubscriptionInformationPanel';

import {selectUsage} from 'slices/usageSlice';

export default function SubscriptionInformationContainer({
  productData,
  payments,
}) {
  const usage = useSelector(selectUsage);
  return (
    <div style={styles.container}>
      <>
        <SubscriptionPageHeader usage={usage} />
        {usage?.yearlyPlan === true || usage.monthlyPlan === true ? (
          <SubscriptionInformationPanel
            product={productData}
            usage={usage}
            payments={payments}
          />
        ) : null}
      </>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: 20,
  },
};
