import {ReactComponent as RunningIcon} from 'assets/svg/analysisIcon.svg';
import {ReactComponent as SprintIcon} from 'assets/svg/sprint.svg';
import {ReactComponent as FootIcon} from 'assets/svg/foot.svg';
import {ReactComponent as RaceWalking} from 'assets/svg/racewalking.svg';
import {
  ALL_USER_TYPE,
  ANALYSISTYPE_PRONATION,
  ANALYSISTYPE_PRONATION_LOWER_BODY,
  ANALYSISTYPE_RUNNING,
  ANALYSISTYPE_RUNNING_TREADMILL,
  REGISTRATION_DATA,
  RETAIL_STORE,
  USERTYPE_PROFESSIONAL,
  USERTYPE_RETAIL_STORE,
} from './constants';
import {images} from 'themes';
import {store} from 'store';

export const uploadAnalysisTypeList = {
  [ANALYSISTYPE_RUNNING]: {
    desc: 'AnalysisTypeRunningDesc',
    exempleVideo:
      'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FOnboarding%2Fvideo%2Fperrine_sideview_overground_exemple.mp4?alt=media',
    key: ANALYSISTYPE_RUNNING,
    title: 'OverGround',
    subtitle: 'SideViewRunning',
    userType: ALL_USER_TYPE,
    analysisAsset: images?.SIDEVIEW_OVERGROUND,
  },
  [ANALYSISTYPE_RUNNING_TREADMILL]: {
    desc: 'AnalysisTypeRunningDesc',
    exempleVideo:
      store?.getState()?.user?.userType === USERTYPE_PROFESSIONAL ||
      JSON.parse(localStorage.getItem(REGISTRATION_DATA))?.userType ===
        USERTYPE_PROFESSIONAL
        ? 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FOnboarding%2Fvideo%2Fexemple_treadmill_pro.mp4?alt=media'
        : 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FvideoTuto%2Fupload%2FanalysisTypes%2FtreadmillPreview.mp4?alt=media&token=ec1e0222-8389-420e-9e59-be5049aa28a6',
    key: ANALYSISTYPE_RUNNING_TREADMILL,
    title: 'OnTreadmill',
    subtitle: 'SideViewRunning',
    userType: ALL_USER_TYPE,
    analysisAsset: images?.SIDEVIEW_TREADMILL,
  },
  [ANALYSISTYPE_PRONATION_LOWER_BODY]: {
    available: true,
    desc: 'AnalysisTypePronationLowerBodyDesc',
    exempleVideo:
      'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FvideoTuto%2Fupload%2FanalysisTypes%2Fpronation_preview.mp4?alt=media&token=a60aafcb-33ae-4e53-9950-2f5f3bf60f39',
    key: ANALYSISTYPE_PRONATION_LOWER_BODY,
    title: 'OnTreadmillLowerBody',
    subtitle: 'BackViewRunning',
    userType: [RETAIL_STORE],
    full_body: false,
    analysisAsset: images?.BACKVIEW_LOWER_BODY,
  },
  [ANALYSISTYPE_PRONATION]: {
    available: true,
    desc: 'AnalysisTypePronationDesc',
    exempleVideo:
      'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FvideoTuto%2Fback_view_tuto.mp4?alt=media&token=5fd15e87-31a6-4175-b915-fec72fd57527',
    key: ANALYSISTYPE_PRONATION,
    title: 'OnTreadmillFullBody',
    subtitle: 'BackViewRunning',
    userType: ALL_USER_TYPE,
    full_body: true,
    analysisAsset: images?.BACKVIEW_FULL_BODY,
  },
};

export const goodVideoTutoContent = {
  [ANALYSISTYPE_RUNNING]: [
    {
      title: 'UploadStep1',
      asset: images?.SIDEVIEW_OVERGROUND_STEP_1,
      importantText: {
        text: ['CamereNotMove', 'VisibleHeadToFoot'],
      },
      recapText: {
        text: ['PlaceCamera', 'DistanceFromRunner5strides'],
      },
    },
    {
      title: 'UploadStep2',
      asset: images?.SIDEVIEW_OVERGROUND_STEP_2,
      importantText: {
        text: ['CamereNotMove'],
      },
      recapText: {
        text: ['AtUsualRunningPace', 'LandscapeFormat', 'NoLoseClothing'],
      },
    },
    {
      title: 'UploadStep3',
      asset: images?.SIDEVIEW_OVERGROUND_STEP_3,
      importantText: {
        text: [],
      },
      recapText: {
        text: ['CropVideo', 'SeeYourResults'],
      },
    },
  ],
  [ANALYSISTYPE_RUNNING_TREADMILL]: [
    {
      title: 'UploadStep1',
      asset: images?.SIDEVIEW_TREADMILL_STEP_1,
      importantText: {
        text: ['CamereNotMove', 'VisibleHeadToFoot'],
      },
      recapText: {
        text: ['PlaceCamera', 'DistanceFromRunner2m', 'ShootFromSideTreadmill'],
      },
    },
    {
      title: 'UploadStep2',
      asset: images?.SIDEVIEW_TREADMILL_STEP_2,
      importantText: {
        text: ['CamereNotMove'],
      },
      recapText: {
        text: ['AtUsualRunningPace', 'PortraitFormat', 'NoLoseClothing'],
      },
    },
    {
      title: 'UploadStep3',
      asset: images?.SIDEVIEW_TREADMILL_STEP_3,
      importantText: {
        text: [],
      },
      recapText: {
        text: ['CropVideo', 'SeeYourResults'],
      },
    },
  ],
  [ANALYSISTYPE_PRONATION]: [
    {
      title: 'UploadStep1',
      asset: images?.BACKVIEW_FB_STEP_1,
      importantText: {
        text: ['CamereNotMove', 'VisibleHeadToFoot'],
      },
      recapText: {
        text: ['PlaceCamera', 'DistanceFromRunner2m', 'ShootFromBackTreadmill'],
      },
    },

    {
      title: 'UploadStep2',
      asset: images?.BACKVIEW_FB_STEP_2,
      importantText: {
        text: ['CamereNotMove'],
      },
      recapText: {
        text: [
          'AtUsualRunningPace',
          'WithUsualRunningShoes',
          'PortraitFormat',
          'AvoidJoggingTrousers',
          'PreferClothFitting',
        ],
      },
    },
    {
      title: 'UploadStep3',
      asset: images?.BACKVIEW_FB_STEP_3,
      importantText: {
        text: [],
      },
      recapText: {
        text: ['CropVideo', 'SeeYourResults'],
      },
    },
  ],
  [ANALYSISTYPE_PRONATION_LOWER_BODY]: [
    {
      title: 'UploadStep1',
      asset: images?.BACKVIEW_LB_STEP_1,
      importantText: {
        text: ['CamereNotMove'],
      },
      recapText: {
        text: ['PlaceCamera', 'ShootFromBackTreadmill'],
      },
    },
    {
      title: 'UploadStep2',
      asset: images?.BACKVIEW_LB_STEP_2,
      importantText: {
        text: ['CamereNotMove'],
      },
      recapText: {
        text: [
          'AtUsualRunningPace',
          'WithUsualRunningShoes',
          'PortraitFormat',
          'AvoidJoggingTrousers',
          'PreferClothFitting',
        ],
      },
    },
    {
      title: 'UploadStep3',
      asset: images?.BACKVIEW_LB_STEP_3,
      importantText: {
        text: [],
      },
      recapText: {
        text: ['CropVideo', 'SeeYourResults'],
      },
    },
  ],
};

export default function analysisTypeIcons({name}) {
  switch (name) {
    case 'running':
      return (
        <RunningIcon width={30} height={30} fill={'white'} strokeWidth={2} />
      );
    case 'sprinting':
      return (
        <SprintIcon width={30} height={30} fill={'white'} strokeWidth={2} />
      );
    case 'User':
      return (
        <RaceWalking width={30} height={30} fill={'white'} strokeWidth={2} />
      );
    case 'PromoCode':
      return <FootIcon width={30} height={30} fill={'white'} strokeWidth={2} />;
    default:
      return <></>;
  }
}

export const VIDEO_VALIDATION_CONDITIONS = {
  running: [
    'CamereNotMove',
    'VisibleHeadToFoot',
    'LandscapeFormat',
    'DistanceFromRunner5strides',
    'NoLoseClothing',
  ],
  running_treadmill: [
    'CamereNotMove',
    'VisibleHeadToFoot',
    'PortraitFormat',
    'DistanceFromRunner2m',
    'ShootFromSideTreadmill',
    'NoLoseClothing',
  ],
  pronation: [
    'CamereNotMove',
    'VisibleHeadToFoot',
    'PortraitFormat',
    'DistanceFromRunner2m',
    'ShootFromBackTreadmill',
    'WithUsualRunningShoes',
    'AvoidJoggingTrousers',
    'PreferClothFitting',
  ],
  pronation_lower_body: [
    'CamereNotMove',
    'PortraitFormat',
    'ShootFromBackTreadmill',
    'WithUsualRunningShoes',
    'AvoidJoggingTrousers',
    'PreferClothFitting',
  ],
};

export const RUNNING_PACE = {
  mpm: [
    '15:00',
    '14:55',
    '14:50',
    '14:45',
    '14:40',
    '14:35',
    '14:30',
    '14:25',
    '14:20',
    '14:15',
    '14:10',
    '14:05',
    '14:00',
    '13:55',
    '13:50',
    '13:45',
    '13:40',
    '13:35',
    '13:30',
    '13:25',
    '13:20',
    '13:15',
    '13:10',
    '13:05',
    '13:00',
    '12:55',
    '12:50',
    '12:45',
    '12:40',
    '12:35',
    '12:30',
    '12:25',
    '12:20',
    '12:15',
    '12:10',
    '12:05',
    '12:00',
    '11:55',
    '11:50',
    '11:45',
    '11:40',
    '11:35',
    '11:30',
    '11:25',
    '11:20',
    '11:15',
    '11:10',
    '11:05',
    '11:00',
    '10:55',
    '10:50',
    '10:45',
    '10:40',
    '10:35',
    '10:30',
    '10:25',
    '10:20',
    '10:15',
    '10:10',
    '10:05',
    '10:00',
    '09:55',
    '09:50',
    '09:45',
    '09:40',
    '09:35',
    '09:30',
    '09:25',
    '09:20',
    '09:15',
    '09:10',
    '09:05',
    '09:00',
    '08:55',
    '08:50',
    '08:45',
    '08:40',
    '08:35',
    '08:30',
    '08:25',
    '08:20',
    '08:15',
    '08:10',
    '08:05',
    '08:00',
    '07:55',
    '07:50',
    '07:45',
    '07:40',
    '07:35',
    '07:30',
    '07:25',
    '07:20',
    '07:15',
    '07:10',
    '07:05',
    '07:00',
    '06:55',
    '06:50',
    '06:45',
    '06:40',
    '06:35',
    '06:30',
    '06:25',
    '06:20',
    '06:15',
    '06:10',
    '06:05',
    '06:00',
    '05:55',
    '05:50',
    '05:45',
    '05:40',
    '05:35',
    '05:30',
    '05:25',
    '05:20',
    '05:15',
    '05:10',
    '05:05',
    '05:00',
    '04:55',
    '04:50',
    '04:45',
    '04:40',
    '04:35',
    '04:30',
  ],
  mpkm: [
    '10:00',
    '09:55',
    '09:50',
    '09:45',
    '09:40',
    '09:35',
    '09:30',
    '09:25',
    '09:20',
    '09:15',
    '09:10',
    '09:05',
    '09:00',
    '08:55',
    '08:50',
    '08:45',
    '08:40',
    '08:35',
    '08:30',
    '08:25',
    '08:20',
    '08:15',
    '08:10',
    '08:05',
    '08:00',
    '07:55',
    '07:50',
    '07:45',
    '07:40',
    '07:35',
    '07:30',
    '07:25',
    '07:20',
    '07:15',
    '07:10',
    '07:05',
    '07:00',
    '06:55',
    '06:50',
    '06:45',
    '06:40',
    '06:35',
    '06:30',
    '06:25',
    '06:20',
    '06:15',
    '06:10',
    '06:05',
    '06:00',
    '05:55',
    '05:50',
    '05:45',
    '05:40',
    '05:35',
    '05:30',
    '05:25',
    '05:20',
    '05:15',
    '05:10',
    '05:05',
    '05:00',
    '04:55',
    '04:50',
    '04:45',
    '04:40',
    '04:35',
    '04:30',
    '04:25',
    '04:20',
    '04:15',
    '04:10',
    '04:05',
    '04:00',
    '03:55',
    '03:50',
    '03:45',
    '03:40',
    '03:35',
    '03:30',
    '03:25',
    '03:20',
    '03:15',
    '03:10',
    '03:05',
    '03:00',
    '02:55',
    '02:50',
    '02:45',
    '02:40',
  ],
  kmh: [
    '06.00',
    '06.10',
    '06.20',
    '06.30',
    '06.40',
    '06.50',
    '06.60',
    '06.70',
    '06.80',
    '06.90',
    '07.00',
    '07.10',
    '07.20',
    '07.30',
    '07.40',
    '07.50',
    '07.60',
    '07.70',
    '07.80',
    '07.90',
    '08.00',
    '08.10',
    '08.20',
    '08.30',
    '08.40',
    '08.50',
    '08.60',
    '08.70',
    '08.80',
    '08.90',
    '09.00',
    '09.10',
    '09.20',
    '09.30',
    '09.40',
    '09.50',
    '09.60',
    '09.70',
    '09.80',
    '09.90',
    '10.00',
    '10.10',
    '10.20',
    '10.30',
    '10.40',
    '10.50',
    '10.60',
    '10.70',
    '10.80',
    '10.90',
    '11.00',
    '11.10',
    '11.20',
    '11.30',
    '11.40',
    '11.50',
    '11.60',
    '11.70',
    '11.80',
    '11.90',
    '12.00',
    '12.10',
    '12.20',
    '12.30',
    '12.40',
    '12.50',
    '12.60',
    '12.70',
    '12.80',
    '12.90',
    '13.00',
    '13.10',
    '13.20',
    '13.30',
    '13.40',
    '13.50',
    '13.60',
    '13.70',
    '13.80',
    '13.90',
    '14.00',
    '14.10',
    '14.20',
    '14.30',
    '14.40',
    '14.50',
    '14.60',
    '14.70',
    '14.80',
    '14.90',
    '15.00',
    '15.10',
    '15.20',
    '15.30',
    '15.40',
    '15.50',
    '15.60',
    '15.70',
    '15.80',
    '15.90',
    '16.00',
    '16.10',
    '16.20',
    '16.30',
    '16.40',
    '16.50',
    '16.60',
    '16.70',
    '16.80',
    '16.90',
    '17.00',
    '17.10',
    '17.20',
    '17.30',
    '17.40',
    '17.50',
    '17.60',
    '17.70',
    '17.80',
    '17.90',
    '18.00',
    '18.10',
    '18.20',
    '18.30',
    '18.40',
    '18.50',
    '18.60',
    '18.70',
    '18.80',
    '18.90',
    '19.00',
    '19.10',
    '19.20',
    '19.30',
    '19.40',
    '19.50',
    '19.60',
    '19.70',
    '19.80',
    '19.90',
    '20.00',
    '20.10',
    '20.20',
    '20.30',
    '20.40',
    '20.50',
    '20.60',
    '20.70',
    '20.80',
    '20.90',
    '21.00',
    '21.10',
    '21.20',
    '21.30',
    '21.40',
    '21.50',
    '21.60',
    '21.70',
    '21.80',
    '21.90',
    '22.00',
  ],
  mph: [
    '03.00',
    '03.10',
    '03.20',
    '03.30',
    '03.40',
    '03.50',
    '03.60',
    '03.70',
    '03.80',
    '03.90',
    '04.00',
    '04.10',
    '04.20',
    '04.30',
    '04.40',
    '04.50',
    '04.60',
    '04.70',
    '04.80',
    '04.90',
    '05.00',
    '05.10',
    '05.20',
    '05.30',
    '05.40',
    '05.50',
    '05.60',
    '05.70',
    '05.80',
    '05.90',
    '06.00',
    '06.10',
    '06.20',
    '06.30',
    '06.40',
    '06.50',
    '06.60',
    '06.70',
    '06.80',
    '06.90',
    '07.00',
    '07.10',
    '07.20',
    '07.30',
    '07.40',
    '07.50',
    '07.60',
    '07.70',
    '07.80',
    '07.90',
    '08.00',
    '08.10',
    '08.20',
    '08.30',
    '08.40',
    '08.50',
    '08.60',
    '08.70',
    '08.80',
    '08.90',
    '09.00',
    '09.10',
    '09.20',
    '09.30',
    '09.40',
    '09.50',
    '09.60',
    '09.70',
    '09.80',
    '09.90',
    '10.00',
    '10.10',
    '10.20',
    '10.30',
    '10.40',
    '10.50',
    '10.60',
    '10.70',
    '10.80',
    '10.90',
    '11.00',
    '11.10',
    '11.20',
    '11.30',
    '11.40',
    '11.50',
    '11.60',
    '11.70',
    '11.80',
    '11.90',
    '12.00',
    '12.10',
    '12.20',
    '12.30',
    '12.40',
    '12.50',
    '12.60',
    '12.70',
    '12.80',
    '12.90',
    '13.00',
    '13.10',
    '13.20',
    '13.30',
    '13.40',
    '13.50',
    '13.60',
    '13.70',
    '13.80',
    '13.90',
    '14.00',
    '14.10',
    '14.20',
    '14.30',
    '14.40',
    '14.50',
    '14.60',
    '14.70',
    '14.80',
    '14.90',
    '15.00',
  ],
};
