import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import TextComponent from 'components/atoms/TextComponent';

import { selectUser } from 'slices/userSlice';
import { translate } from 'services/remoteConfig';

import { colors } from 'themes';

const CheckCircleIcon = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" 
      stroke={fill} 
      strokeWidth="2" 
      fill="none"
    />
    <path 
      d="M8 12L11 15L16 9" 
      stroke={fill} 
      strokeWidth="2" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
  </svg>
);

const OneTimePaymentSuccess = () => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  useEffect(() => {
    // Redirect to analysis list after 5 seconds
    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <div style={styles.iconContainer}>
          <CheckCircleIcon width={80} height={80} fill={colors.VALID_IMAGE} />
        </div>
        
        <TextComponent 
          variant="h4" 
          text={translate('PaymentSuccessful')}
          sx={styles.title} 
        />
        
        <TextComponent 
          variant="body1" 
          text={`${translate('ThankYou')}, ${user?.fullName || 'User'}! ${translate('YourPaymentHasBeenSuccessfullyProcessed')}`} 
          sx={styles.message} 
        />
        
        <TextComponent 
          variant="body1" 
          text={translate('YouNowHaveAccessToYourAnalysis')}
          sx={styles.subMessage} 
        />
        
        <div style={styles.detailsContainer}>
          <TextComponent 
            variant="body2" 
            text={translate('YouWillBeRedirectedToTheDashboardInAFewSeconds')}
            sx={styles.redirect} 
          />
        </div>
        
        <button 
          style={styles.button} 
          onClick={() => navigate('/')}
        >
          {translate('GoToDashboard')}
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 'calc(100vh - 100px)',
    padding: '20px',
    backgroundColor: colors.BACKGROUND,
  },
  card: {
    backgroundColor: colors.BLACK,
    borderRadius: '15px',
    padding: '40px',
    maxWidth: '600px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
  },
  iconContainer: {
    marginBottom: '24px',
  },
  title: {
    color: colors.WHITE,
    marginBottom: '24px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  message: {
    color: colors.WHITE,
    marginBottom: '16px',
    textAlign: 'center',
    fontSize: '18px',
  },
  subMessage: {
    color: colors.WHITE,
    marginBottom: '32px',
    textAlign: 'center',
    fontSize: '18px',
  },
  detailsContainer: {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    borderRadius: '8px',
    padding: '20px',
    marginBottom: '32px',
    width: '100%',
  },
  details: {
    color: colors.WHITE_OPACITY_80,
    marginBottom: '16px',
    textAlign: 'center',
  },
  redirect: {
    color: colors.WHITE_OPACITY_60,
    textAlign: 'center',
  },
  button: {
    backgroundColor: colors.SECONDARY,
    color: colors.WHITE,
    border: 'none',
    borderRadius: '5px',
    padding: '12px 24px',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
};

export default OneTimePaymentSuccess; 