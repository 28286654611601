import {ALL_USER_TYPE} from './constants';

export function settingsConfig() {
  const config = [
    {
      Category: 'Options',
      Button: [
        {
          name: 'Language',
          icon: 'Language',
          picker: true,
        },
        {
          name: 'HeightUnit',
          icon: 'Height',
          switch: true,
        },
        {
          name: 'WeightUnit',
          icon: 'Weight',
          switch: true,
        },
      ],
      permission: ALL_USER_TYPE,
    },
    {
      Category: 'AboutUs',
      Button: [
        {
          name: 'TermsConditions',
          icon: 'T&C',
        },
        {
          name: 'PrivacyTitle',
          icon: 'Pp',
        },
      ],
      permission: ALL_USER_TYPE,
    },
    {
      Category: '',
      Button: [
        {
          name: 'DeleteAccount',
          icon: 'LogOut',
        },
      ],
      permission: ALL_USER_TYPE,
    },
  ];
  return config;
}
