import React, {useContext, useState} from 'react';

import {Box, Container, Grid} from '@mui/material';

import OnBoardingButton from './OnBoardingButton';
import ButtonComponent from 'components/atoms/ButtonComponent';
import TextInput from 'components/atoms/TextInput';

import {mpTrack} from 'services/mixpanel';
import {setUserAttributeOnAllPlatforms} from 'services/tracking';
import {
  onBoardingTranslate,
  translate,
  USER_LOCALE,
} from 'services/remoteConfig';

import {RegistrationContext} from 'contexts/RegistrationContext';

import {ORANGE, PROFESSIONAL, REGISTRATION_DATA} from 'constant/constants';
import {httpsCallable} from 'firebase/functions';
import {functions} from 'utils/firebaseInit';
import {useSelector} from 'react-redux';

function OnBoardingButtonList({
  data,
  mutlipleSelection = false,
  field = null,
  onPress = null,
}) {
  const [selected, setSelected] = useState([]);
  const [other, setOther] = useState('');
  const [otherSelected, setOtherSelected] = useState(false);
  const {goToNextStep, updateUserType} = useContext(RegistrationContext);

  const userSlice = useSelector(state => state.user);

  async function handlePressed(item) {
    if (item?.text === 'Other') {
      if (otherSelected) {
        setOtherSelected(false);
        setSelected([]);
        return;
      }
      setSelected(prev => {
        if (!prev) return;
        const newArr = [...prev];
        if (newArr.includes(item?.text)) {
          const index = newArr.findIndex(obj => obj === item?.text);
          newArr.splice(index, 1);
        } else if (!newArr.includes(item?.text)) {
          newArr.push(item?.text);
        }
        return newArr;
      });

      setOtherSelected(true);
      return;
    }
    if (mutlipleSelection) {
      setSelected(prev => {
        if (!prev) return;
        const newArr = [...prev];
        if (newArr.includes(item?.text)) {
          const index = newArr.findIndex(obj => obj === item?.text);
          newArr.splice(index, 1);
        } else if (!newArr.includes(item?.text)) {
          newArr.push(item?.text);
        }
        return newArr;
      });
    } else {
      mpTrack(item?.tracking);
      if (item?.attributes) {
        setUserAttributeOnAllPlatforms(
          item?.attributes?.key,
          item?.attributes?.value,
        );
      }

      const onBoardingUserData = JSON.parse(
        localStorage.getItem(REGISTRATION_DATA),
      );

      if (onBoardingUserData) {
        if (field)
          localStorage.setItem(
            REGISTRATION_DATA,
            JSON.stringify({
              ...onBoardingUserData,
              ...item?.userData,
              [field]: item?.attributes?.value || item?.text,
            }),
          );
        else {
          localStorage.setItem(
            REGISTRATION_DATA,
            JSON.stringify({
              ...onBoardingUserData,
              ...item?.userData,
            }),
          );
        }
        goToNextStep();
      } else {
        localStorage.setItem(REGISTRATION_DATA, JSON.stringify(item?.userData));
        await updateUserType(item?.userData?.userType);
        const callInitExempleAnalysis = httpsCallable(
          functions,
          'initExempleAnalysis',
        );
        try {
          await callInitExempleAnalysis({
            userType: item?.userData?.userType,
            language: USER_LOCALE,
            userUid: userSlice.userID,
          });
        } catch (error) {
          console.error('Error calling initExempleAnalysis:', error);
        }
      }
    }
  }

  function handleSubmitMultiple() {
    const onBoardingUserData = JSON.parse(
      localStorage.getItem(REGISTRATION_DATA),
    );
    const specializations = selected.filter(item => item !== 'Other');
    if (selected.includes('Other')) {
      specializations.push(other);
    }
    if (onBoardingUserData) {
      localStorage.setItem(
        REGISTRATION_DATA,
        JSON.stringify({
          ...onBoardingUserData,
          ...{specialization: specializations},
        }),
      );
    }
    if (onBoardingUserData?.userType === PROFESSIONAL)
      mpTrack('Web App Onboarding Professional Specialization Validated');
    else {
      mpTrack('Web App Onboarding Coach Specialization Validated');
      setUserAttributeOnAllPlatforms(
        'coach_specialisation',
        specializations.join(', '),
      );
    }
    goToNextStep();
  }

  return (
    <>
      <Container>
        <Box sx={styles.container}>
          <Grid container spacing={3} justifyContent="center">
            {data?.map((button, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <OnBoardingButton
                  selected={selected.includes(button?.text)}
                  key={index}
                  disabled={button?.disabled}
                  onPress={() => {
                    onPress ? onPress(button) : handlePressed(button);
                  }}
                  text={
                    button.text === '1'
                      ? '1'
                      : mutlipleSelection
                      ? translate(button?.text)
                      : onBoardingTranslate(button?.text) ||
                        translate(button?.text)
                  }
                  subText={onBoardingTranslate(button?.subText)}
                  icon={button?.icon}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      {selected?.includes('Other') && (
        <div style={styles.textInputContainer}>
          <TextInput
            label={translate('Other')}
            name={translate('Other')}
            value={other}
            onChange={item => {
              setOther(item?.target?.value);
            }}
          />
        </div>
      )}
      {(mutlipleSelection || otherSelected) && (
        <div style={styles.validateContainer}>
          <ButtonComponent
            type={ORANGE}
            title={translate('Validate')}
            onClick={handleSubmitMultiple}
            disabled={selected.length === 0}
          />
        </div>
      )}
    </>
  );
}
const styles = {
  container: {
    flexGrow: 1,
    padding: 2,
    width: '100%',
  },
  textInputContainer: {
    margin: 'auto',
    marginTop: 20,
    width: '30%',
  },
  validateContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '5vh',
    width: '100%',
  },
};
export default OnBoardingButtonList;
