import React from 'react';
import CircleSvg from './CircleSvg';
import {score_colors} from 'screen/analysis/AnalysisListCard/constants/analysisListConstants';

export default function FrontLegSvg({angle, score, rotation = 0}) {
  if (!score || angle === undefined) return null;

  // Generate a unique identifier for this instance
  const instanceId = `front_leg_${Math.random().toString(36).substr(2, 9)}`;

  const Bx = 130;
  const By = 30;
  const L = 100;

  // Convert both angles to radians
  const angleRad = ((180 - angle) * Math.PI) / 180;
  const rotationRad = (rotation * Math.PI) / 180;

  // Segment BA (horizontal with rotation)
  const Ax = Bx + L * Math.cos(rotationRad);
  const Ay = By + L * Math.sin(rotationRad);

  // Segment AC (angle + rotation) - from A point
  const Cx = Ax + L * Math.cos(angleRad + rotationRad);
  const Cy = Ay + L * Math.sin(angleRad + rotationRad);

  const arcRadius = 60; // Radius of the arc

  // Calculate arc center at the joint point
  const arcCenterX = Ax;
  const arcCenterY = Ay + 5; // Small offset as in the original lines

  // For the inner angle, we need to determine the direction vectors of both segments
  // First segment: from A to B
  const firstSegDirX = Bx - Ax;
  const firstSegDirY = By - (Ay + 10); // Include vertical offset

  // Second segment: from A to C
  const secondSegDirX = Cx - Ax;
  const secondSegDirY = Cy + 15 - (Ay + 5); // Include vertical offsets
  const innerAngleDeg =
    Math.abs(angle) <= 180 ? Math.abs(angle) : 360 - Math.abs(angle);

  // Calculate start and end angles for the arc
  // We need the angle of each segment relative to the horizontal
  const firstSegAngle = Math.atan2(firstSegDirY, firstSegDirX);
  const secondSegAngle = Math.atan2(secondSegDirY, secondSegDirX);

  // Calculate points where the arc should start and end
  const arcStartX = arcCenterX + arcRadius * Math.cos(firstSegAngle);
  const arcStartY = arcCenterY + arcRadius * Math.sin(firstSegAngle);
  const arcEndX = arcCenterX + arcRadius * Math.cos(secondSegAngle);
  const arcEndY = arcCenterY + arcRadius * Math.sin(secondSegAngle);

  // Determine the sweep and large arc flags based on the inner angle
  const isClockwise =
    (secondSegAngle - firstSegAngle + 2 * Math.PI) % (2 * Math.PI) < Math.PI;
  const sweepFlag = isClockwise ? 1 : 0;
  const largeArcFlag = innerAngleDeg > 180 ? 1 : 0;

  // Create sector path (filled arc from center point)
  const sectorPath = `M ${arcCenterX},${arcCenterY} L ${arcStartX},${arcStartY} A ${arcRadius} ${arcRadius} 0 ${largeArcFlag} ${sweepFlag} ${arcEndX},${arcEndY} Z`;

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 200 300"
      xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient
          id={`paint0_linear_1248_69690_${instanceId}`}
          x1="12.7658"
          y1="31.6564"
          x2="130.579"
          y2="38.7842"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={score_colors[score]?.svgLinear} stopOpacity="0" />
          <stop offset="0.329184" stopColor={score_colors[score]?.svgLinear} />
          <stop offset="0.908897" stopColor={score_colors[score]?.svgLinear} />
          <stop
            offset="1"
            stopColor={score_colors[score]?.svgLinear}
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_1248_69690_${instanceId}`}
          x1="137.457"
          y1="26.4039"
          x2="13.651"
          y2="26.864"
          gradientUnits="userSpaceOnUse">
          <stop
            offset="0.13927"
            stopColor={score_colors[score]?.stroke}
            stopOpacity="0"
          />
          <stop offset="0.404022" stopColor={score_colors[score]?.stroke} />
          <stop offset="0.533825" stopColor={score_colors[score]?.stroke} />
          <stop
            offset="0.957011"
            stopColor={score_colors[score]?.stroke}
            stopOpacity="0"
          />
        </linearGradient>

        <linearGradient
          id={`paint0_linear_1248_69728_${instanceId}`}
          x1="29.003"
          y1="83.9887"
          x2="154.229"
          y2="35.4606"
          gradientUnits="userSpaceOnUse">
          <stop
            offset="0.00397462"
            stopColor={score_colors[score]?.svgLinear}
            stopOpacity="0"
          />
          <stop offset="0.125402" stopColor={score_colors[score]?.svgLinear} />
          <stop offset="1" stopColor={score_colors[score]?.svgLinear} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_1248_69728_${instanceId}`}
          x1="46.0988"
          y1="66.5328"
          x2="36.4457"
          y2="74.0741"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={score_colors[score]?.stroke} />
          <stop
            offset="1"
            stopColor={score_colors[score]?.stroke}
            stopOpacity="0"
          />
        </linearGradient>
      </defs>

      {/* Decorative shape that only follows rotation */}
      <g
        transform={`translate(${Bx}, ${By}) rotate(${rotation}) translate(-30, -33) scale(1.2)`}>
        <path
          d="M74.0331 7.00676C62.0074 4.73755 37.2569 1.2774 30.1508 1.11919C-2.5949 -2.52222 -10.6772 49.4558 20.4479 56.5253C22.8462 57.0054 29.5803 57.627 37.3297 56.2728C47.0166 54.5801 60.226 55.5229 87.3274 49.8921C94.6734 48.3658 110.595 47.4612 114.141 48.3779C126.764 54.039 129.346 41.3225 129.346 41.3225C132.608 30.8167 127.613 24.2619 126.322 21.8586C124.278 18.0503 117.216 13.9726 114.852 13.3614C112.487 12.7502 107.453 12.7079 102.419 12.6657C97.3851 12.6234 86.0587 9.27596 74.0331 7.00676Z"
          fill={`url(#paint0_linear_1248_69690_${instanceId})`}
          stroke={`url(#paint1_linear_1248_69690_${instanceId})`}
        />
      </g>

      <g
        transform={`translate(${Ax}, ${Ay}) rotate(${
          rotation + (180 - angle)
        }) translate(-30, -65) scale(1.1)`}>
        <path
          d="M36.5266 42.6259C33.8889 41.9246 25.8912 41.4328 23.5128 41.9873C4.17448 46.4956 0.883423 62.8806 5.28852 68.8634C9.12534 75.104 16.4119 80.2307 37.5701 73.9388C41.158 73.1999 48.3558 75.866 58.6609 77.4773C68.966 79.0886 78.9325 77.0361 84.8305 75.4066C90.7284 73.7771 105.877 70.6572 111.541 69.9058C117.204 69.1543 139.07 68.3847 139.633 69.0985C163.882 71.9407 157.513 54.4304 157.513 54.4304C155.415 40.2247 134 43.7297 127.21 48.3571C123.119 51.1449 103.928 49.0723 89.1553 47.9661C74.383 46.86 57.3826 47.0424 52.7516 46.7516C48.1206 46.4608 39.1644 43.3272 36.5266 42.6259Z"
          fill={`url(#paint0_linear_1248_69728_${instanceId})`}
          stroke={`url(#paint1_linear_1248_69728_${instanceId})`}
        />
        <path
          d="M157.033 14.529C155.697 18.965 150.991 27.7841 147.986 33.8024C147.289 35.3119 145.652 38.5554 144.677 39.4531C143.459 40.5752 141.767 40.4588 140.049 40.798C138.364 41.1306 136.474 40.7978 136.631 42.737C136.94 43.9794 139.8 42.8195 141.867 44.7185C143.934 46.6176 144.719 49.6819 145.238 50.5883C145.757 51.4946 147.572 51.581 148.22 51.2357C148.551 51.1062 149.318 50.7954 149.732 50.5883C150.147 50.3811 153.065 53.7194 154.448 56.4816C155.831 59.2439 155.531 61.2161 154.926 62.5109C153.801 64.9195 150.897 67.0974 147.156 67.2269C144.162 67.3305 142.872 66.5414 142.733 66.809C142.374 67.4955 143.01 68.6256 143.802 69.459C144.594 70.2924 145.793 71.1364 148.629 71.6165C151.465 72.0966 157.544 73.0673 160.369 73.9535C163.195 74.8397 163.559 76.4749 168.054 75.7787C172.549 75.0826 175.126 69.866 175.638 65.8151C176.151 61.7642 175.966 53.2245 175.718 47.1217C175.47 41.0189 178.122 32.9592 178.782 23.2222C179.443 13.4852 173.137 5.59941 169.957 2.67189C166.778 -0.255644 163.076 1.27391 161.417 2.45012C159.759 3.62632 158.37 10.093 157.033 14.529Z"
          fill="#0D0F0B"
          stroke="#AAAAAA"
          strokeLinejoin="round"
        />
        <path
          d="M162.469 74.7921C163.951 73.0838 166.176 73.9947 166.346 68.3003C166.517 62.6058 166.118 52.1848 167.372 44.7819C168.626 37.379 172.618 24.0539 171.078 16.5371C169.539 9.02027 166.916 3.78129 166.003 3.15489C165.091 2.52849 162.126 3.95202 161.1 2.69922"
          stroke="white"
          strokeOpacity="0.36"
          strokeWidth="0.8"
          strokeDasharray="3 3"
        />
        <path
          d="M150.67 51.2407C149.389 49.7943 147.639 50.3997 148.581 46.9015C149.524 43.4034 155.114 30.5879 155.821 29.2761C156.529 27.9642 157.741 23.3897 156.562 21.6742C155.619 20.3019 154.62 20.8557 154.238 21.3042"
          stroke="white"
          strokeOpacity="0.36"
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeDasharray="3 3"
        />
      </g>
      <path
        d={sectorPath}
        fill={score_colors[score]?.stroke}
        fillOpacity="0.4"
        stroke={score_colors[score]?.stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d={`M ${Bx},${By} L ${Ax},${Ay + 10}`}
        stroke={score_colors[score]?.stroke}
        strokeWidth="2"
      />
      <path
        d={`M ${Ax},${Ay + 5} L ${Cx},${Cy + 15}`}
        stroke={score_colors[score]?.stroke}
        strokeWidth="2"
      />
      <CircleSvg x={Ax} y={Ay + 5} score={score} />
      <CircleSvg x={Bx} y={By} score={score} />
      <CircleSvg x={Cx} y={Cy + 15} score={score} />
    </svg>
  );
}
