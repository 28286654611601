import TextComponent from 'components/atoms/TextComponent';
import { H4, ORANGE } from 'constant/constants';
import { useItemChanges } from 'hooks/useItemsChanges';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMultipleUserInfo } from 'services/userApi';
import {
  selectListItems,
  setLstItemPending,
  setLstItemsData,
} from 'slices/userItemsSlice';
import { translate } from 'services/remoteConfig';
import AthleteWrapper from './components/AthleteWrapper';
import ButtonComponent from 'components/atoms/ButtonComponent';
import { useNavigate } from 'react-router-dom';
import NoAthlete from 'components/template/NoAthlete';
import { isPro } from 'utils/utils';
import AthleteSearchBar from 'components/atoms/AthleteSearchBar';

function AthletesList() {
  useItemChanges();

  const itemList = useSelector(selectListItems);
  const [pendingListLength, setPendingListLength] = useState();

  const listIdNoPending = [];
  const listCustomItem = [];
  const pendingList = [];
  const [navigation, setNavigation] = useState(
    isPro() ? 'PatientList' : 'AthletesList',
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getUserData = async () => {
    setPendingListLength(pendingList?.length);
    const userData = await getMultipleUserInfo(listIdNoPending);
    if (listCustomItem?.length > 0) userData?.push(...listCustomItem);
    dispatch(setLstItemPending(pendingList));
    dispatch(setLstItemsData(userData));
  };

  useEffect(() => {
    for (const items in itemList) {
      if (!itemList[items]?.pending) {
        if (itemList[items]?.type === 'patient') {
          listCustomItem?.push(itemList[items]);
        } else {
          listIdNoPending?.push(itemList[items]?.id);
        }
      } else {
        pendingList?.push(itemList[items]);
      }
    }
    getUserData();
  }, [itemList]);

  if (itemList?.length === 0 && pendingList?.length === 0) {
    return (
      <div style={{ height: '80vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <NoAthlete />
      </div>
    )
  }

  else return (
    <>
      <div style={{ borderRadius: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '4vh' }}>
          <TextComponent
            variant={H4}
            text={isPro() ? translate('Patients') : translate('Athletes')}
          />
          <div style={{ marginLeft: '1.5rem', }}>
            <ButtonComponent
              title={
                isPro() ? translate('AddPatients') : translate('AddAthlete')
              }
              type={ORANGE}
              onClick={() => {
                navigate('/add-athlete');
              }}
            />
          </div>
        </div>
        <div style={{ width: '30%', display: 'flex', alignSelf: 'center', marginTop: '2vh' }}>
          <AthleteSearchBar />
        </div>

      </div>
      <AthleteWrapper navigation={navigation} />
    </>
  );
}

export default AthletesList;
