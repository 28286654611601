import React from 'react';

import { ReactComponent as SupinationIcon } from 'assets/svg/supination.svg';
import { ReactComponent as PronationIcon } from 'assets/svg/pronation.svg';
import { ReactComponent as NeutralIcon } from 'assets/svg/neutral.svg';
import { SCORE_GOOD, SCORE_OKAY } from 'constant/constants';
import { score_colors } from 'screen/analysis/AnalysisListCard/constants/analysisListConstants';

function UnderFootIcon({ score_text, type, direction, size = 30 }) {
    const numericSize = parseFloat(size);

    const adjustedSize = typeof size === 'string' ? `${numericSize / 2.5}vw` : size / 2.5;
    function getScoreIcon() {
        if (!type) return null;
        const itemCopy = type?.toLowerCase()?.split('/')[1];

        if (itemCopy.includes('supination') && score_text) {
            return (
                <SupinationIcon
                    width={adjustedSize || size}
                    height={adjustedSize || size}
                    style={{
                        ...styles.aCenter,
                        ...(direction ? styles.leftFoot : styles.rightFoot),
                    }}
                    fill={
                        score_text === SCORE_GOOD
                            ? score_colors.score_good.stroke
                            : score_text === SCORE_OKAY
                                ? score_colors.score_okay.stroke
                                : score_colors.score_bad.stroke
                    }
                    strokeWidth={8}
                    stroke={
                        score_text === SCORE_GOOD
                            ? score_colors.score_good.stroke
                            : score_text === SCORE_OKAY
                                ? score_colors.score_okay.stroke
                                : score_colors.score_bad.stroke
                    }
                />
            );
        }
        if (itemCopy.includes('pronation') && score_text) {
            return (
                <PronationIcon
                    width={adjustedSize || size}
                    height={adjustedSize || size}
                    style={{
                        ...styles.aCenter,
                        ...(direction ? styles.leftFoot : styles.rightFoot),
                    }}
                    fill={
                        score_text === SCORE_GOOD
                            ? score_colors.score_good.stroke
                            : score_text === SCORE_OKAY
                                ? score_colors.score_okay.stroke
                                : score_colors.score_bad.stroke
                    }
                    stroke={
                        score_text === SCORE_GOOD
                            ? score_colors.score_good.stroke
                            : score_text === SCORE_OKAY
                                ? score_colors.score_okay.stroke
                                : score_colors.score_bad.stroke
                    }
                    strokeWidth={8}
                />
            );
        }
        if (itemCopy.includes('neutral') && score_text) {
            return (
                <NeutralIcon
                    width={adjustedSize || size}
                    height={adjustedSize || size}
                    style={{
                        ...styles.aCenter,
                        ...(direction ? styles.leftFoot : styles.rightFoot),
                    }}
                    fill={
                        score_text === SCORE_GOOD
                            ? score_colors.score_good.stroke
                            : score_text === SCORE_OKAY
                                ? score_colors.score_okay.stroke
                                : score_colors.score_bad.stroke
                    }
                    stroke={
                        score_text === SCORE_GOOD
                            ? score_colors.score_good.stroke
                            : score_text === SCORE_OKAY
                                ? score_colors.score_okay.stroke
                                : score_colors.score_bad.stroke
                    }
                    strokeWidth={8}
                />
            );
        }
    }

    return (
        <div style={styles.container}>
            {getScoreIcon()}
        </div>
    );
}

const styles = {
    aCenter: {
        alignSelf: 'center',
    },
    container: {
        alignSelf: 'center',
        display: 'flex',
        position: 'absolute',
    },
    leftFoot: {
        justifyContent: 'center',
        transform: 'scaleX(-1)', // Utilisation de la syntaxe de chaîne de caractères pour React.js
    },
    rightFoot: {
        justifyContent: 'center',
    },
};
export default UnderFootIcon;
