import React from 'react';

import CircleSvg from './CircleSvg';
import { score_colors } from 'screen/analysis/AnalysisListCard/constants/analysisListConstants';

export default function FootBVSvg({ angle, rotation, score }) {
  if (!score || angle === undefined) return;

  // Generate a unique identifier for this instance if not provided
  const instanceId = `svg_${Math.random().toString(36).substr(2, 9)}`;

  const centerX = 75;
  const centerY = 100;
  const Bx = centerX;
  const By = centerY;
  const L = 70;
  const L2 = 25;

  // Convert both angles to radians
  const angleRad = ((180 - angle) * Math.PI) / 180;
  const rotationRad = (-rotation * Math.PI) / 180;

  // Segment BA (horizontal with rotation)
  const Ax = Bx + L * Math.cos(rotationRad);
  const Ay = By + L * Math.sin(rotationRad);

  // Segment BC (angle + rotation)
  const Cx = Bx + L2 * Math.cos(angleRad + rotationRad);
  const Cy = By + L2 * Math.sin(angleRad + rotationRad);

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 150 150"
      preserveAspectRatio="xMidYMid meet">
      {angle > 10 ? (
        <g
          transform={`translate(${Cx}, ${Cy}) rotate(${-rotation}) translate(-6, -18) scale(0.9)`}>
          <path
            d="M19.0505 1.20981C16.4076 1.97114 11.9155 3.74251 7.03901 3.74253C2.59509 3.74255 -0.668156 29.1229 1.91263 31.7037C7.03884 36.8299 13.8823 35.6694 17.7838 37.7553C21.7185 39.859 23.2022 40.7714 27.5439 41.7231C31.8857 42.6748 35.2524 39.4218 35.0008 36.3639C33.6014 19.3542 39.9313 25.0929 27.5449 4.82786C25.6836 1.7827 21.6933 0.448484 19.0505 1.20981Z"
            fill={`url(#paint0_linear_1280_71040_${instanceId})`}
            stroke={`url(#paint1_linear_1280_71040_${instanceId})`}
            strokeWidth={1.5}
          />
        </g>
      ) : angle < -10 ? (
        <g
          transform={`translate(${Cx}, ${Cy}) rotate(${-rotation}) translate(-6, -20) scale(0.9)`}>
          <path
            d="M22.0618 1.21943C17.4884 2.53692 18.6518 7.16757 6.76905 6.4685C-6.27565 5.70108 6.51836 30.9169 9.09914 33.4976C14.2254 38.6239 16.3757 27.4232 19.5848 30.4685C22.794 33.5139 23.7375 33.3235 28.0793 34.2752C32.421 35.2269 36.3465 31.7786 35.8218 28.7556C33.0138 12.5773 43.8551 28.1317 31.4687 7.86662C29.6075 4.82146 26.6352 -0.0980583 22.0618 1.21943Z"
            fill={`url(#paint0_linear_1280_71039_${instanceId})`}
            stroke={`url(#paint1_linear_1280_71039_${instanceId})`}
            strokeWidth={1.5}
          />
        </g>
      ) : (
        <g
          transform={`translate(${Cx}, ${Cy}) rotate(${-rotation}) translate(-18, -40) scale(0.9)`}>
          <path
            d="M27.9287 23.5184C25.2859 24.2797 19.4335 30.1799 16.0356 32.0833C8.48507 42.5516 12.7389 55.4463 15.3197 58.027C20.4459 63.1533 24.7196 61.9654 27.9287 65.0108C31.1379 68.0561 32.0813 67.8657 36.4231 68.8174C40.7649 69.7691 44.6903 66.3208 44.1656 63.2978C41.3577 47.1195 48.8095 47.4015 36.4231 27.1365C34.5619 24.0913 30.5715 22.7571 27.9287 23.5184Z"
            fill={`url(#paint0_linear_1276_70642_foot_${instanceId})`}
            stroke={`url(#paint1_linear_1276_70642_foot_${instanceId})`}
            strokeWidth={1.5}
          />
        </g>
      )}
      <g
        transform={`translate(${Bx}, ${By}) rotate(${-rotation}) translate(-40, -42) scale(0.9)`}>
        <path
          d="M211.338 28.8498C213.878 29.3354 221.509 31.1165 224.628 32.3581C237.207 37.365 257.371 42.2952 254.039 64.8948C252.305 76.6575 233.378 82.827 222.782 82.5445C212.186 82.2621 210.941 80.6688 194.903 76.4416C178.864 72.2144 153.535 72.0206 143.829 71.1338C134.123 70.2469 119.356 66.4565 105.898 62.916C92.4387 59.3754 77.5102 58.4586 71.9498 58.1704C66.3894 57.8823 53.4251 62.5334 50.5937 62.4151C45.0227 62.1823 39.0893 60.6153 35.0511 60.4244C26.5167 60.021 22.2995 51.3919 22.6234 45.6447C22.9551 39.758 23.9366 31.2566 34.7195 31.5747C40.5884 31.7479 45.6626 28.9367 49.6807 30.0395C55.6083 31.6663 61.4239 31.1795 69.0309 30.8906C76.638 30.6018 112.596 21.7896 136.477 18.4148C160.359 15.04 180.648 21.2414 190.228 24.815C199.808 28.3887 208.162 28.2428 211.338 28.8498Z"
          fill={`url(#paint4_linear_1276_70642_${instanceId})`}
          stroke={`url(#paint5_linear_1276_70642_${instanceId})`}
          strokeWidth={1.5}
        />
      </g>
      <g
        transform={`translate(${Cx}, ${Cy}) rotate(${-rotation - angle
          }) translate(-15, -43) scale(0.9)`}>
        <path
          d="M14.702 62.3551C9.00393 61.777 8.05354 53.2975 7.8636 45.7816C7.8636 34.4114 11.2126 31.8157 14.702 31.5206C19.2605 31.1352 22.1096 31.9703 26.6681 32.4842C31.7964 33.0623 37.6845 30.9425 45.092 29.9789C52.4996 29.0153 61.8058 32.8696 63.8951 41.1564C65.9844 49.4432 58.3869 56.9591 54.3982 58.6935C50.4096 60.428 40.1529 61.1988 35.0246 61.0061C29.8963 60.8134 20.4002 62.9333 14.702 62.3551Z"
          fill={`url(#paint2_linear_1276_70642_${instanceId})`}
          stroke={`url(#paint3_linear_1276_70642_${instanceId})`}
          strokeWidth={1.5}
        />
      </g>

      <path
        d={`M ${Bx},${By} L ${Ax},${Ay}`}
        stroke={score_colors[score]?.stroke}
        strokeWidth={2}
      />
      <path
        d={`M ${Bx},${By} L ${Cx},${Cy}`}
        stroke={score_colors[score]?.stroke}
        strokeWidth={2}
      />

      {/* Circle points */}
      <CircleSvg x={Ax} y={Ay} score={score} r={5} r2={2.5} />
      <CircleSvg x={Bx} y={By} score={score} r={5} r2={2.5} />
      <CircleSvg x={Cx} y={Cy} score={score} r={5} r2={2.5} />

      {/* Definitions for the gradients - all in a single defs block */}
      <defs>
        <linearGradient
          id={`paint4_linear_1276_70642_${instanceId}`}
          x1={147.298}
          y1={50.3966}
          x2={21.7705}
          y2={43.7157}
          gradientUnits="userSpaceOnUse">
          <stop
            offset={0.168406}
            stopColor={score_colors[score]?.svgLinear}
            stopOpacity={0}
          />
          <stop offset={0.626969} stopColor={score_colors[score]?.svgLinear} />
          <stop offset={0.849892} stopColor={score_colors[score]?.svgLinear} />
          <stop
            offset={0.943155}
            stopColor={score_colors[score]?.svgLinear}
            stopOpacity={0}
          />
        </linearGradient>
        <linearGradient
          id={`paint5_linear_1276_70642_${instanceId}`}
          x1={24.6088}
          y1={41.7301}
          x2={137.425}
          y2={46.7829}
          gradientUnits="userSpaceOnUse">
          <stop
            offset={0.106944}
            stopColor={score_colors[score]?.stroke}
            stopOpacity={0}
          />
          <stop offset={0.215561} stopColor={score_colors[score]?.stroke} />
          <stop offset={0.394506} stopColor={score_colors[score]?.stroke} />
          <stop
            offset={0.903966}
            stopColor={score_colors[score]?.stroke}
            stopOpacity={0}
          />
        </linearGradient>
        <linearGradient
          id={`paint2_linear_1276_70642_${instanceId}`}
          x1={49.3393}
          y1={49.6398}
          x2={6.69855}
          y2={50.1058}
          gradientUnits="userSpaceOnUse">
          <stop
            offset={0.0601391}
            stopColor={score_colors[score]?.svgLinear}
            stopOpacity={0}
          />
          <stop offset={0.212164} stopColor={score_colors[score]?.svgLinear} />
          <stop offset={0.990114} stopColor={score_colors[score]?.svgLinear} />
        </linearGradient>
        <linearGradient
          id={`paint3_linear_1276_70642_${instanceId}`}
          x1={7.8636}
          y1={47.0767}
          x2={48.4073}
          y2={46.1447}
          gradientUnits="userSpaceOnUse">
          <stop stopColor={score_colors[score]?.stroke} />
          <stop offset={0.704759} stopColor={score_colors[score]?.stroke} />
          <stop
            offset={1}
            stopColor={score_colors[score]?.stroke}
            stopOpacity={0}
          />
        </linearGradient>
        <linearGradient
          id={`paint0_linear_1280_71039_${instanceId}`}
          x1={41.9897}
          y1={22.3132}
          x2={1.79695}
          y2={26.5818}
          gradientUnits="userSpaceOnUse">
          <stop
            offset={0.103617}
            stopColor={score_colors[score]?.svgLinear}
            stopOpacity={0}
          />
          <stop offset={0.756263} stopColor={score_colors[score]?.svgLinear} />
          <stop offset={0.983165} stopColor={score_colors[score]?.svgLinear} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_1280_71039_${instanceId}`}
          x1={1.78904}
          y1={25.5754}
          x2={45.0245}
          y2={18.226}
          gradientUnits="userSpaceOnUse">
          <stop stopColor={score_colors[score]?.stroke} />
          <stop offset={0.204192} stopColor={score_colors[score]?.stroke} />
          <stop
            offset={0.884931}
            stopColor={score_colors[score]?.stroke}
            stopOpacity={0}
          />
        </linearGradient>
        <linearGradient
          id={`paint0_linear_1276_70642_foot_${instanceId}`}
          x1={48.2094}
          y1={46.8426}
          x2={8.01671}
          y2={51.1112}
          gradientUnits="userSpaceOnUse">
          <stop
            offset={0.103617}
            stopColor={score_colors[score]?.svgLinear}
            stopOpacity={0}
          />
          <stop offset={0.756263} stopColor={score_colors[score]?.svgLinear} />
          <stop offset={0.983165} stopColor={score_colors[score]?.svgLinear} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_1276_70642_foot_${instanceId}`}
          x1={8.00881}
          y1={50.1047}
          x2={51.2442}
          y2={42.7554}
          gradientUnits="userSpaceOnUse">
          <stop stopColor={score_colors[score]?.stroke} />
          <stop offset={0.204192} stopColor={score_colors[score]?.stroke} />
          <stop
            offset={0.884931}
            stopColor={score_colors[score]?.stroke}
            stopOpacity={0}
          />
        </linearGradient>
        <linearGradient
          id={`paint0_linear_1280_71040_${instanceId}`}
          x1={38.0647}
          y1={19.5872}
          x2={-2.12806}
          y2={23.8558}
          gradientUnits="userSpaceOnUse">
          <stop
            offset={0.103617}
            stopColor={score_colors[score]?.svgLinear}
            stopOpacity={0}
          />
          <stop offset={0.756263} stopColor={score_colors[score]?.svgLinear} />
          <stop offset={0.983165} stopColor={score_colors[score]?.svgLinear} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_1280_71040_${instanceId}`}
          x1={-2.13596}
          y1={22.8493}
          x2={41.0995}
          y2={15.5}
          gradientUnits="userSpaceOnUse">
          <stop stopColor={score_colors[score]?.stroke} />
          <stop offset={0.204192} stopColor={score_colors[score]?.stroke} />
          <stop
            offset={0.884931}
            stopColor={score_colors[score]?.stroke}
            stopOpacity={0}
          />
        </linearGradient>
      </defs>
    </svg>
  );
}
