import React from 'react';
import ErrorPage from 'Error-page';
import {Route, Routes} from 'react-router-dom';
import OnBoardingSpecialization from 'screen/auth/registration/onBoarding/coach/OnBoardingSpecialization';
import AnalysisExperienceCheck from 'screen/auth/registration/onBoarding/retailStore/AnalysisExperienceCheck';
import DualViewAnalysis from 'screen/auth/registration/onBoarding/retailStore/DualViewAnalysis';
import ScienceDrivenAnalysis from 'screen/auth/registration/onBoarding/common/userTypeSpecific/ScienceDrivenAnalysis';
import QuickAnalysisHighlight from 'screen/auth/registration/onBoarding/common/userTypeSpecific/QuickAnalysisHighlight';
import BrandCustomizationFeature from 'screen/auth/registration/onBoarding/retailStore/BrandCustomizationFeature';
import AnalysisSharing from 'screen/auth/registration/onBoarding/retailStore/AnalysisSharing';
import VideoTutoIntro from 'screen/auth/registration/onBoarding/common/userTypeSpecific/VideoTutoIntro';
import OnBoardingGoodVideo from 'screen/auth/registration/onBoarding/common/userTypeSpecific/OnBoardingGoodVideo';
import OnBoardingFinished from 'screen/auth/registration/onBoarding/common/OnBoardingFinished';
import CustomerUniquenessShowcase from 'screen/auth/registration/onBoarding/retailStore/CustomerUniquenessShowcase';
import PlaceOfWork from 'screen/auth/registration/onBoarding/common/userTypeSpecific/PlaceOfWork';
import DataDrivenTraining from 'screen/auth/registration/onBoarding/coach/DataDrivenTraining';

function RetailStoreStack() {
  return (
    <Routes>
      <Route
        path="/store-type-selection"
        element={<PlaceOfWork />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/store-specialization"
        element={<OnBoardingSpecialization />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/analysis-experience"
        element={<AnalysisExperienceCheck />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/dual-view-analysis"
        element={<DualViewAnalysis />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/science-driven-analysis"
        element={<ScienceDrivenAnalysis />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/analysis-highlights"
        element={<QuickAnalysisHighlight />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/brand-customization"
        element={<BrandCustomizationFeature />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/analysis-sharing"
        element={<AnalysisSharing />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/video-tuto-intro"
        element={<VideoTutoIntro />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/video-tuto"
        element={<OnBoardingGoodVideo />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/profile-creation"
        element={<OnBoardingFinished />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/customer-showcase"
        element={<CustomerUniquenessShowcase />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/sales-credibility"
        element={<DataDrivenTraining />}
        errorElement={<ErrorPage />}
      />
    </Routes>
  );
}

export default RetailStoreStack;
