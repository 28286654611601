import {getAuth} from 'firebase/auth';
import {arrayUnion, doc, getDoc, setDoc, updateDoc, runTransaction} from 'firebase/firestore';
import {firestore} from 'utils/firebaseInit';
import {translate} from './remoteConfig';

const PROMO_COLLECTION = 'promotionCode';
const USAGE_COLLECTION = 'usages';

export async function checkCodeExists(promoCodeId) {
  const currentUser = getAuth().currentUser;
  if (currentUser) {
    try {
      const docRef = doc(firestore, PROMO_COLLECTION, promoCodeId);
      const docSnap = await getDoc(docRef);
      if (docSnap?.exists()) {
        const data = docSnap.data();
        //check if the promo code is valid (not expired)
        const today = new Date();
        const expire = data?.expired.toDate();
        if (data?.starting?.toDate() > today) {
          return translate('NoCode');
        }
        if (expire?.getTime() > today?.getTime()) {
          const usageRef = doc(firestore, USAGE_COLLECTION, currentUser.uid);
          const usageSnap = await getDoc(usageRef);
          if (usageSnap?.exists()) {
            const usageList = usageSnap.data();
            if (
              usageList?.promoCode?.find(
                element => element?.promoCode === promoCodeId,
              )
            ) {
              return translate('AlreadyUsed');
            }
          }
          if (data?.number > 0) {
            return true;
          }
        }
        return translate('Expired');
      } else {
        return translate('NoCode');
      }
    } catch (error) {
      console.log('Error in promoCode api : ', error);
    }
  }
}

/**
 * Consumes a promotion code and adds it to user's usage history
 * @param {string} promoCodeId - The ID of the promotion code to consume
 * @returns {Promise<Object>} Object containing success status, message and updated promo code data
 */
export async function consumePromoCodeAndAddInUsage(promoCodeId) {
  // Validate inputs
  if (!promoCodeId || typeof promoCodeId !== 'string') {
    return {
      success: false,
      message: translate('InvalidPromoCode'),
      data: null
    };
  }

  const currentUser = getAuth().currentUser;
  if (!currentUser?.uid) {
    return {
      success: false,
      message: translate('UserNotAuthenticated'),
      data: null
    };
  }

  try {
    return await runTransaction(firestore, async (transaction) => {
      // Read promo code document
      const docRef = doc(firestore, PROMO_COLLECTION, promoCodeId);
      const promoCodeSnap = await transaction.get(docRef);
      
      if (!promoCodeSnap.exists()) {
        throw new Error('NoCode');
      }

      const promoCodeData = promoCodeSnap.data();
      
      // Validate promo code
      const now = new Date();
      if (promoCodeData.expired.toDate() < now || promoCodeData.number <= 0) {
        throw new Error('Expired');
      }

      // Check usage
      const usageRef = doc(firestore, USAGE_COLLECTION, currentUser.uid);
      const usageSnap = await transaction.get(usageRef);
      const usageData = usageSnap.exists() ? usageSnap.data() : { promoCode: [] };

      if (usageData.promoCode?.some(usage => usage.promoCode === promoCodeId)) {
        throw new Error('AlreadyUsed');
      }

      // Update both documents atomically
      const newUsage = {
        promoCode: promoCodeId,
        date: new Date().toISOString(),
      };

      transaction.set(usageRef, {
        promoCode: arrayUnion(newUsage)
      }, { merge: true });

      transaction.update(docRef, {
        number: promoCodeData.number - 1,
        usersUID: arrayUnion(currentUser.uid),
      });

      return {
        success: true,
        message: translate('PromoCodeApplied'),
        data: {
          ...promoCodeData,
          number: promoCodeData.number - 1,
        },
      };
    });
  } catch (error) {
    console.error('Error in transaction:', error);
    return {
      success: false,
      message: translate(error.message) || translate('ErrorPromoCode'),
      data: null
    };
  }
}

export async function getPromoCodePartner(promoCodeId) {
  try {
    const docRef = doc(firestore, PROMO_COLLECTION, promoCodeId);
    const docSnap = await getDoc(docRef);
    if (docSnap?.exists()) {
      const data = docSnap.data();
      return data?.partnerName;
    }
  } catch (error) {
    console.log('Error in promoCode api : ', error);
  }
}
