import React, { useState } from "react";



import { ReactComponent as ArrowIcon } from 'assets/svg/arrow.svg';
import { ReactComponent as InstructionsIcon } from 'assets/svg/instructions.svg';
import { colors } from "themes";
import TextComponent from "components/atoms/TextComponent";
import { translate } from "services/remoteConfig";
import { BOLD, H4 } from "constant/constants";
import { mpTrack } from "services/mixpanel";
import { useNavigate } from "react-router-dom";
import GoodVideoTuto from "./GoodVideoTuto";

function AnalysisTypeButton({ item, modalRef }) {

    const navigate = useNavigate();
    const [arrowHover, setArrowHover] = useState(false);
    const [instructionsHover, setInstructionsHover] = useState(false);

    const handleNavigation = () => {
        if (modalRef.current) {
            modalRef.current.open();
            modalRef.current.setContent(
                <GoodVideoTuto item={item} analysisTypeButtonPress={analysisTypeButtonPress} />
            );
        }
    };

    function analysisTypeButtonPress(item) {
        mpTrack(item?.key + 'Web App Analysis Button Clicked');
        navigate(`/upload-video/${item?.key}`);
    };


    return (
        <div style={{
            ...styles.card, ...{
                border: `2px solid ${arrowHover || instructionsHover ? colors.WHITE : colors.SECONDARY}`,
            }
        }} >
            <div style={styles.topContainer}>
                <div style={styles.titlesContainer}>
                    <TextComponent
                        text={translate(item?.title)}
                        weight={BOLD}
                        sx={styles.title}
                    />
                    <TextComponent
                        text={translate(item?.subtitle)}
                        type={H4}
                        sx={styles.subtitle}
                    />
                </div>
                <img
                    src={item?.analysisAsset}
                    alt=""
                    style={styles.asset}
                />
            </div>

            <div style={styles.bottomContainer}>
                <div onClick={handleNavigation} style={styles.instructionsButton}
                    onMouseEnter={() => setInstructionsHover(true)}
                    onMouseLeave={() => setInstructionsHover(false)}
                >
                    <InstructionsIcon height={30} width={30} stroke={instructionsHover ? colors?.WHITE : colors.WHITE_OPACITY_30} />
                    <TextComponent
                        text={translate('Instructions')}
                        sx={{ ...styles.instructionsText, ...{ color: instructionsHover ? colors.WHITE : colors.WHITE_OPACITY_30 } }}
                    />
                </div>

                <div style={styles.divider} />

                <div onClick={() => analysisTypeButtonPress(item)} style={styles.analysisButton}
                    onMouseEnter={() => setArrowHover(true)}
                    onMouseLeave={() => setArrowHover(false)}
                >
                    <TextComponent
                        text={translate('Analyse')}
                        sx={{ ...styles.analysisText, ...{ color: arrowHover ? colors.WHITE : colors.SECONDARY } }} // Assurez-vous que cette couleur correspond à `colors.SECONDARY`
                    />
                    <ArrowIcon height={30} width={30} stroke={arrowHover ? colors.WHITE : colors.SECONDARY} /> {/* Adjust stroke color */}
                </div>
            </div>
        </div >
    )
}

const styles = {
    asset: {
        width: '40%',
        height: '90%',
        objectFit: 'contain'
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        background: `radial-gradient(ellipse at top, ${colors.SECONDARY_OPACITY_30} , ${colors.SECONDARY_OPACITY_10}, ${colors.APP_BACKGROUND})`,
        borderRadius: 10,
        marginBottom: '2vh',
        height: '30vh',
    },
    bottomContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '25%',
        flexDirection: 'row',
    },
    topContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '75%',
        width: '100%',
        flexDirection: 'row',
        borderBottom: `2px solid ${colors.WHITE_OPACITY_10}`
    },

    divider: {
        backgroundColor: colors.WHITE_OPACITY_10,
        height: '100%',
        width: '2px',
    },

    instructionsButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%',
        height: '100%',
        gap: '5px',
        cursor: 'pointer'
    },
    instructionsText: {
        color: colors.WHITE_OPACITY_30,
        fontSize: '1vw',
    },
    analysisButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: '1',
        flexDirection: 'row',
        gap: '5px',
        cursor: 'pointer',

    },
    analysisText: {
        color: colors.SECONDARY,
        fontSize: '1vw',
    },
    titlesContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '45%',
        flexDirection: 'column'
    },
    title: {
        fontSize: '1.6vw'
    },
    subtitle: {
        color: colors.WHITE_OPACITY_30,
        fontSize: '1.2vw'
    },
}

export default AnalysisTypeButton;