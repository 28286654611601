import React from 'react';

import CircleSvg from './CircleSvg';
import {colors} from 'themes';
import {score_colors} from 'screen/analysis/AnalysisListCard/constants/analysisListConstants';
import {SCORE_GOOD} from 'constant/constants';

export default function HeadSvg({angle, rotation, score}) {
  if (!score || angle === undefined) return null;

  // Generate a unique identifier for this instance
  const instanceId = `head_${Math.random().toString(36).substr(2, 9)}`;

  const Bx = 100;
  const BX2 = 60;
  const By = 100;
  const L = 35;
  const L_extended = 60;
  const angleRad = (-angle * Math.PI) / 180;
  const rotationRad = (rotation * Math.PI) / 180;

  // Segment BA (horizontal with rotation)
  const Ax = Bx + L_extended * Math.cos(rotationRad);
  const Ay = By + L_extended * Math.sin(rotationRad);

  // Segment BC (angle + rotation)
  const Cx = Bx + L * Math.cos(angleRad + rotationRad);
  const Cy = By + L * Math.sin(angleRad + rotationRad);

  const radius = 20;
  const startX = Bx + radius * Math.cos(rotationRad);
  const startY = By + radius * Math.sin(rotationRad);
  const endX = Bx + radius * Math.cos(angleRad + rotationRad);
  const endY = By + radius * Math.sin(angleRad + rotationRad);

  const sweepFlag = angle > 0 ? 0 : 1; // Changed sweep direction
  const largeArcFlag = Math.abs(angle) > 180 ? 1 : 0;

  const sectorPath = `
    M ${Bx} ${By}
    L ${startX} ${startY}
    A ${radius} ${radius} 0 ${largeArcFlag} ${sweepFlag} ${endX} ${endY}
    L ${Bx} ${By}
    Z
  `;

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg">
      <defs>
        {/* First gradient */}
        <linearGradient
          id={`paint0_linear_1254_70020_${instanceId}`}
          x1="26.6827"
          y1="71.5527"
          x2="57.8875"
          y2="16.1803"
          gradientUnits="userSpaceOnUse">
          <stop
            offset="0.0752893"
            stopColor={score_colors[score]?.stroke}
            stopOpacity="0"
          />
          <stop offset="0.215561" stopColor={score_colors[score]?.stroke} />
          <stop offset="1" stopColor={score_colors[score]?.stroke} />
        </linearGradient>

        {/* Second gradient */}
        <linearGradient
          id={`paint0_linear_1254_70022_${instanceId}`}
          x1="26.8473"
          y1="0.535156"
          x2="26.8473"
          y2="80.79"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={score_colors[score]?.svgLinear} />
          <stop
            offset="0.602409"
            stopColor={score_colors[score]?.svgLinear}
            stopOpacity="0.8"
          />
          <stop
            offset="0.905017"
            stopColor={score_colors[score]?.svgLinear}
            stopOpacity="0"
          />
        </linearGradient>

        {/* Third gradient */}
        <linearGradient
          id={`paint1_linear_1254_70022_${instanceId}`}
          x1="26.9169"
          y1="22.6779"
          x2="25.9593"
          y2="62.0651"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={score_colors[score]?.stroke} />
          <stop offset="0.567706" stopColor={score_colors[score]?.stroke} />
          <stop
            offset="1"
            stopColor={score_colors[score]?.stroke}
            stopOpacity="0"
          />
        </linearGradient>
      </defs>

      <g transform={`translate(100, 100) translate(-25, -5) scale(1.3)`}>
        <path
          d="M13.8724 32.6673C12.9184 27.8972 9.82225 24.5158 5.98469 19.719C-2.53007 5.68769 13.0601 -5.58615 29.3708 4.24816C40.8755 11.1848 40.9088 26.5434 39.9494 32.18C38.99 37.8166 38.6302 44.2927 38.7501 47.0511C38.8701 49.8094 41.5257 51.3577 45.2082 54.8297C55.4822 64.5166 49.2786 71.5277 46.6402 75.3654C44.0018 79.2031 36.4463 83.4006 18.2172 78.7234C-0.0118694 74.0462 -2.72547 63.6977 5.21306 54.8297C11.7419 47.5365 14.7901 37.2559 13.8724 32.6673Z"
          fill={`url(#paint0_linear_1254_70022_${instanceId})`}
          stroke={`url(#paint1_linear_1254_70022_${instanceId})`}
          strokeDasharray={score === SCORE_GOOD ? 0 : 7}
          strokeWidth="2"
        />
      </g>

      <g
        transform={`translate(100, 100) rotate(${-angle}) translate(-30, -35) scale(1.3)`}>
        <path
          d="M2.00839 33.939C3.31605 39.0763 5.8492 41.6558 10.3794 43.4615C16.1425 45.7587 21.121 45.4438 23.4183 45.6353C25.7155 45.8267 26.628 45.3612 27.9042 47.0203C29.1805 48.6794 32.4977 57.8893 36.5188 59.655C37.8677 60.2473 51.7943 62.3325 52.8153 62.5877C53.8363 62.843 56.4629 62.6536 57.3214 58.5063C58.3423 53.5741 58.677 46.6638 58.3424 41.4687C59.5636 40.9565 59.8921 41.0542 61.4236 39.9694C62.9551 38.8846 63.2 36.8034 62.4969 36.1264C61.5027 35.169 54.7987 28.6752 54.352 27.6542C53.9053 26.6332 55.2398 23.2499 55.5588 21.7823C55.8779 20.3146 56.5266 18.694 50.9226 10.4748C45.3186 2.25568 37.7532 1.50882 27.8529 1.04182C17.9525 0.574823 10.4308 4.0262 5.10704 11.0312C-0.216739 18.0361 0.700734 28.8018 2.00839 33.939Z"
          fill={score_colors[score]?.svgLinear}
          stroke={`url(#paint0_linear_1254_70020_${instanceId})`}
          strokeDasharray={score === SCORE_GOOD ? 0 : 7}
          strokeWidth="2"
        />
      </g>

      <path
        d={`M ${BX2},${By} L ${Ax},${Ay}`}
        stroke={colors.WHITE}
        strokeWidth="2"
        strokeDasharray="10"
      />

      <path
        d={`M ${Bx},${By} L ${Cx},${Cy}`}
        stroke={score_colors[score]?.stroke}
        strokeWidth="2"
      />

      <path
        d={sectorPath}
        fill={score_colors[score]?.stroke}
        fillOpacity="0.4"
        stroke={score_colors[score]?.stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <CircleSvg x={Bx} y={By} score={score} />
      <CircleSvg x={Cx} y={Cy} score={score} />
    </svg>
  );
}
