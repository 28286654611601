import React, {useEffect, useState} from 'react';
import './styles/PartnerActivationRegistrationSuccess.css';
import TextComponent from 'components/atoms/TextComponent';
import {BOLD, H2, H4, H5, ORANGE} from 'constant/constants';
import {colors, images} from 'themes';
import {useLocation} from 'react-router-dom';

import Lottie from 'lottie-react';
import fireworks from 'assets/animations/fireworks.json';
import ButtonComponent from 'components/atoms/ButtonComponent';
import FadinView from 'components/atoms/FadinView';
import {ReactComponent as Close} from 'assets/svg/close.svg';
import {isMobile, isTablet} from 'react-device-detect';
import {mpTrack} from 'services/mixpanel';
import {translate} from 'services/remoteConfig';
import {consumePromoCodeAndAddInUsage} from 'services/promoCodeApi';
import {addCredit, addQuarterlyPlan} from 'services/usageApi';
import * as AdjustSdk from '@adjustcom/adjust-web-sdk';
import {replaceBracketInTranslate} from 'utils/utils';
import {createMessageOnSlack} from 'services/slack';
import {setPartnerNameInUser, updateProfile} from 'services/userApi';

function PartnerActivationRegistrationSuccess({partner}) {
  const location = useLocation();
  const {promoCode} = location.state || {};
  const [lottieVisible, setLottieVisible] = useState(true);
  const [promoCodeData, setPromoCodeData] = useState(null);

  const [animationFinished, setAnimationFinished] = useState(false);

  function handleAnimationFinished() {
    setAnimationFinished(true);
  }

  /**
   * Handles promo code activation and applies corresponding benefits
   * @returns {Promise<void>}
   */
  async function handlePromoCode() {
    createMessageOnSlack(
      {
        promoCode: promoCode,
        partnerName: partner?.partnerName,
        userID: location.state?.userID,
        promoFrom: location.state?.promoFrom,
        isSSO: location.state?.isSSO,
      },
      'promoCode',
    );
    try {
      // Early validation
      if (!promoCode || !partner?.partnerName) {
        console.error('Missing promo code or partner information');
        return;
      }
      // Check if already activated
      const storageKey = `promoCodeActivated${promoCode}`;
      if (localStorage?.getItem(storageKey)) {
        mpTrack(`${partner.partnerName} Promo Code Already Used`);
        return;
      }

      // Step 1: Consume the promo code
      const promocodeConsumed = await consumePromoCodeAndAddInUsage(promoCode);
      if (!promocodeConsumed?.success) {
        console.error(
          'Failed to consume promo code:',
          promocodeConsumed?.message,
        );
        return;
      }

      // Step 2: Update user profile
      try {
        await updateProfile({partnerName: partner.partnerName});
      } catch (error) {
        console.error('Failed to update profile:', error);
        // Consider whether to proceed or return
      }

      // Step 3: Apply benefits based on promo code type
      const promoData = promocodeConsumed.data;

      let benefitApplied = false;
      console.log('promoData', promoData, promoData?.type, promoData?.subscriptionType);
      if (promoData?.type === 'CREDIT' && promoData?.creditGifted > 0) {
        try {
          benefitApplied = await addCredit(promoData.creditGifted);
          if (benefitApplied) {
            mpTrack(
              `${partner.partnerName} Unique Analysis Promo Code Validated`,
            );
          }
        } catch (error) {
          console.error('Failed to add credit:', error);
          throw new Error('Credit application failed');
        }
      } else if (
        promoData?.type === 'AFFILIATION' &&
        promoData?.subscriptionType === 'quarterly'
      ) {
        try {
          benefitApplied = await addQuarterlyPlan();
          if (benefitApplied) {
            mpTrack(`${partner.partnerName} Promo Code Entered`);
          }
        } catch (error) {
          console.error('Failed to add quarterly plan:', error);
          throw new Error('Quarterly plan application failed');
        }
      } else {
        console.error('Invalid promo code type:', promoData?.type);
        throw new Error('Invalid promo code type');
      }

      // Step 4: Mark as activated only if benefit was successfully applied
      if (benefitApplied) {
        localStorage.setItem(storageKey, 'true');
      } else {
        throw new Error('Benefit application failed');
      }
      setPromoCodeData(promoData);

    } catch (error) {
      console.error('Promo code activation failed:', error);
      // Here you might want to:
      // 1. Revert the promo code consumption
      // 2. Show error to user
      // 3. Track failure in analytics
    }
  }

  useEffect(() => {
    handlePromoCode();
  }, []);

  useEffect(() => {
    if (animationFinished) {
      setTimeout(() => {
        setLottieVisible(false);
      }, 3000);
    }
  }, [animationFinished]);

  return (
    <FadinView>
      <div className="green-bar-container">
        <div
          style={{
            backgroundColor: colors.BLACK_OPACITY_70,
            height: '100%',
            width: '100%',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
          <div className="green-bar" onAnimationEnd={handleAnimationFinished} />
          <div style={{zIndex: 2}}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 20,
              }}
              className="">
              <img
                src={images.OCHY_LOGO_VERTICAL_WHITE}
                alt={'Ochy white logo'}
                style={{width: 160}}
                className=""
              />
              <Close stroke="white" className="cross" />
              <img
                src={partner?.partnerImageWhite}
                style={{width: 160}}
                alt={'Logo partner'}
                className=""
              />
            </div>
            {animationFinished ? (
              <FadinView>
                <TextComponent
                  text={translate('Success')}
                  variant={H2}
                  weight={BOLD}
                  sx={{
                    color: colors.GREEN_FLASH,
                    zIndex: 1,
                    textAlign: 'center',
                    padding: 2,
                  }}
                />
                {lottieVisible && (
                  <Lottie
                    animationData={fireworks}
                    style={{
                      position: 'absolute',
                      bottom: isMobile || isTablet ? '0' : '25%',
                      left: isMobile || isTablet ? '0' : '25%',
                      width: isMobile || isTablet ? '100%' : '50%',
                      height: isMobile || isTablet ? '100%' : '50%',
                      zIndex: -2,
                    }}
                  />
                )}
                <TextComponent
                  text={
                    promoCodeData?.type === 'CREDIT'
                      ? replaceBracketInTranslate(
                          translate('PartnerCreditWelcome'),
                          'trad_partnerName',
                          partner?.trad_partnerName,
                        )
                      : replaceBracketInTranslate(
                          translate('PartnerWelcome'),
                          'trad_partnerName',
                          partner?.trad_partnerName,
                        )
                  }
                  variant={H5}
                  weight={BOLD}
                  sx={{
                    color: colors.WHITE,
                    textAlign: 'center',
                    padding: 2,
                  }}
                />
                <div
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                    marginTop: 30,
                  }}>
                  <ButtonComponent
                    type={ORANGE}
                    title={translate('GoToMobile')}
                    onClick={() => {
                      mpTrack(
                        `${partner?.partnerName} Activation Success Web App Button Clicked`,
                      );
                      window.location.href =
                        promoCodeData?.type === 'CREDIT'
                          ? partner?.tracking_link_unique_analysis
                          : partner?.tracking_link_3_months;
                    }}
                  />
                </div>
              </FadinView>
            ) : (
              <>
                <TextComponent
                  text={replaceBracketInTranslate(
                    translate('PartnerPromoCodeActivation'),
                    'partnerName',
                    partner?.partnerName,
                  )}
                  variant={H5}
                  weight={BOLD}
                  sx={{
                    color: colors.WHITE,
                    textAlign: 'center',
                    padding: 2,
                  }}
                />
                <TextComponent
                  text={promoCode}
                  variant={H4}
                  weight={BOLD}
                  sx={{
                    color: colors.GREEN,
                    zIndex: 1,
                    textAlign: 'center',
                    padding: 2,
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </FadinView>
  );
}

export default PartnerActivationRegistrationSuccess;
