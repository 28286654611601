import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import TextComponent from 'components/atoms/TextComponent';
import ButtonBackAndNext from 'components/molecules/ButtonBackAndNext';
import ContactSalesForm from './ContactSalesForm';

import { translate } from 'services/remoteConfig';
import { createMessageOnSlack } from 'services/slack';
import { postRequestOnHubspot } from 'services/usageApi';
import { mpTrack } from 'services/mixpanel';

import { createTextObjectReusable } from 'utils/utils';

import { BODY_2, BOLD, H6, LIGHT } from 'constant/constants';
import { FORM_INPUTS } from 'constant/contactSalesConstants';

import { colors } from 'themes';

import OchyTeam from 'assets/images/ochy_team.png';

function ContactSales({ big = false, sidePanRef }) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [step, setStep] = useState(0);
  const userSlice = useSelector(state => state?.user);

  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({
    email: '',
    phoneNumber: '',
    companyName: '',
    role: '',
    jobTitle: '',
    jobTitleOther: '',
    analysisNumber: '',
    usersNumber: '',
    notes: '',
  });


  function goToNextStep() {
    if (step === FORM_INPUTS?.length - 1) {
      handlePressSend();
    } else {
      const mail = formValues?.email;
      const phone = formValues?.phoneNumber;
      if (mail && !mail.match(/^\S+@\S+$/)) {
        setErrorMessage('AuthErrorInvalidEmail');
        return;
      }

      if (phone && !/^\+\d+$/?.test(phone)) {
        setErrorMessage('ErrorInvalidPhone');
        return;
      }

      setErrorMessage(null);
      setStep(prev => prev + 1);
    }
  }

  async function handlePressSend() {
    setStep(prev => prev + 1);
    createMessageOnSlack(formValues, 'contactSales', formValues?.email, userSlice);
    await postRequestOnHubspot(formValues);
    mpTrack('Web App Contact Sales Form Submitted')
  };

  function onFinish() {
    if (sidePanRef) sidePanRef.current.close();
    else navigate('/');
  }

  return (
    <div style={styles.container}>
      <img
        style={{ ...styles.image, width: big ? '40%' : '100%' }}
        src={OchyTeam}
        alt="Ochy_Team"
      />
      <div style={styles.titleContainer}>
        <TextComponent
          multipleText={createTextObjectReusable(
            translate('ContactSalesTeamTitle'),
            { weight: BOLD, variant: H6, style: { color: colors.WHITE } },
            { weight: BOLD, variant: H6, style: { color: colors.SECONDARY } },
          )}
        />
        <TextComponent
          text={translate('ContactSalesTeamSubTitle')}
          variant={BODY_2}
          weight={LIGHT}
          sx={styles.textLight}
        />
      </div>


      <ContactSalesForm formValues={formValues} setFormValues={setFormValues} step={step} errorMessage={errorMessage} setErrorMessage={setErrorMessage} big={big} onFinish={onFinish} />


      <div style={{ ...styles.buttonContainer, ...{ width: !big ? '21vw' : '30vw' } }} >
        <ButtonBackAndNext
          disabled={{
            showNext: step === FORM_INPUTS?.length,
            disabledFinish: !formValues['notes'],
            disabledBack: step === 0,
            disabledNext: FORM_INPUTS[step]?.components?.some(element => {
              if (step === FORM_INPUTS?.length - 1) {
                return false;
              }
              if (!formValues[element?.id]) {
                return true;
              } else if (
                (element?.id === 'role' && !formValues['jobTitle']) ||
                (element?.id === 'role' &&
                  formValues['jobTitle'] === translate('Other') &&
                  !formValues['jobTitleOther'])
              ) {
                return true;
              } else {
                return false;
              }
            }),
          }}
          step={step}
          goToNextStep={goToNextStep}
          goToPreviousStep={() => setStep(prev => prev - 1)}
          button={{
            disabled: step !== FORM_INPUTS?.length - 1,
            title: translate('Send'),
          }}
        />
      </div>


    </div >
  );
}

const styles = {
  buttonContainer: { display: 'flex', flex: 1, alignItems: 'flex-end', marginBottom: '4vh' },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '90vh',
  },
  image: {
    height: '20vh',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center top',
    borderRadius: 10,
  },
  textLight: {
    color: colors.TEXT_LIGHT,
  },
  titleContainer: {
    justifyContent: 'center',
    marginTop: 10,
    textAlign: 'center',
  },
};

export default ContactSales;
