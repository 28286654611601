import React, {useEffect, useState, useMemo} from 'react';

import ProductCard from './ProductCard';

import {useSelector} from 'react-redux';

import {COUNTRIES_CURRENCIES} from 'constant/currencyCountry';
import {getCurrentUserSubscriptions} from '@invertase/firestore-stripe-payments';

function ListProducts({products, selected, setSelected, payments}) {
  const userSlice = useSelector(state => state?.user);
  const [subscriptions, setSubscriptions] = useState([]);
  const [currency, setCurrency] = useState(undefined);

  useEffect(() => {
    // Function to get user's country from IP and set currency
    const getCurrencyFromIP = async () => {
      try {
        // Use a free IP geolocation API
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        
        // Get the user's country code from the API response
        const userCountryCode = data?.country_code;
        
        // Find the currency based on the country code
        const currency = COUNTRIES_CURRENCIES?.country?.find(
          c => c?.countryCode === userCountryCode,
        );
        
        // Set the currency state
        setCurrency(currency?.currencyCode === 'EUR' ? 'eur' : 'usd');
      } catch (error) {
        console.error('Error fetching location data:', error);
        // Fallback to USD if there's an error
        setCurrency('usd');
      }
    };
  
    // Call the function when component mounts
    getCurrencyFromIP();
  }, []);

  useEffect(() => {
    getCurrentUserSubscriptions(payments, {status: 'active'}).then(
      subscriptions => {
        setSubscriptions(subscriptions);
      },
    );
  }, []);
  // Use useMemo to prevent unnecessary recalculations when other state changes
  const sortedProducts = useMemo(() => {
    return products
      ?.filter(product => {
        // Determine the target interval based on selected
        const targetInterval = selected ? 'year' : 'month';

        // Filter prices within the product
        const filteredPrices = product?.prices?.filter(
          price =>
            // Check if price type is recurring
            price.type === 'recurring' &&
            // Check if interval matches the target interval
            price.interval === targetInterval &&
            // Check if currency matches (only filter if currency is defined)
            (currency ? price.currency === currency : true),
        );

        // Only keep products that have at least one matching price
        return filteredPrices.length > 0;
      })
      ?.map(product => ({
        ...product,
        // Replace prices with filtered prices
        prices: product?.prices?.filter(
          price =>
            price?.type === 'recurring' &&
            price?.interval === (selected ? 'year' : 'month') &&
            (currency ? price?.currency === currency : true),
        ),
      }));
  }, [products, selected, currency]);

  // Use useMemo for one-shot products as well
  const oneShotProduct = useMemo(() => {
    return products
      ?.filter(product => {
        // Filter prices within the product
        const filteredPrices = product?.prices?.filter(
          price =>
            // Check if price type is recurring
            price.type === 'one_time' &&
            // Check if currency matches (only filter if currency is defined)
            (currency ? price.currency === currency : true),
        );
        // Only keep products that have at least one matching price
        return filteredPrices.length > 0;
      })
      ?.map(product => ({
        ...product,
        // Replace prices with filtered prices
        prices: product?.prices?.filter(
          price =>
            price.type === 'one_time' &&
            (currency ? price.currency === currency : true),
        ),
      }));
  }, [products, currency]);

  // Enterprise product data doesn't change, so we can define it outside the render
  const enterpriseProduct = {
    id: 'onDemand',
    type: 'OnDemand',
    metadata: {
      features:
        'OnDemandPerkOne,OnDemandPerkTwo,OnDemandPerkThree,OnDemandPerkFour',
      perks: 'OnDemand',
      name: 'EnterprisePlan',
    },
    prices: [
      {
        transform_quantity: null,
        billing_scheme: 'per_unit',
        tax_behavior: 'unspecified',
        tiers: null,
        unit_amount: 19900,
        tiers_mode: null,
        active: true,
        type: 'OnDemand',
      },
    ],
  };

  return (
    <div style={styles.productsContainer}>
      {sortedProducts?.map((product, index) => (
        <ProductCard
          subscriptions={subscriptions}
          payments={payments}
          key={`recurring-${product.id}-${index}`}
          selected={selected}
          currency={currency}
          product={product}
        />
      ))}

      {oneShotProduct?.map((product, index) => (
        <ProductCard
          subscriptions={subscriptions}
          payments={payments}
          key={`oneshot-${product.id}-${index}`}
          selected={selected}
          currency={currency}
          product={product}
        />
      ))}

      <ProductCard
        subscriptions={subscriptions}
        payments={payments}
        selected={selected}
        currency={currency}
        product={enterpriseProduct}
      />
    </div>
  );
}

const styles = {
  productsContainer: {
    display: 'flex',
    width: '100%',
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    alignContent: 'stretch',
  },
};

export default ListProducts;
