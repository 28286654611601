import React, {useEffect, useState} from 'react';

import {getProduct} from '@invertase/firestore-stripe-payments';

import TextComponent from 'components/atoms/TextComponent';

import {translate} from 'services/remoteConfig';

import {ReactComponent as CheckMarkIcon} from 'assets/svg/checkmark.svg';
import {colors} from 'themes';

export default function SubscriptionInformationPlanFeatures({
  product,
  subscription,
  payments,
}) {
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    const getFeatures = async () => {
      const features = await getProduct(payments, subscription?.product);
      setFeatures(features);
    };
    if (subscription?.product) getFeatures();
  }, [payments, subscription]);

  function parseCommaSeparatedString(inputString) {
    if (typeof inputString !== 'string' || inputString?.trim() === '') {
      return [];
    }

    return inputString?.split(',')?.map(item => item?.trim());
  }
  const values = parseCommaSeparatedString(features?.metadata?.features);
  if (values?.length < 1) {
    return null;
  }
  return (
    <div style={styles.container}>
      <TextComponent
        variant="h6"
        text={translate('PlanFeatures')}
        sx={styles.title}
      />
      {(() => {
        if (!features?.metadata) {
          return;
        }
        return values?.map((element, index) => {
          return (
            <div key={index} style={styles.featuresContainer}>
              <div
                style={{
                  ...styles.borders,
                  ...{
                    borderTop:
                      index !== 0 && '0.5px solid rgba(255,255,255,0.15)',
                  },
                }}>
                <div style={{alignSelf: 'center', marginRight: 10}}>
                  <CheckMarkIcon
                    height={25}
                    width={25}
                    stroke={colors.VALID_IMAGE}
                    fill={colors.VALID_IMAGE}
                  />
                </div>
                <TextComponent
                  variant="body1"
                  text={translate(element)}
                  sx={styles.rowText}
                />
              </div>
            </div>
          );
        });
      })()}
    </div>
  );
}
const styles = {
  borders: {
    display: 'flex',
    flexDirection: 'row',
    width: '80%',
  },
  container: {
    backgroundColor: colors.BACKGROUND,
    flex: 1,
    margin: 15,
    borderRadius: 10,
    padding: 10,
  },
  featuresContainer: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: 50,
    justifyContent: 'center',
  },
  rowText: {
    textAlign: 'left',
    alignSelf: 'center',
    color: colors.WHITE_OPACITY_60,
  },
  title: {
    textAlign: 'left',
    marginTop: 2,
    fontWeight: 'bold',
    marginBottom: 2,
  },
};
