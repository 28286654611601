import React from 'react';
import CircleSvg from './CircleSvg';
import {score_colors} from 'screen/analysis/AnalysisListCard/constants/analysisListConstants';
import {SCORE_GOOD} from 'constant/constants';

export default function ArmSvg({angle, rotation, score}) {
  if (!score || angle === undefined) return null;

  // Generate a unique identifier for this instance
  const instanceId = `arm_${Math.random().toString(36).substr(2, 9)}`;

  const centerX = 25;
  const centerY = 150;

  const Bx = centerX;
  const By = centerY;
  const L = 100;

  // Convert both angles to radians
  const angleRad = (angle * Math.PI) / 180;
  const rotationRad = (-rotation * Math.PI) / 180;

  // Segment BA (horizontal with rotation)
  const Ax = Bx + L * Math.cos(rotationRad);
  const Ay = By + L * Math.sin(rotationRad);

  // Segment BC (angle + rotation)
  const Cx = Bx + L * Math.cos(angleRad + rotationRad);
  const Cy = By + L * Math.sin(angleRad + rotationRad);

  // Arc parameters
  const radius = 60; // You can adjust this value
  const startX = Bx + radius * Math.cos(rotationRad);
  const startY = By + radius * Math.sin(rotationRad);
  const endX = Bx + radius * Math.cos(angleRad + rotationRad);
  const endY = By + radius * Math.sin(angleRad + rotationRad);
  const largeArcFlag = angle > 180 ? 1 : 0;

  const sectorPath = `
  M ${Bx} ${By}
  L ${startX} ${startY}
  A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}
  L ${Bx} ${By}
  Z
`;

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 200 200"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient
          id={`paint0_linear_1238_67917_${instanceId}`}
          x1="109.103"
          y1="18.4587"
          x2="-8.10971"
          y2="15.7342"
          gradientUnits="userSpaceOnUse">
          <stop
            offset="0.0601391"
            stopColor={score_colors[score]?.svgLinear}
            stopOpacity="0"
          />
          <stop
            offset="0.428599"
            stopColor={score_colors[score]?.svgLinear}
            stopOpacity="0.8"
          />
          <stop
            offset="0.820064"
            stopColor={score_colors[score]?.svgLinear}
            stopOpacity="0.8"
          />
          <stop
            offset="1"
            stopColor={score_colors[score]?.svgLinear}
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_1238_67917_${instanceId}`}
          x1="104.631"
          y1="9.0558"
          x2="8.2713"
          y2="4.92339"
          gradientUnits="userSpaceOnUse">
          <stop
            offset="0.126021"
            stopColor={score_colors[score]?.stroke}
            stopOpacity="0"
          />
          <stop offset="0.215561" stopColor={score_colors[score]?.stroke} />
          <stop offset="0.81792" stopColor={score_colors[score]?.stroke} />
          <stop
            offset="1"
            stopColor={score_colors[score]?.stroke}
            stopOpacity="0"
          />
        </linearGradient>

        {/* Second gradient for second path */}
        <linearGradient
          id={`paint0_linear_1238_67876_${instanceId}`}
          x1="89.2979"
          y1="8.85877"
          x2="-17.3061"
          y2="16.138"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={score_colors[score]?.svgLinear} stopOpacity="0" />
          <stop
            offset="0.108068"
            stopColor={score_colors[score]?.svgLinear}
            stopOpacity="0.8"
          />
          <stop
            offset="0.755086"
            stopColor={score_colors[score]?.svgLinear}
            stopOpacity="0.8"
          />
          <stop
            offset="1"
            stopColor={score_colors[score]?.svgLinear}
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_1238_67876_${instanceId}`}
          x1="-3.07673"
          y1="0.117342"
          x2="76.8364"
          y2="33.747"
          gradientUnits="userSpaceOnUse">
          <stop
            offset="0.132086"
            stopColor={score_colors[score]?.stroke}
            stopOpacity="0"
          />
          <stop offset="0.258142" stopColor={score_colors[score]?.stroke} />
          <stop offset="0.844248" stopColor={score_colors[score]?.stroke} />
          <stop
            offset="0.965534"
            stopColor={score_colors[score]?.stroke}
            stopOpacity="0"
          />
        </linearGradient>

        {/* Third gradient */}
        <linearGradient
          id={`paint0_linear_1238_67933_${instanceId}`}
          x1="7.23056"
          y1="35.1774"
          x2="68.4396"
          y2="22.4638"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={score_colors[score]?.svgLinear} stopOpacity="0" />
          <stop
            offset="0.05"
            stopColor={score_colors[score]?.svgLinear}
            stopOpacity="0.8"
          />
          <stop
            offset="1"
            stopColor={score_colors[score]?.svgLinear}
            stopOpacity="0.8"
          />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_1238_67933_${instanceId}`}
          x1="7.58619"
          y1="39.2453"
          x2="41.4176"
          y2="32.0733"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={score_colors[score]?.stroke} stopOpacity="0" />
          <stop offset="0.529667" stopColor={score_colors[score]?.stroke} />
          <stop offset="1" stopColor={score_colors[score]?.stroke} />
        </linearGradient>

        <circle transform="translate(100 100)" cx="0" cy="0" r="7.16602" />
      </defs>

      {/* Decorative gradient shape along BA path */}
      <g
        transform={`translate(${centerX}, ${centerY}) rotate(${-rotation}) translate(-10, -15) scale(1.2)`}>
        <path
          d="M53.9042 33.1605C43.1878 31.9515 31.923 29.6468 22.3796 26.2094C17.3186 24.3865 14.4456 24.5902 8.97652 23.9806C2.14017 23.2185 -4.01327 12.8746 8.46518 7.64556C14.955 4.92603 22.4695 5.16899 27.8211 4.22259C33.1726 3.2762 39.7305 2.45574 44.2205 2.58154C48.7105 2.70735 50.6851 2.17427 54.6802 1.73058C58.6752 1.28689 62.0915 1.95469 66.2894 2.65777C70.4872 3.36084 71.4978 2.28039 76.6769 1.1017C81.8559 -0.0769849 89.7498 2.20634 97.9141 6.01867C106.078 9.831 108.15 19.3198 108.152 23.7873C108.154 28.2547 103.919 30.3175 102.398 31.376C100.877 32.4344 100.173 34.3971 97.0101 35.0878C93.8472 35.7786 90.1365 34.8584 85.579 34.3504C81.0214 33.8423 79.6865 32.2381 76.8608 32.6064C74.0351 32.9747 66.9826 34.636 53.9042 33.1605Z"
          fill={`url(#paint0_linear_1238_67917_${instanceId})`}
          stroke={`url(#paint1_linear_1238_67917_${instanceId})`}
          strokeDasharray={score === SCORE_GOOD ? '0' : '7'}
          strokeWidth="2"
        />
      </g>

      {/* Decorative gradient shape along BC path */}
      <g
        transform={`translate(${centerX}, ${centerY}) rotate(${
          angle - rotation
        }) translate(-10, -15) scale(1.3)`}>
        <path
          d="M56.6156 21.0454C65.9464 19.4308 72.8866 18.8578 76.5169 18.3924C79.6051 18.0212 85.4546 15.3736 84.1476 7.75249C82.8407 0.131365 76.4378 2.58719 70.0372 3.67999C55.4047 6.1777 45.241 2.98534 37.8075 1.77916C28.5155 0.271419 24.9512 1.16853 16.3399 1.5699C7.72854 1.97128 1.92492 5.65778 1.37526 10.3181C0.549954 17.3155 6.02314 19.8681 7.80607 20.2268C12.2243 21.1155 21.6676 22.0265 29.8084 22.822C37.9491 23.6175 47.2847 22.6601 56.6156 21.0454Z"
          fill={`url(#paint0_linear_1238_67876_${instanceId})`}
          stroke={`url(#paint1_linear_1238_67876_${instanceId})`}
          strokeDasharray={score === SCORE_GOOD ? '0' : '7'}
          strokeWidth="2"
        />
      </g>

      {/* Third decorative gradient shape - also following BC path with different positioning */}
      <g
        transform={`translate(${centerX}, ${centerY}) rotate(${
          angle + -rotation
        }) translate(85, -40) scale(1.2)`}>
        <path
          d="M13.7895 20.4109C12.6727 22.0627 10.3002 23.962 7.37478 24.8128C4.58098 25.4185 -0.410498 28.2402 1.97394 34.6811C4.35838 41.122 11.9116 40.8945 15.3901 39.9756C18.5424 39.8118 22.177 41.467 24.9074 41.6334C27.6377 41.7999 32.7285 41.467 34.7175 41.467C36.7064 41.467 45.0644 43.1317 49.9849 44.0639C54.9053 44.9962 56.3899 44.0972 56.7276 43.6644C57.0653 43.2316 56.7302 41.7666 54.6743 41.467C52.6183 41.1673 43.587 38.9033 42.0368 38.5371C40.4866 38.1708 39.173 37.3385 39.3426 36.606C39.5122 35.8735 42.1766 34.9746 42.9522 34.7748C43.7278 34.575 62.9782 33.5429 64.7651 33.3431C66.5521 33.1434 67.2297 30.6796 65.0354 30.3162C63.9141 30.1306 58.3201 30.1831 56.7355 30.3162C55.1509 30.4494 45.8231 30.846 44.5181 30.6796C43.2132 30.5131 43.8701 29.2479 44.3424 29.0148C44.8146 28.7818 53.6815 28.1159 54.693 27.9827C55.7045 27.8495 65.1107 27.2502 66.9656 26.6842C68.8205 26.1182 69.0483 23.9725 67.2397 23.5213C66.5781 23.3562 59.7203 23.9725 57.1197 23.9725C54.5191 23.9725 46.1981 24.8128 44.5263 24.8128C42.8545 24.8128 42.7629 23.8962 43.2284 23.0706C43.694 22.245 48.2457 21.7864 50.4757 21.1442C52.7056 20.5021 59.6586 19.9603 62.6316 19.3177C65.6047 18.675 64.4273 15.6927 62.2196 15.6927C59.7374 15.6927 55.4806 16.2507 53.2513 16.4342C51.0219 16.6176 39.5203 18.0253 37.477 18.0253C35.4336 18.0253 28.9321 18.0253 28.1906 16.9246C27.4491 15.8239 29.4958 13.4393 30.2405 12.2463C30.9852 11.0532 33.1273 6.74249 32.6674 3.53188C32.2075 0.321277 29.978 0.596834 29.5124 1.51415C29.0467 2.43147 26.5325 7.11032 25.415 9.22015C24.2975 11.33 21.8821 11.6964 19.7435 13.4393C17.6048 15.1822 17.5093 17.108 16.5792 18.0253C15.6491 18.9426 14.9063 18.7592 13.7895 20.4109Z"
          fill={`url(#paint0_linear_1238_67933_${instanceId})`}
          stroke={`url(#paint1_linear_1238_67933_${instanceId})`}
          strokeWidth="2"
        />
      </g>

      <path
        d={`M ${Bx},${By} L ${Ax},${Ay}`}
        stroke={score_colors[score]?.stroke}
        strokeWidth="2"
      />

      <path
        d={`M ${Bx},${By} L ${Cx},${Cy}`}
        stroke={score_colors[score]?.stroke}
        strokeWidth="2"
      />

      <path
        d={sectorPath}
        fill={score_colors[score]?.stroke}
        fillOpacity="0.4"
        stroke={score_colors[score]?.stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <CircleSvg x={Ax} y={Ay} score={score} />
      <CircleSvg x={Bx} y={By} score={score} />
      <CircleSvg x={Cx} y={Cy} score={score} />
    </svg>
  );
}
