import React from 'react';

export default function KneeAdductionIcon({
    leftFill = '#424242',
    rightFill = '#424242',
    leftFillLinear = '#424242',
    rightFillLinear = '#424242',
    strokeWidth = 1,
    style,
}) {
    // Generate a unique identifier for this instance
    const instanceId = `knee_adduction_${Math.random().toString(36).substr(2, 9)}`;

    return (
        <svg
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={style}>
            <defs>
                <linearGradient id={`gradient1_${instanceId}`} x1="15.8368" y1="5.24121" x2="15.8368" y2="34.7591" gradientUnits="userSpaceOnUse">
                    <stop stopColor={leftFillLinear} />
                    <stop offset="0.34" stopColor={leftFill} />
                    <stop offset="0.68" stopColor={leftFill} />
                    <stop offset="1" stopColor={leftFillLinear} />
                </linearGradient>
                <linearGradient id={`gradient2_${instanceId}`} x1="24.1632" y1="5.24121" x2="24.1632" y2="34.7591" gradientUnits="userSpaceOnUse">
                    <stop stopColor={rightFillLinear} />
                    <stop offset="0.34" stopColor={rightFill} />
                    <stop offset="0.68" stopColor={rightFill} />
                    <stop offset="1" stopColor={rightFillLinear} />
                </linearGradient>
            </defs>

            <path
                d="M13.3314 14.2986C12.5315 11.1625 12.7836 8.33201 12.8018 5.54122C12.8029 5.37553 12.9372 5.24121 13.1028 5.24121H18.5335C18.6992 5.24121 18.8334 5.37551 18.8349 5.54118C18.8527 7.44314 19.026 9.37396 18.8335 11.7412C18.5969 14.6515 18.7751 14.1052 17.7784 17.598C17.1252 19.8874 18.3989 21.3226 18.2673 23.4753C18.0634 26.8104 17.0793 30.4819 16.9373 31.0108C16.7953 31.5396 17.3025 31.5863 17.0734 32.1635C16.935 32.5119 17.8475 33.4233 17.7784 34.1403C17.7576 34.3561 17.6397 34.5489 17.5576 34.6597C17.5089 34.7254 17.4303 34.7585 17.3485 34.7585H13.3652C13.3565 34.7585 13.348 34.7589 13.3393 34.759C13.2566 34.7606 12.8031 34.7389 12.8031 34.0691C12.8031 33.75 13.1892 33.5788 13.3522 33.1973C13.5434 32.7498 13.5099 32.617 13.6861 32.1635C13.9368 31.518 14.1356 31.6689 14.1356 30.6298C14.1356 27.6405 11.5729 24.1979 13.6035 20.7137C14.4514 19.2587 13.9614 16.769 13.3314 14.2986Z"
                fill={leftFill}
                fillOpacity="0.3"
                stroke={`url(#gradient1_${instanceId})`}
            />
            <path
                d="M26.6686 14.2986C27.4685 11.1625 27.2164 8.33201 27.1982 5.54122C27.1971 5.37553 27.0628 5.24121 26.8972 5.24121H21.4665C21.3008 5.24121 21.1666 5.37551 21.1651 5.54118C21.1473 7.44314 20.974 9.37396 21.1665 11.7412C21.4031 14.6515 21.2249 14.1052 22.2216 17.598C22.8748 19.8874 21.6011 21.3226 21.7327 23.4753C21.9366 26.8104 22.9207 30.4819 23.0627 31.0108C23.2047 31.5396 22.6975 31.5863 22.9266 32.1635C23.065 32.5119 22.1525 33.4233 22.2216 34.1403C22.2424 34.3561 22.3603 34.5489 22.4424 34.6597C22.4911 34.7254 22.5697 34.7585 22.6515 34.7585H26.6348C26.6435 34.7585 26.652 34.7589 26.6607 34.759C26.7434 34.7606 27.1969 34.7389 27.1969 34.0691C27.1969 33.75 26.8108 33.5788 26.6478 33.1973C26.4566 32.7498 26.4901 32.617 26.3139 32.1635C26.0632 31.518 25.8644 31.6689 25.8644 30.6298C25.8644 27.6405 28.4271 24.1979 26.3965 20.7137C25.5486 19.2587 26.0386 16.769 26.6686 14.2986Z"
                fill={rightFill}
                fillOpacity="0.3"
                stroke={`url(#gradient2_${instanceId})`}
            />

            <path
                d="M14.7148 21.1055L14.8671 21.177C15.4149 21.4343 16.0497 21.4297 16.5937 21.1645L16.7148 21.1055"
                stroke={leftFill}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
            <path
                d="M15.3477 17.9336L15.3585 17.9245C15.6454 17.6835 16.0651 17.6874 16.3477 17.9336V17.9336"
                stroke={leftFill}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
            <path
                d="M23.6621 17.9336L23.6729 17.9245C23.9599 17.6835 24.3796 17.6874 24.6621 17.9336V17.9336"
                stroke={rightFill}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
            <path
                d="M23.3125 21.1055L23.4648 21.177C24.0126 21.4343 24.6474 21.4297 25.1913 21.1645L25.3125 21.1055"
                stroke={rightFill}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
        </svg>
    );
}
