import React from 'react';

// Assuming you'll create a CircleSvg component separately
import CircleSvg from './CircleSvg';
import {score_colors} from 'screen/analysis/AnalysisListCard/constants/analysisListConstants';

export default function FootBVRightSvg({angle, rotation, score}) {
  if (!score || angle === undefined) return null;

  // Generate a unique identifier for this instance if not provided
  const instanceId = `svg_${Math.random().toString(36).substr(2, 9)}`;

  const centerX = 75;
  const centerY = 100;
  const Bx = centerX;
  const By = centerY;
  const L = 70;
  const L2 = 25;

  // Convert both angles to radians
  const angleRad = ((180 - angle) * Math.PI) / 180;
  const rotationRad = (-rotation * Math.PI) / 180;

  // Segment BA (horizontal with rotation)
  const Ax = Bx + L * Math.cos(rotationRad);
  const Ay = By + L * Math.sin(rotationRad);

  // Segment BC (angle + rotation)
  const Cx = Bx + L2 * Math.cos(angleRad + rotationRad);
  const Cy = By + L2 * Math.sin(angleRad + rotationRad);

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 150 150"
      preserveAspectRatio="xMidYMid meet">
      {angle > 10 ? (
        <g
          transform={`translate(${Cx}, ${Cy}) rotate(${-rotation}) translate(-6, -13) scale(0.9)`}>
          <path
            d="M22.0618 34.6419C17.4884 33.3244 18.6518 28.6938 6.76905 29.3928C-6.27565 30.1602 6.51836 4.94447 9.09914 2.36368C14.2254 -2.76254 16.3757 8.4381 19.5848 5.39279C22.794 2.34747 23.7375 2.5378 28.0793 1.58614C32.421 0.634476 36.3465 4.0827 35.8218 7.10578C33.0138 23.284 43.8551 7.72967 31.4687 27.9947C29.6075 31.0399 26.6352 35.9594 22.0618 34.6419Z"
            fill={`url(#paint0_linear_1285_71104_${instanceId})`}
            stroke={`url(#paint1_linear_1285_71104_${instanceId})`}
            strokeWidth={1.5}
          />
        </g>
      ) : angle < -10 ? (
        <g
          transform={`translate(${Cx}, ${Cy}) rotate(${-rotation}) translate(-6, -21) scale(0.9)`}>
          <path
            d="M18.652 41.6105C16.0092 40.8492 11.517 39.0778 6.64057 39.0778C2.19666 39.0778 -1.06659 13.6974 1.51419 11.1166C6.64041 5.99043 13.4839 7.15088 17.3854 5.06496C21.32 2.9613 22.8037 2.04888 27.1455 1.09722C31.4873 0.145561 34.854 3.39851 34.6024 6.45644C33.203 23.4662 39.5328 17.7274 27.1464 37.9925C25.2852 41.0376 21.2948 42.3718 18.652 41.6105Z"
            fill={`url(#paint0_linear_1285_71085_${instanceId})`}
            stroke={`url(#paint1_linear_1285_71085_${instanceId})`}
            strokeWidth={1.5}
          />
        </g>
      ) : (
        <g
          transform={`translate(${Cx}, ${Cy}) rotate(${-rotation}) translate(-8, -23) scale(0.9)`}>
          <path
            d="M16.9287 46.0128C14.2859 45.2515 8.43352 39.3513 5.03561 37.448C-2.51493 26.9797 1.7389 14.085 4.31969 11.5042C9.4459 6.37799 13.7196 7.56581 16.9287 4.5205C20.1379 1.47518 21.0813 1.66551 25.4231 0.713852C29.7649 -0.237812 33.6903 3.21041 33.1656 6.23349C30.3577 22.4117 37.8095 22.1298 25.4231 42.3948C23.5619 45.44 19.5715 46.7742 16.9287 46.0128Z"
            fill={`url(#paint0_linear_1285_71052_${instanceId})`}
            stroke={`url(#paint1_linear_1285_71052_${instanceId})`}
            strokeWidth={1.5}
          />
        </g>
      )}
      <g
        transform={`translate(${Bx}, ${By}) rotate(${-rotation}) translate(-40, -42) scale(0.9)`}>
        <path
          d="M211.338 28.8498C213.878 29.3354 221.509 31.1165 224.628 32.3581C237.207 37.365 257.371 42.2952 254.039 64.8948C252.305 76.6575 233.378 82.827 222.782 82.5445C212.186 82.2621 210.941 80.6688 194.903 76.4416C178.864 72.2144 153.535 72.0206 143.829 71.1338C134.123 70.2469 119.356 66.4565 105.898 62.916C92.4387 59.3754 77.5102 58.4586 71.9498 58.1704C66.3894 57.8823 53.4251 62.5334 50.5937 62.4151C45.0227 62.1823 39.0893 60.6153 35.0511 60.4244C26.5167 60.021 22.2995 51.3919 22.6234 45.6447C22.9551 39.758 23.9366 31.2566 34.7195 31.5747C40.5884 31.7479 45.6626 28.9367 49.6807 30.0395C55.6083 31.6663 61.4239 31.1795 69.0309 30.8906C76.638 30.6018 112.596 21.7896 136.477 18.4148C160.359 15.04 180.648 21.2414 190.228 24.815C199.808 28.3887 208.162 28.2428 211.338 28.8498Z"
          fill={`url(#paint4_linear_1276_70642_${instanceId})`}
          stroke={`url(#paint5_linear_1276_70642_${instanceId})`}
          strokeWidth={1.5}
        />
      </g>
      <g
        transform={`translate(${Cx}, ${Cy}) rotate(${
          -rotation - angle
        }) translate(-15, -43) scale(0.9)`}>
        <path
          d="M14.702 62.3551C9.00393 61.777 8.05354 53.2975 7.8636 45.7816C7.8636 34.4114 11.2126 31.8157 14.702 31.5206C19.2605 31.1352 22.1096 31.9703 26.6681 32.4842C31.7964 33.0623 37.6845 30.9425 45.092 29.9789C52.4996 29.0153 61.8058 32.8696 63.8951 41.1564C65.9844 49.4432 58.3869 56.9591 54.3982 58.6935C50.4096 60.428 40.1529 61.1988 35.0246 61.0061C29.8963 60.8134 20.4002 62.9333 14.702 62.3551Z"
          fill={`url(#paint2_linear_1276_70642_${instanceId})`}
          stroke={`url(#paint3_linear_1276_70642_${instanceId})`}
          strokeWidth={1.5}
        />
      </g>

      <path
        d={`M ${Bx},${By} L ${Ax},${Ay}`}
        stroke={score_colors[score]?.stroke}
        strokeWidth={2}
      />
      <path
        d={`M ${Bx},${By} L ${Cx},${Cy}`}
        stroke={score_colors[score]?.stroke}
        strokeWidth={2}
      />

      {/* Circle points */}
      <CircleSvg x={Ax} y={Ay} score={score} r={5} r2={2.5} />
      <CircleSvg x={Bx} y={By} score={score} r={5} r2={2.5} />
      <CircleSvg x={Cx} y={Cy} score={score} r={5} r2={2.5} />

      {/* Definitions for the gradients - make IDs unique for this instance */}
      <defs>
        <linearGradient
          id={`paint4_linear_1276_70642_${instanceId}`}
          x1={147.298}
          y1={50.3966}
          x2={21.7705}
          y2={43.7157}
          gradientUnits="userSpaceOnUse">
          <stop
            offset={0.168406}
            stopColor={score_colors[score]?.svgLinear}
            stopOpacity={0}
          />
          <stop offset={0.626969} stopColor={score_colors[score]?.svgLinear} />
          <stop offset={0.849892} stopColor={score_colors[score]?.svgLinear} />
          <stop
            offset={0.943155}
            stopColor={score_colors[score]?.svgLinear}
            stopOpacity={0}
          />
        </linearGradient>
        <linearGradient
          id={`paint5_linear_1276_70642_${instanceId}`}
          x1={24.6088}
          y1={41.7301}
          x2={137.425}
          y2={46.7829}
          gradientUnits="userSpaceOnUse">
          <stop
            offset={0.106944}
            stopColor={score_colors[score]?.stroke}
            stopOpacity={0}
          />
          <stop offset={0.215561} stopColor={score_colors[score]?.stroke} />
          <stop offset={0.394506} stopColor={score_colors[score]?.stroke} />
          <stop
            offset={0.903966}
            stopColor={score_colors[score]?.stroke}
            stopOpacity={0}
          />
        </linearGradient>
        <linearGradient
          id={`paint2_linear_1276_70642_${instanceId}`}
          x1={49.3393}
          y1={49.6398}
          x2={6.69855}
          y2={50.1058}
          gradientUnits="userSpaceOnUse">
          <stop
            offset={0.0601391}
            stopColor={score_colors[score]?.svgLinear}
            stopOpacity={0}
          />
          <stop offset={0.212164} stopColor={score_colors[score]?.svgLinear} />
          <stop offset={0.990114} stopColor={score_colors[score]?.svgLinear} />
        </linearGradient>
        <linearGradient
          id={`paint3_linear_1276_70642_${instanceId}`}
          x1={7.8636}
          y1={47.0767}
          x2={48.4073}
          y2={46.1447}
          gradientUnits="userSpaceOnUse">
          <stop stopColor={score_colors[score]?.stroke} />
          <stop offset={0.704759} stopColor={score_colors[score]?.stroke} />
          <stop
            offset={1}
            stopColor={score_colors[score]?.stroke}
            stopOpacity={0}
          />
        </linearGradient>

        <linearGradient
          id={`paint0_linear_1285_71085_${instanceId}`}
          x1={37.6662}
          y1={23.2331}
          x2={-2.52649}
          y2={18.9645}
          gradientUnits="userSpaceOnUse">
          <stop
            offset={0.103617}
            stopColor={score_colors[score]?.svgLinear}
            stopOpacity={0}
          />
          <stop offset={0.756263} stopColor={score_colors[score]?.svgLinear} />
          <stop offset={0.983165} stopColor={score_colors[score]?.svgLinear} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_1285_71085_${instanceId}`}
          x1={-2.53439}
          y1={19.971}
          x2={40.701}
          y2={27.3203}
          gradientUnits="userSpaceOnUse">
          <stop stopColor={score_colors[score]?.stroke} />
          <stop offset={0.204192} stopColor={score_colors[score]?.stroke} />
          <stop
            offset={0.884931}
            stopColor={score_colors[score]?.stroke}
            stopOpacity={0}
          />
        </linearGradient>

        <linearGradient
          id={`paint0_linear_1285_71052_${instanceId}`}
          x1={37.2094}
          y1={22.6887}
          x2={-2.98329}
          y2={18.4201}
          gradientUnits="userSpaceOnUse">
          <stop
            offset={0.103617}
            stopColor={score_colors[score]?.svgLinear}
            stopOpacity={0}
          />
          <stop offset={0.756263} stopColor={score_colors[score]?.svgLinear} />
          <stop offset={0.983165} stopColor={score_colors[score]?.svgLinear} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_1285_71052_${instanceId}`}
          x1={-2.99119}
          y1={19.4265}
          x2={40.2442}
          y2={26.7758}
          gradientUnits="userSpaceOnUse">
          <stop stopColor={score_colors[score]?.stroke} />
          <stop offset={0.204192} stopColor={score_colors[score]?.stroke} />
          <stop
            offset={0.884931}
            stopColor={score_colors[score]?.stroke}
            stopOpacity={0}
          />
        </linearGradient>

        <linearGradient
          id={`paint0_linear_1285_71104_${instanceId}`}
          x1={41.9897}
          y1={13.5481}
          x2={1.79695}
          y2={9.27949}
          gradientUnits="userSpaceOnUse">
          <stop
            offset={0.103617}
            stopColor={score_colors[score]?.svgLinear}
            stopOpacity={0}
          />
          <stop offset={0.756263} stopColor={score_colors[score]?.svgLinear} />
          <stop offset={0.983165} stopColor={score_colors[score]?.svgLinear} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_1285_71104_${instanceId}`}
          x1={1.78904}
          y1={10.286}
          x2={45.0245}
          y2={17.6353}
          gradientUnits="userSpaceOnUse">
          <stop stopColor={score_colors[score]?.stroke} />
          <stop offset={0.204192} stopColor={score_colors[score]?.stroke} />
          <stop
            offset={0.884931}
            stopColor={score_colors[score]?.stroke}
            stopOpacity={0}
          />
        </linearGradient>
      </defs>
    </svg>
  );
}
