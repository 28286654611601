import React from 'react';
import CircleSvg from './CircleSvg';
import {colors} from 'themes';
import {score_colors} from 'screen/analysis/AnalysisListCard/constants/analysisListConstants';
import {SCORE_GOOD} from 'constant/constants';

export default function TrunkSvg({angle, rotation, score}) {
  if (!score || angle === undefined) return null;

  // Generate a unique identifier for this instance
  const instanceId = `trunk_${Math.random().toString(36).substr(2, 9)}`;

  const centerX = 100;
  const centerY = 230;
  const Bx = centerX;
  const By = centerY;
  const By2 = centerY + 30;
  const L = 120;
  const L_extended = 140; // For the dashed line

  // Convert both angles to radians
  const angleRad = (angle * Math.PI) / 180;
  const rotationRad = (-rotation * Math.PI) / 180;

  // Segment BA (horizontal with rotation) - now from center
  const Ax = Bx + L_extended * Math.cos(rotationRad);
  const Ay = By + L_extended * Math.sin(rotationRad);

  // Segment BC (angle + rotation)
  const Cx = Bx + L * Math.cos(angleRad + rotationRad);
  const Cy = By + L * Math.sin(angleRad + rotationRad);

  const radius = 80; // Reduced to ensure it fits within viewBox
  const startX = Bx + radius * Math.cos(rotationRad);
  const startY = By + radius * Math.sin(rotationRad);
  const endX = Bx + radius * Math.cos(angleRad + rotationRad);
  const endY = By + radius * Math.sin(angleRad + rotationRad);

  const largeArcFlag = angle > 180 ? 1 : 0;

  const sectorPath = `
    M ${Bx} ${By}
    L ${startX} ${startY}
    A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}
    L ${Bx} ${By}
    Z
  `;

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 200 300"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient
          id={`paint0_linear_1255_73215_${instanceId}`}
          x1="224.23"
          y1="46.6715"
          x2="143.975"
          y2="46.6715"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#1C2018" />
          <stop offset="0.653769" stopColor="#1C2018" />
          <stop offset="0.893625" stopColor="#1C2018" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_1255_73215_${instanceId}`}
          x1="202.088"
          y1="46.7411"
          x2="143.975"
          y2="48.5474"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2A381C" />
          <stop offset="0.515236" stopColor="#2A381C" />
          <stop offset="0.700444" stopColor="#2A381C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id={`paint2_linear_1255_73215_${instanceId}`}
          x1="177.213"
          y1="44.5069"
          x2="232.585"
          y2="75.7117"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.0752893" stopColor="#2A381C" stopOpacity="0" />
          <stop offset="0.215561" stopColor="#2A381C" />
          <stop offset="1" stopColor="#2A381C" />
        </linearGradient>
        <linearGradient
          id={`paint3_linear_1255_73215_${instanceId}`}
          x1="201.366"
          y1="38.5606"
          x2="0.647511"
          y2="55.4925"
          gradientUnits="userSpaceOnUse">
          <stop
            offset="0.0640923"
            stopColor={score_colors[score]?.svgLinear}
            stopOpacity="0"
          />
          <stop
            offset="0.193459"
            stopOpacity="0.8"
            stopColor={score_colors[score]?.svgLinear}
          />
          <stop
            offset="0.812632"
            stopOpacity="0.8"
            stopColor={score_colors[score]?.svgLinear}
          />
          <stop
            offset="0.962687"
            stopColor={score_colors[score]?.svgLinear}
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id={`paint4_linear_1255_73215_${instanceId}`}
          x1="175.521"
          y1="38.3715"
          x2="6.73703"
          y2="64.8903"
          gradientUnits="userSpaceOnUse">
          <stop
            offset="0.0377271"
            stopColor={score_colors[score]?.stroke}
            stopOpacity="0"
          />
          <stop offset="0.129958" stopColor={score_colors[score]?.stroke} />
          <stop offset="0.691754" stopColor={score_colors[score]?.stroke} />
          <stop
            offset="0.88745"
            stopColor={score_colors[score]?.stroke}
            stopOpacity="0"
          />
        </linearGradient>
      </defs>

      {/* Trunk shape - centered and properly positioned */}
      <g
        transform={`translate(${centerX}, ${centerY}) rotate(${
          angle - rotation
        }) translate(-50, -50) scale(1)`}>
        <path
          d="M192.098 33.6966C196.868 32.7426 200.25 29.6465 205.046 25.8089C219.078 17.2941 230.352 32.8844 220.517 49.195C213.581 60.6998 198.222 60.7331 192.585 59.7736C186.949 58.8142 180.473 58.4544 177.714 58.5744C174.956 58.6943 173.408 61.3499 169.936 65.0324C160.249 75.3064 153.238 69.1028 149.4 66.4644C145.562 63.826 141.365 56.2705 146.042 38.0414C150.719 19.8123 161.068 17.0987 169.936 25.0373C177.229 31.5661 187.509 34.6144 192.098 33.6966Z"
          fill={`url(#paint0_linear_1255_73215_${instanceId})`}
          stroke={`url(#paint1_linear_1255_73215_${instanceId})`}
        />
        <path
          d="M214.826 19.8326C209.689 21.1403 207.11 23.6734 205.304 28.2036C203.007 33.9667 203.322 38.9453 203.13 41.2425C202.939 43.5397 203.404 44.4522 201.745 45.7284C200.086 47.0047 190.876 50.3219 189.11 54.343C188.518 55.6919 186.433 69.6185 186.178 70.6395C185.922 71.6605 186.112 74.2871 190.259 75.1456C195.191 76.1666 202.102 76.5012 207.297 76.1666C207.809 77.3878 207.711 77.7164 208.796 79.2478C209.881 80.7793 211.962 81.0242 212.639 80.3211C213.596 79.3269 220.09 72.6229 221.111 72.1762C222.132 71.7295 225.515 73.064 226.983 73.383C228.451 73.7021 230.071 74.3508 238.291 68.7468C246.51 63.1428 247.257 55.5774 247.724 45.6771C248.191 35.7767 244.739 28.255 237.734 22.9313C230.729 17.6075 219.964 18.525 214.826 19.8326Z"
          fill="#1C2018"
          stroke={`url(#paint2_linear_1255_73215_${instanceId})`}
        />
        <path
          d="M30.2884 74.8328C14.7789 76.3794 8.91196 67.4384 6.02675 59.7988C2.81007 51.2815 1.74546 38.8756 12.6114 25.658C17.7353 19.4252 39.6121 10.661 53.4526 14.2957C67.2929 17.9302 82.6756 19.8069 96.148 19.9849C111.69 20.1902 122.301 14.0563 147.288 14.8851C163.149 15.4113 168.735 26.2036 171.65 30.0087C174.565 33.8137 176.839 48.7995 176.084 54.9208C175.329 61.0421 165.239 71.3932 159.155 74.9254C153.071 78.4576 148.786 85.2076 127.632 87.3171C115.465 88.5304 113.571 84.0885 108.879 83.9713C104.186 83.8541 90.7034 81.6904 83.4818 79.6801C76.2601 77.6698 65.7639 74.8437 56.6023 74.0161C48.0957 73.2477 45.7979 73.2862 30.2884 74.8328Z"
          fill={`url(#paint3_linear_1255_73215_${instanceId})`}
          stroke={`url(#paint4_linear_1255_73215_${instanceId})`}
          strokeDasharray={score === SCORE_GOOD ? '0' : '7'}
          strokeWidth="2"
        />
      </g>

      {/* Angular guides and indicators */}
      <path
        d={`M ${Bx},${By2} L ${Ax},${Ay}`}
        stroke={colors.WHITE}
        strokeWidth="2"
        strokeDasharray="10"
      />
      <path
        d={`M ${Bx},${By} L ${Cx},${Cy}`}
        stroke={score_colors[score]?.stroke}
        strokeWidth="2"
      />
      <path
        d={sectorPath}
        fill={score_colors[score]?.stroke}
        fillOpacity="0.4"
        stroke={score_colors[score]?.stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <CircleSvg x={Bx} y={By} score={score} />
      <CircleSvg x={Cx} y={Cy} score={score} />
    </svg>
  );
}
