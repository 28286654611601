import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { useNavigate } from 'react-router-dom';

import TextComponent from 'components/atoms/TextComponent';
import OnBoardingContainer from '../components/OnBoardingContainer';
import OnBoardingTemplate from '../components/OnBoardingTemplate';
import ButtonComponent from 'components/atoms/ButtonComponent';

import {
  USER_LOCALE,
  onBoardingTranslate,
  translate,
} from 'services/remoteConfig';
import { updateProfile } from 'services/userApi';
import { mpTrack } from 'services/mixpanel';

import { updateProfileStore } from 'slices/userSlice';

import { functions } from 'utils/firebaseInit';
import { checkUserPermission, createTextObjectReusable } from 'utils/utils';

import {
  BOLD,
  COACH,
  GREEN,
  INDEPENDANT,
  PROFESSIONAL,
  REGISTRATION_DATA,
  RETAIL_STORE,
} from 'constant/constants';

import { colors } from 'themes';
import { ReactComponent as Trophy } from 'assets/svg/bottomBarScore.svg';

function OnBoardingFinished() {
  const userData = JSON.parse(localStorage.getItem(REGISTRATION_DATA));
  const userSlice = useSelector(state => state?.user);
  const usageSlice = useSelector(state => state.usage);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function onGetStartedPress() {
    updateProfile({ registered: true });
    dispatch(updateProfileStore({ registered: true }));
    mpTrack('Web App Onboarding Completed');
    navigate('/');
  }

  useEffect(() => {
    mpTrack('Web App OnBoarding Profile Creation Screen Viewed');
  }, []);

  useEffect(() => {
    if (!userData) return;
    updateTracking();
  }, [userData]);

  async function updateTracking() {
    const nextWeek = new Date();
    nextWeek.setDate(nextWeek.getDate() + 7);
    const user = getAuth().currentUser;
    const defaultProfile = {
      settings: {
        selectedLanguage: USER_LOCALE,
      },
    };

    let updatedProfile = {};

    if (userData?.userType === COACH) {
      updatedProfile = {
        type: userData?.userType || 'coach',
        typology: userData?.typology || 'PersonnalCoach',
        specialization: userData?.specialization || [],
      };
    } else if (userData?.userType === PROFESSIONAL) {
      updatedProfile = {
        type: userData?.userType || 'professional',
        specialization: userData?.specialization || [],
        typology: userData?.typology || '',
      };
    } else if (userData?.userType === RETAIL_STORE) {
      updatedProfile = {
        type: userData?.userType || RETAIL_STORE,
        typology: userData?.typology || INDEPENDANT,
        specialization: userData?.specialization || [],
        analysisExperience: userData?.analysisExperience || 'no',
      };
    } else {
      updatedProfile = {
        gender: userData?.gender || 'man',
        type: userData?.userType || 'athlete',
        typology: userData?.typology || 'performer',
        level: userData?.level || 'beginner',
        timePerWeek: userData?.timePerWeek ? userData?.timePerWeek : 3,
        height: userData?.height || 160,
        weight: userData?.weight || 60,
        runningDate: userData?.runningDate || '',
      };
    }

    const finalProfile = { ...defaultProfile, ...updatedProfile };

    updateProfile(finalProfile);
    if (usageSlice?.monthlyPlan === false && usageSlice?.yearlyPlan === false) {
      const callInitProfileTypeUsage = httpsCallable(
        functions,
        'initProfileTypeUsage',
      );
      try {
        const result = await callInitProfileTypeUsage({
          hubspotId: userSlice?.hubspotId,
          userType: userData?.userType ? userData?.userType : 'athlete',
          userId: user.uid,
          freeTrialTimeStamp: nextWeek.toISOString(),
        });
        return result.data;
      } catch (error) {
        console.error('Error calling cloud function: ', error);
        throw error;
      }
    }
  }

  return (
    <OnBoardingContainer previous radialGradient>
      <OnBoardingTemplate>
        <div style={styles.trophy}>
          <Trophy height={25} width={25} fill={colors.GREEN} />
        </div>

        <TextComponent
          multipleText={createTextObjectReusable(
            onBoardingTranslate('WelcomeOnBoard'),
            {
              weight: BOLD,
              style: {
                textAlign: 'center',
                color: colors?.WHITE,
                fontSize: '3vw',
              },
            },
            {
              weight: BOLD,
              style: {
                textAlign: 'center',
                color: colors.SECONDARY,
                fontSize: '5vw',
              },
            },
          )}
          center
        />

        <TextComponent
          text={onBoardingTranslate('WelcomeOnBoardSecond')}
          sx={{ marginTop: '5vh', textAlign: 'center', fontSize: '1.5vw' }}
        />

        <TextComponent
          multipleText={createTextObjectReusable(
            checkUserPermission([COACH], true)
              ? onBoardingTranslate('WelcomeOnBoardFreeTrialCoach')
              : onBoardingTranslate('WelcomeOnBoardFreeTrialPro'),
            {
              style: {
                textAlign: 'center',
                color: colors?.WHITE,
                fontSize: '1.5vw',
              },
            },
            {
              weight: BOLD,
              style: {
                textAlign: 'center',
                color: colors.SECONDARY,
                fontSize: '1.5vw',
              },
            },
            {
              weight: BOLD,
              style: {
                textAlign: 'center',
                color: colors.VALID_IMAGE,
                fontSize: '1.5vw',
              },
            },
          )}
          center
        />

        <TextComponent
          text={
            checkUserPermission([COACH], true)
              ? onBoardingTranslate('ReadyToDiveIn')
              : ''
          }
          sx={{ marginTop: '5vh', textAlign: 'center', fontSize: '1.5vw' }}
        />

        <ButtonComponent
          title={translate('GetStarted')}
          type={GREEN}
          onClick={onGetStartedPress}
          sx={{
            width: '15vw',
            height: '6vh',
            alignSelf: 'center',
            marginTop: '5vh',
          }}
        />
      </OnBoardingTemplate>
    </OnBoardingContainer>
  );
}
const styles = {
  trophy: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.GREEN_15_HEX,
    border: '2px solid rgba(255, 255, 255, 0.15)',
    borderRadius: '40px',
    boxShadow: '0 5px 10px rgba(0, 0, 0, 0.5)',
    height: '50px',
    width: '50px',
    marginBottom: '30px',
    position: 'relative',
  },
};

export default OnBoardingFinished;
