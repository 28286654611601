import React from "react";

import TextComponent from "components/atoms/TextComponent";
import { translate } from "services/remoteConfig";
import { colors } from "themes";
import {
  SUB_STATUS_INCOMPLETE_EXPIRED,
  SUB_STATUS_PAST_DUE,
} from "constant/constants";

export default function SubscriptionInformationPanelIncomplete({ status }) {
  if (!status) return <></>;
  if (status === SUB_STATUS_PAST_DUE)
    return (
      <div style={styles.container}>
        <div style={styles.subcontainer}>
          <TextComponent
            variant="h6"
            text={translate("ResubscriptionPaymentError")}
            sx={styles.mainText}
          />
          <TextComponent
            variant="body1"
            text={translate("ResubscriptionIncentive")}
            sx={styles.subText}
          />
        </div>
      </div>
    );
  if (status === SUB_STATUS_INCOMPLETE_EXPIRED)
    return (
      <div style={styles.container}>
        <div style={styles.subcontainer}>
          <TextComponent
            variant="h6"
            text={translate("SubscriptionError")}
            sx={styles.mainText}
          />
          <TextComponent
            variant="body1"
            text={translate("TryAgainOrContactSupport")}
            sx={styles.subText}
          />
        </div>
      </div>
    );
  return (
    <div style={styles.container}>
      <div style={styles.subcontainer}>
        <TextComponent
          variant="h6"
          text={translate("SubscriptionUnderActivation")}
          sx={styles.mainText}
        />
        <TextComponent
          variant="body1"
          text={translate("MayTakeMinuts")}
          sx={styles.subText}
        />
      </div>
    </div>
  );
}

const styles = {
  container: {
    flex: 5,
    display: "flex",
    marginTop: 20,
    justifyContent: "space-around",
    padding: 10,
  },
  mainText: {
    textAlign: "center",
    marginTop: 2,
    fontWeight: "bold",
    alignSelf: "center",
  },
  subcontainer: {
    flexDirection: "row",
  },
  subText: {
    textAlign: "center",
    marginTop: 2,
    alignSelf: "center",
    marginBottom: 10,
    color: colors.WHITE_OPACITY_60,
  },
};
