import HeightComponent from "components/atoms/HeightComponent";
import TextComponent from "components/atoms/TextComponent";
import WeightComponent from "components/atoms/WeightComponent";
import { BODY_1, BODY_2 } from "constant/constants";
import React, { useContext } from "react";
import { colors } from "themes";
import { ReactComponent as Arrow } from "assets/svg/arrow-right-bold.svg";
import "./styles/AthleteCard.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectAthleteSelected, setAthleteSelected } from "slices/athleteSlice";
function PatientCard({ item }) {
  const athleteSlice = useSelector(selectAthleteSelected);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const search = useSelector((state) => state.athleteSelected.search);

  if (!item?.name?.toLowerCase().includes(search?.toLowerCase())) {
    return null
  }

  return (
    <div
      className="athlete-card-container"
      onClick={() => {
        dispatch(setAthleteSelected(item))
        navigate(`/analysis-athlete/${item?.name}`)
      }}

      style={
        athleteSlice?.id === item.id ? { backgroundColor: "#38393c" } : null,
        {
          marginBottom: '1vh'
        }
      }
    >
      <div
        style={{
          width: "70%",
          marginLeft: 15,
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <TextComponent
          sx={{ margin: 0, textTransform: "capitalize", marginBottom: 0.5 }}
          text={item?.name}
          variant={BODY_1}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <HeightComponent height={item?.height} />
          <WeightComponent weight={item?.weight} />
        </div>
      </div>
      <div
        style={{
          width: "30%",
          alignContent: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Arrow height={20} width={20} />
      </div>
    </div>
  );
}

export default PatientCard;
