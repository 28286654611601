import React, { forwardRef, useImperativeHandle, useState } from "react";

import { Modal } from "@mui/material";

import { ReactComponent as Close } from "assets/svg/close.svg";

import { colors } from "themes";

const ModalComponent = forwardRef(({ children, style, blur = false }, ref) => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState(null);
  const [editField, setEditField] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    getOpen() {
      return open;
    },
    open() {
      setOpen(true);
    },
    setClose() {
      setOpen(false);
    },
    setContent(newContent) {
      setContent(newContent);
    },
    getEditField() {
      return editField;
    },
    setEditField(newEditField) {
      setEditField(newEditField);
    },
  }));

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          backdropFilter: blur ? "blur(8px)" : "",
        },
      }}
    // aria-labelledby="modal-modal-title"
    // aria-describedby="modal-modal-description"
    >
      <div
        style={
          {
            ...{
              backgroundColor: colors.BACKGROUND,
              width: "40%",
              minHeight: "40%",
              position: "relative",
              borderRadius: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }, ...style
          }}
      >
        <div
          style={{
            height: 50,
            width: 50,
            cursor: "pointer",
            position: "absolute",
            top: 0,
            right: 0,
          }}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            handleClose()
          }}
        >
          <Close stroke="white" style={{ height: 50, width: 50 }} />
        </div>
        {content || children}
      </div>
    </Modal >
  );
});

export default ModalComponent;
