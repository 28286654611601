import ButtonComponent from "components/atoms/ButtonComponent";
import TextComponent from "components/atoms/TextComponent";
import { BODY_2, H5, ORANGE } from "constant/constants";
import React from "react";
import { useNavigate } from "react-router-dom";
import { translate } from "services/remoteConfig";
import { isPro } from "utils/utils";

import { ReactComponent as CrookedArrow } from "assets/svg/crookedArrow.svg";

function NoAthlete({ description, noButton, upload = false }) {
  const navigate = useNavigate();
  return (
    <>
      {upload && <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%' }}>
        <CrookedArrow style={{ height: '15vh', }} />
      </div>}
      <TextComponent
        text={
          isPro() ? translate("NoPatientWebApp") : translate("NoAthleteWebApp")
        }
        variant={H5}
      />
      {description ? (
        <TextComponent
          text={translate("PersonalizedAnalysisExplanation")}
          sx={styles.description}
          variant={BODY_2}
        />
      ) : null}
      {noButton ? null : (
        <div style={styles.buttonContainer}>
          <ButtonComponent
            title={isPro() ? translate("AddPatients") : translate("AddAthlete")}
            type={ORANGE}
            onClick={() => {
              navigate("/add-athlete");
            }}
          />
        </div>
      )}
    </>
  );
}

const styles = {
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "3%",
    width: "100%",
  },
  description: {
    color: "#737373",
    marginBottom: 3,
  },
};

export default NoAthlete;
