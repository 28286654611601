import React from "react";

import TextComponent from "components/atoms/TextComponent";

import { translate } from "services/remoteConfig";

import { colors } from "themes";

export default function ProductsSwitchButton({ selected, setSelected }) {
  function handleSwitch() {
    setSelected(!selected);
  }
  return (
    <div style={styles.wrapper}>
      <div style={styles.buttonsContainer}>
        <div
          onClick={handleSwitch}
          style={{
            ...styles.textContainer,
            ...{ backgroundColor: selected ? colors.SELECTED : null },
          }}
        >
          <TextComponent
            text={translate("Yearly")}
            sx={{
              color: selected ? colors.WHITE : colors.TEXT_GRAY,
            }}
          />
        </div>
        <div
          onClick={handleSwitch}
          style={{
            ...styles.textContainer,
            ...{ backgroundColor: !selected ? colors.SELECTED : null },
          }}
        >
          <TextComponent
            text={translate("Monthly")}
            sx={{
              color: !selected ? colors.WHITE : colors.TEXT_GRAY,
            }}
          />
        </div>
      </div>
    </div>
  );
}

const styles = {
  buttonsContainer: {
    width: "25%",
    height: "4vh",
    backgroundColor: colors.HOVER,
    margin: "auto",
    borderRadius: 10,
    border: " 2px solid #38393C",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textContainer: {
    width: "49.5%",
    height: "90%",
    borderRadius: 7,
    transition: "all .3s",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  wrapper: {
    backgroundColor: colors.BACKGROUND,
    marginBottom: 20,
    marginTop: "5%",
  },
};
