import React from "react";
import TextComponent from "components/atoms/TextComponent";
import { H3 } from "constant/constants";
import PatientCard from "./PatientCard";
import AthleteCard from "./AthleteCard";
import { useSelector } from "react-redux";
import { selectListItemsData } from "slices/userItemsSlice";
import NoAthlete from "components/template/NoAthlete";

function AthletesListNoPending() {
  const userListData = useSelector(selectListItemsData);
  const copyOfUserListData = userListData ? [...userListData] : [];

  const search = useSelector((state) => state.athleteSelected.search);

  const sortData = (data) => {
    if (data?.length === 0) return;
    data?.sort((a, b) => {
      const nameA = (a?.name || a?.fullName)?.toLowerCase();
      const nameB = (b?.name || b?.fullName)?.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  };

  const groupData = (data) => {
    if (data?.length === 0) return;
    const groupedData = {};
    data?.forEach((item) => {
      const name = item?.name || item?.fullName;
      const firstLetter = name?.charAt(0)?.toUpperCase();
      if (groupedData[firstLetter]) {
        groupedData[firstLetter].push(item);
      } else {
        groupedData[firstLetter] = [item];
      }
    });

    return groupedData;
  };

  function showSortedAthelete() {
    if (copyOfUserListData?.length === 0)
      return (
        <div style={{ marginLeft: "1.5%", marginTop: "2%" }}>
          <NoAthlete noButton={true} />
        </div>
      );
    sortData(copyOfUserListData);
    const groupedData = groupData(copyOfUserListData);
    return (
      <div style={{ marginTop: "2%", marginBottom: '1%' }}>
        {Object.entries(groupedData).map(([letter, names]) => (
          <div key={letter} style={{ width: "98%", margin: "auto" }}>
            {search === '' && <TextComponent
              variant={H3}
              text={letter}
              sx={{
                marginBottom: 3,
                marginLeft: 3,
                marginTop: 2,
                fontWeight: "bold",
              }}
            />}
            <div
              style={{
                display: "grid",
                alignItems: "center",
                gridTemplateColumns: "repeat(auto-fit, minmax(10rem, 33rem))",
                gridGap: 10,
              }}
            >
              {names.map((item, index) =>
                item?.type === "patient" ? (
                  <PatientCard key={index} item={item} />
                ) : (
                  <AthleteCard key={index} item={item} />
                )
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }
  return <>{showSortedAthelete()}</>;
}

export default AthletesListNoPending;
