import BlackTextInput from 'components/atoms/BlackTextInput';
import LinedTextComponent from 'components/atoms/LinedTextComponent';
import TextComponent from 'components/atoms/TextComponent';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { translate, USER_LOCALE } from 'services/remoteConfig';
import SsoButtons from '../components/SsoButtons';
import { mpIdentify, mpTrack } from 'services/mixpanel';
import {
  createUser,
  createUserInDBAndAddUsages,
} from 'services/authApi';
import { colors, images } from 'themes';
import {
  ATHLETE,
  BODY_1,
  BODY_2,
  BOLD,
  H3,
  H4,
  H5,
  H6,
  ORANGE,
} from 'constant/constants';
import ButtonComponent from 'components/atoms/ButtonComponent';
import 'screen/auth/registration/styles/PartnerActivationRegistration.css';
import { isMobile, isTablet } from 'react-device-detect';
import AccordionComponent from 'components/atoms/AccordionComponent';
import DoubleLogo from './components/DoubleLogo';
import ClickableTextComponent from 'components/atoms/ClickableTextComponent';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'utils/firebaseInit';
import { replaceBracketInTranslate } from 'utils/utils';
import { getUserInfoFromId } from 'services/userApi';
import { updateProfileStore } from 'slices/userSlice';
import { useDispatch } from 'react-redux';
import { getAuth } from 'firebase/auth';

const yup = require('yup');

function PartnerActivationRegistration({ partner }) {
  const location = useLocation();
  const [promoCode, setPromoCode] = useState('');
  const [promoCodeValidity, setPromoCodeValidity] = useState({ success: true });
  const [openAccordion, setOpenAccordion] = useState(false);

  const dispatch = useDispatch();


  const navigate = useNavigate();

  useEffect(() => {
    mpTrack(`${partner?.partnerName} Activation Registration Page Viewed`);
  }, []);

  const checkPromoCodeValidity = async promocodeId => {
    if (promocodeId === null || promocodeId === undefined || promocodeId === '') {
      setPromoCodeValidity({ success: false, message: 'Invalid promo code' });
      return;
    }
    try {
      const checkPromoCode = httpsCallable(functions, 'checkPromoCodeValidity');
      const response = await checkPromoCode({ promoCodeId: promocodeId });
      setPromoCodeValidity(response.data);
    } catch (err) {
      console.error('Error checking promo code:', err);
      setPromoCodeValidity(err?.message);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const promo = params.get('promo-code');
    if (promo) {
      checkPromoCodeValidity(promo);
      setPromoCode(promo);
    } else {
      setPromoCodeValidity({ success: false, message: 'Invalid promo code' });
    }
  }, [location]);

  const userSchema = yup.object({
    name: yup.string().required(translate('NameRequired')),
    email: yup
      .string()
      .email(translate('AuthErrorInvalidEmail'))
      .required(translate('EmailRequired')),
    password: yup
      .string()
      .required(translate('PasswordRequired'))
      .min(6, translate('AuthErrorWeakPassword')),
    repeatPassword: yup
      .string()
      .required(translate('RepeatPassword'))
      .oneOf([yup.ref('password')], translate('PasswordRepeatMatch')),
  });

  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    password: '',
    repeatPassword: '',
  });

  const [formErrors, setFormErrors] = useState({});

  const handleChange = e => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
    if (formErrors[e.target.name]) {
      setFormErrors({
        ...formErrors,
        [e.target.name]: '',
      });
    }
  };

  const handleToggleAccordion = isOpen => {
    setOpenAccordion(isOpen); // Keep track of user interaction with the accordion
  };

  const handleSubmit = async e => {
    if (e) e.preventDefault();
    try {
      await userSchema.validate(formValues, { abortEarly: false });
      createUser(formValues?.email, formValues?.password).then(res => {
        if (res !== undefined) {
          if (res?.user?.uid === undefined) {
            setOpenAccordion(true);
            setFormErrors({
              email: res,
            });
            return;
          }
          createUserInDBAndAddUsages({
            emailAddress: formValues?.email,
            fullName: formValues?.name,
            registered: false,
            registerDate: new Date().toISOString(),
            platform: 'Web app user',
            partnerName: partner?.partnerName,
            language: USER_LOCALE,
            onlyUserType: partner?.permissions,
          }).then(async () => {
            const auth = getAuth();
            await getUserInfoFromId(auth?.currentUser?.uid)
              .then(data => {
                if (!data) return;
                const userData = data;
                userData.userID = auth?.currentUser?.uid;
                userData.userType = userData?.type;
                dispatch(updateProfileStore(userData));
                mpIdentify(userData, { partnerName: partner?.partnerName });
                mpTrack(partner?.partnerName + ' Registration')
              })

            navigate(`/ochy-${partner?.id}-activation-success`, {
              state: { promoCode: promoCode, userID: auth?.currentUser?.uid, promoFrom: 'registration', isSSO: false },
            });
          });
        }
      });
    } catch (err) {
      if (err.inner) {
        setOpenAccordion(true);
        const errors = {};
        err.inner.forEach(error => {
          errors[error?.path] = error?.message;
        });
        setFormErrors(prevErrors => ({
          ...prevErrors,
          ...errors,
        }));
      }
    }
  };

  const getTextVariant = () => {
    const screenWidth = window.innerWidth;
    if (isMobile) {
      return H5; // Small text for small mobile devices
    } else if (isTablet) {
      return H5; // Medium size text for tablets
    } else if (screenWidth > 1024 && screenWidth <= 1440) {
      return H4; // Larger text for 13-inch screens or medium-sized desktops
    } else {
      return H3; // Extra-large text for very large screens
    }
  };

  const textInputRendering = () => {
    return (
      <div style={{ marginTop: 20 }}>
        <BlackTextInput
          id="nameTextInput"
          name="name"
          title={translate('NameAndLastName')}
          label={translate('NameAndLastName')}
          value={formValues?.name}
          onChange={handleChange}
          schema={userSchema}
          error={!!formErrors?.name}
          helperText={formErrors?.name}
          handleSubmit={() => {
            document.getElementById('emailTextInput').focus();
          }}
        />

        <div
          style={{
            marginTop: 15,
          }}>
          <BlackTextInput
            id="emailTextInput"
            name="email"
            title={translate('EmailAdress')}
            label={translate('EmailAdress')}
            value={formValues?.email}
            onChange={handleChange}
            schema={userSchema}
            error={!!formErrors?.email}
            helperText={formErrors?.email}
            handleSubmit={() => {
              document.getElementById('passwordTextInput').focus();
            }}
          />
        </div>

        <div
          style={{
            marginTop: 15,
          }}>
          <BlackTextInput
            id="passwordTextInput"
            name="password"
            title={translate('Password')}
            label={translate('Password')}
            type="password"
            value={formValues?.password}
            onChange={handleChange}
            schema={userSchema}
            error={!!formErrors?.password}
            helperText={formErrors?.password}
            handleSubmit={() => {
              document.getElementById('repeatPasswordTextInput').focus();
            }}
          />
        </div>

        <div
          style={{
            marginTop: 15,
          }}>
          <BlackTextInput
            id="repeatPasswordTextInput"
            name="repeatPassword"
            title={translate('RepeatPassword')}
            label={translate('RepeatPassword')}
            type="password"
            value={formValues?.repeatPassword}
            onChange={handleChange}
            schema={userSchema}
            error={!!formErrors?.repeatPassword}
            helperText={formErrors?.repeatPassword}
            handleSubmit={() => {
              if (!promoCodeValidity?.success) return;
              handleSubmit();
            }}
          />
        </div>
      </div>
    );
  };


  return (
    <div style={{ width: '95%', margin: '0 auto', paddingTop: 20 }}>
      <div className="responsive-layout">
        <DoubleLogo
          firstLogo={images.OCHY_LOGO_VERTICAL_WHITE}
          secondLogo={partner?.partnerImageWhite}
        />

        <div className="form-container">
          <TextComponent
            text={translate('Welcome') + '!'}
            sx={{ marginTop: 0, marginBottom: 4 }}
            variant={getTextVariant()}
            weight={BOLD}
            className={'title'}
          />
          <>
            {isMobile || isTablet ? (
              <AccordionComponent
                open={openAccordion}
                onToggle={handleToggleAccordion}
                buttonContent={
                  <TextComponent text={translate('EmailSignUp')} variant={H6} />
                }>
                {textInputRendering()}
              </AccordionComponent>
            ) : (
              <>
                <LinedTextComponent text={translate('EmailSignUp')} />
                {textInputRendering()}
              </>
            )}
          </>
          <div style={{ marginTop: 20 }} />
          <LinedTextComponent text={translate('Or')} />
          <div className="sso-buttons-container">
            <SsoButtons
              disabled={!promoCodeValidity?.success}
              register={true}
              affiliate={true}
              partner={partner}
              callBack={(auth) => {
                navigate(`/ochy-${partner?.id}-activation-success`, {
                  state: { promoCode: promoCode, userID: auth?.currentUser?.uid, promoFrom: 'registration', isSSO: true },
                });
              }}
            />
          </div>
          <div style={{ marginTop: 30 }} />
          <TextComponent text={replaceBracketInTranslate(translate('PartnerPromoCode'), 'partnerName', partner?.partnerName)} variant={BODY_1} />
          <div
            style={{
              backgroundColor: colors.BLACK,
              border: promoCodeValidity?.success
                ? `2px solid ${colors.GREEN_FLASH}`
                : `2px solid ${colors.RED}`,
              padding: 10,
              display: 'flex',
              borderRadius: 10,
              width: '95%',
              alignItems: 'center',
            }}>
            <TextComponent text={promoCode} variant={BODY_1} />
          </div>
          {promoCodeValidity?.success === false && (
            <TextComponent
              text={translate(promoCodeValidity?.message)}
              variant={BODY_1}
              sx={{ color: colors.RED }}
            />
          )}

          <ButtonComponent
            sx={{ width: '100%', marginTop: 3 }}
            title={translate('RegisterHere')}
            type={ORANGE}
            disabled={!promoCodeValidity?.success}
            onClick={() => {
              handleSubmit();
            }}
          />

          <div
            style={{
              marginTop: 20,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <TextComponent
              text={translate('AccountExists')}
              sx={{ marginRight: 1 }}
              variant={BODY_2}
            />
            <ClickableTextComponent
              title={translate('LoginHere')}
              onPress={() => {
                navigate(`/ochy-${partner?.id}-login?promo-code=` + promoCode);
              }}
              option={{ color: colors.SECONDARY }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnerActivationRegistration;
