import React from 'react';
import { ReactComponent as Close } from 'assets/svg/close.svg';
import 'screen/auth/registration/styles/PartnerActivationRegistration.css';

function DoubleLogo({ firstLogo, secondLogo }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 20,
      }}
      className="logo-container">
      <img src={firstLogo} alt={'Ochy white logo'} className="logo" />
      <Close stroke="white" className="cross" />
      <img src={secondLogo} alt={'Logo partner'} className="logo" />
    </div>
  );
}

export default DoubleLogo;
