import TextComponent from "components/atoms/TextComponent";
import ButtonBackAndNext from "components/molecules/ButtonBackAndNext";
import { goodVideoTutoContent, uploadAnalysisTypeList } from "constant/uploadContant";
import React, { useEffect, useState } from "react";
import BodyContainerContentRecapDisplay from "screen/analysis/AnalysisViewDetailsScreen/components/analysisRightPanelUpdated/BodyContainerContentRecapDisplay";
import { translate } from "services/remoteConfig";
import { colors } from "themes";
import UploadPreview from "./UploadPreview";

function GoodVideoTuto({ item, analysisTypeButtonPress, onboarding = {} }) {
    const [step, setStep] = useState(0);
    const [content, setContent] = useState(
        goodVideoTutoContent[item?.key || 0],
    );


    useEffect(() => {
        setContent(goodVideoTutoContent[item?.key || 0]);
        setStep(0);
    }
        , [item]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div style={styles.container}>
                {step === 0 ? (
                    <UploadPreview item={uploadAnalysisTypeList[item.key]} />
                ) : (
                    <>
                        <TextComponent
                            text={translate(content?.[step - 1]?.title)}
                            sx={styles.stepTitle}
                        />

                        <div style={styles.rowContainer}>
                            <img
                                src={content?.[step - 1]?.asset || ''}
                                alt={content?.[step - 1]?.title || 'Step Image'}
                                style={styles.stepAsset}
                            />

                            <div style={styles.recap}>
                                <BodyContainerContentRecapDisplay
                                    score={'Primary'}
                                    item={content?.[step - 1]?.importantText}
                                    coloredText={true}
                                    multipleLines={true}
                                />
                                <div style={content?.[step - 1]?.importantText?.text?.length > 0 ? { marginTop: '-4vh' } : {}}>
                                    <BodyContainerContentRecapDisplay
                                        score={'score_good'}
                                        item={content?.[step - 1]?.recapText}
                                        coloredText={true}
                                        multipleLines={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}

            </div >
            <div style={styles.arrows}>
                <ButtonBackAndNext
                    disabled={{
                        showNext: step === content?.length + 1,
                        disabledBack: step === 0 && !onboarding,
                    }}
                    step={step}
                    goToNextStep={() => {
                        if (step === content?.length) {
                            if (onboarding?.goToNextStep) {
                                onboarding?.goToNextStep();
                                return;
                            }

                            if (!analysisTypeButtonPress) {
                                return;
                            }

                            analysisTypeButtonPress(
                                item
                            );
                        } else {
                            setStep(prev => prev + 1);
                        }
                    }}
                    goToPreviousStep={() => {
                        if (onboarding?.goToPreviousStep) {
                            if (step === 0) {
                                setStep(0);
                                setContent(null);
                                onboarding?.goToPreviousStep();
                                return;
                            }
                        }
                        setStep(prev => prev - 1);
                    }}
                    button={{
                        disabled: step !== content?.length,
                        title: analysisTypeButtonPress
                            ? translate('LetsStart')
                            : translate('GotIt'),
                    }}
                />
            </div>
        </div>
    );
}

const styles = {
    arrows: {
        width: '98%',
        marginBottom: '2vh',
        display: 'flex',
        justifyContent: 'center'
    },
    container: {
        minHeight: '64vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '2vh',
        maxWidth: '65vw',
        marginRigth: '2vw',
    },
    recap: {
        display: 'flex',
        flexDirection: 'column',
        width: '40%',
        alignSelf: 'center',
        height: '50vh',
        justifyContent: 'center',
    },
    rowContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '95%',
        alignSelf: 'center'
    },
    stepTitle: {
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '15px',
        color: colors.WHITE_OPACITY_70,
    },
    stepAsset: {
        height: '50vh',
        width: '60%',
        objectFit: 'contain',
    },
};

export default GoodVideoTuto;
