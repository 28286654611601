import React, { useEffect, useState } from 'react';
import { colors, images } from 'themes';
import Ruler from '../analysisRightPanelUpdated/Ruler';
import MultipleButton from 'components/molecules/MultipleButton';
import { translate } from 'services/remoteConfig';
import BodyContainerContentRecapDisplay from '../analysisRightPanelUpdated/BodyContainerContentRecapDisplay';
import TextComponent from 'components/atoms/TextComponent';
import { BODY_1, BODY_2, BODY_3, BOLD, H5, H6 } from 'constant/constants';
import CardWithRoundedIcon from 'components/molecules/CardWithRoundedIcon';
import { ReactComponent as Advice } from 'assets/svg/advice.svg';


function PronationAnalysisPanelUpdated({ analysisData, angle, side, json_version }) {
  const [phase, setPhase] = useState('Recap');
  const [phaseContent, setPhaseContent] = useState(null);
  const config = [
    {
      title: 'Recap',
      text: analysisData?.texts?.recap,
      score: analysisData?.texts?.score,
    },
    {
      title: translate('Consequences'),
      text: analysisData?.texts?.segmented_explanation?.consequences,
      score: analysisData?.texts?.score,
    },
    analysisData?.texts?.segmented_explanation?.correction
      ? {
        title: translate('Correction'),
        text: analysisData?.texts?.segmented_explanation?.correction,
        score: analysisData?.score,
      }
      : null,
  ].filter(Boolean);

  useEffect(() => {
    setPhaseContent(config?.find(item => item?.title === phase));
  }, [phase, analysisData]);

  return (
    <div style={styles.footContainer}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: '3rem',
          marginTop: 20,
        }}>
        <TextComponent
          text={translate(analysisData?.texts?.side)}
          variant={BODY_2}
          sx={{ color: colors?.WHITE_OPACITY_60 }}
        />
        <div style={styles.footIcon}>
          <img alt="" src={images[analysisData?.asset_id]} />
        </div>
        <TextComponent text={json_version < 2 ? analysisData?.texts?.title : analysisData?.texts?.position} variant={H6} />
      </div>
      <TextComponent
        text={translate('MeanFootAngleStance')}
        sx={{ marginBottom: 3 }}
        variant={BODY_2}
      />
      <div style={{ marginBottom: 40 }}>
        <Ruler
          score={analysisData?.texts?.score}
          angle={angle}
          rangeGood={analysisData?.range_good}
          rangeOkay={analysisData?.range_okay}
        />
      </div>
      <div
        style={{
          marginBottom: 40,
          width: '95%',
          margin: 'auto',
          alignSelf: 'center',
        }}>
        <MultipleButton
          config={config}
          navigation={phase}
          setNavigation={setPhase}
        />
        <BodyContainerContentRecapDisplay
          item={phaseContent}
          score={phaseContent?.score}
          multipleLines={true}
        />
        <div
          style={{
            marginTop: 40,
          }}>
          <CardWithRoundedIcon icon={Advice}>
            <TextComponent
              text={translate('Definition')}
              weight={BOLD}
              style={styles.definition}
            />
            <TextComponent
              text={analysisData?.texts?.explanation || ''}
              variant={BODY_2}
              style={styles.explanation}
            />
            /
          </CardWithRoundedIcon>
        </div>
      </div>
    </div>
  );
}
const styles = {
  footContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    flex: 1,
  },
  footIcon: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },
};
export default PronationAnalysisPanelUpdated;
