import TextComponent from 'components/atoms/TextComponent';
import { BODY_1, MEDIUM } from 'constant/constants';
import React, { useEffect, useState } from 'react';
import VideoComponent from 'screen/analysis/AnalysisViewDetailsScreen/components/VideoComponent';
import { translate } from 'services/remoteConfig';
import { colors } from 'themes';


function UploadPreview({ item }) {
    const [videoKey, setVideoKey] = useState(item?.exempleVideo);

    useEffect(() => {
        setVideoKey(item?.exempleVideo);
    }, [item]);

    return (
        <div style={styles.container}>
            <TextComponent text={translate(item?.title)} sx={styles.title} />
            <TextComponent
                text={translate(item?.subtitle)}
                variant={BODY_1}
                sx={styles.subtitle}
            />
            <TextComponent text={translate(item?.desc)} sx={styles.desc} />
            <div style={styles.videoContainer}>
                <VideoComponent
                    key={videoKey}
                    video={videoKey}
                    speed={1}
                    loop={true}
                    videoStyle={styles.videoHeight}
                    controls={false}
                />
            </div>
            <TextComponent
                text={translate('AnalysisTypeExempleVideoDesc')}
                weight={MEDIUM}
                sx={styles.videoDesc}
            />
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    desc: {
        marginLeft: '2vw',
        fontSize: '1vw'
    },
    subtitle: {
        color: colors.WHITE_OPACITY_70,
        marginLeft: '2vw',
        fontSize: '1vw',
        marginBottom: '2vh'
    },
    title: {
        marginLeft: '2vw',
        fontSize: '1.4vw'
    },
    videoContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignSelf: 'center',
        marginTop: '2vh',
        marginBottom: '2vh',
        justifyContent: 'center',
        alignItems: 'center'
    },
    videoDesc: {
        color: colors.WHITE_OPACITY_70,
        marginLeft: '2vw',
        fontSize: '0.7vw'
    },
    videoHeight: {
        height: '40vh'
    }
}


export default UploadPreview;