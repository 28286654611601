import React, { useContext } from 'react';

import FadinView from 'components/atoms/FadinView';
import RadialGradient from 'components/atoms/RadialGradient';
import OnBoardingBottomArrow from './OnBoardingBottomArrow';

import { RegistrationContext } from 'contexts/RegistrationContext';

function OnBoardingContainer({ children, radialGradient, previous, next }) {
  const { goToNextStep, goToPreviousStep, combinedStack } = useContext(RegistrationContext);

  return <div style={styles.container}>
    {radialGradient && <RadialGradient startOpacity={0.3}
      endOpacity={0}
      radius={'80%'}
      centerX="50%"
      centerY="25%"
      color="#78BD32"
      numberOfStops={5} />}
    <FadinView style={styles.fadInView}>
      {children}
    </FadinView>
    {next && <OnBoardingBottomArrow onPress={goToNextStep} />}
    {previous && <OnBoardingBottomArrow previous={true} onPress={goToPreviousStep} />}
  </div>;
}
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
  },
  fadInView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '70%',
    margin: '0 auto',
    flexDirection: 'column',
    zIndex: 2,
    height: '100%',
    marginBottom: '1vh',
  }
};
export default OnBoardingContainer;
