import React, { useMemo } from 'react';

import TextComponent from 'components/atoms/TextComponent';

import {translate} from 'services/remoteConfig';
import {colors} from 'themes';

export default function ProductCardHeader({
  displayedPrice,
  selected,
  currency,
  product,
}) {
  // Use useMemo to prevent unnecessary recalculations
  const priceDisplay = useMemo(() => {
    if (product.type === 'OnDemand') return translate('Enterprise');

    if (!displayedPrice) return '';

    if (displayedPrice.type === 'one_time') {
      const curr = displayedPrice?.currency === 'eur' ? '€' : '$';
      const amount = displayedPrice.unit_amount / 100;
      return displayedPrice?.currency === 'usd' ? curr + amount : amount + curr;
    }
    
    // If we have a price from Stripe
    if (
      displayedPrice?.currency &&
      typeof displayedPrice === 'object' &&
      displayedPrice.unit_amount
    ) {
      const curr = displayedPrice?.currency === 'eur' ? '€' : '$';
      const amount = (
        displayedPrice.unit_amount /
        100 /
        (selected ? 12 : 1)
      ).toFixed(0);
      return displayedPrice?.currency === 'usd' ? curr + amount : amount + curr;
    }
    return '';
  }, [displayedPrice, selected, product.type]);

  const subtitle = useMemo(() => {
    if (product.type === 'OnDemand') {
      return translate('TailoredToYourNeeds');
    }

    if (displayedPrice && typeof displayedPrice === 'object') {
      if (displayedPrice.type === 'one_time') {
        return translate('OneTimePayment');
      }

      if (displayedPrice.recurring) {
        return displayedPrice.interval === 'year'
          ? translate('BilledYearly')
          : translate('BilledMonthly');
      }
    }

    return selected ? translate('BilledYearly') : translate('BilledMonthly');
  }, [displayedPrice, selected, product.type]);

  // Use useMemo for occurrence text
  const occurrenceText = useMemo(() => {
    if (!displayedPrice) return '';
    
    return (
      displayedPrice.type === 'one_time' ||
      displayedPrice.type === 'OnDemand'
        ? ''
        : '/ ' + translate('MonthLowCap')
    );
  }, [displayedPrice]);

  return (
    <>
      <TextComponent
        variant="h3"
        text={translate(product?.metadata?.name)}
        sx={styles.productName}
      />
      <div style={styles.priceContainer}>
        <TextComponent
          variant="h5"
          text={priceDisplay}
          sx={styles.priceText}
        />
        <TextComponent
          variant="body1"
          text={occurrenceText}
          sx={styles.textOccurence}
        />
      </div>
      <TextComponent variant="h6" text={subtitle} sx={styles.subtitle} />
    </>
  );
}

const styles = {
  priceContainer: {
    flexDirection: 'row',
    display: 'flex',
  },
  priceText: {
    textAlign: 'left',
    marginTop: 2,
    fontWeight: 'bold',
    marginLeft: 5,
    transition: 'all 0.3s ease', // Add smooth transition
  },
  productName: {
    textAlign: 'left',
    marginTop: 5,
    marginLeft: 5,
  },
  subtitle: {
    textAlign: 'left',
    color: colors.WHITE_OPACITY_60,
    marginLeft: 5,
    transition: 'all 0.3s ease', // Add smooth transition
  },
  textOccurence: {
    textAlign: 'left',
    alignSelf: 'flex-end',
    transition: 'all 0.3s ease', // Add smooth transition
  },
};
