import TextComponent from 'components/atoms/TextComponent';
import { BODY_2, BODY_3, BOLD, RIGHT } from 'constant/constants';
import React from 'react';
import { score_colors } from 'screen/analysis/AnalysisListCard/constants/analysisListConstants';
import { translate } from 'services/remoteConfig';

function BodyContainerContentRecapDisplay({ item, score, multipleLines = false, side, coloredText = false, }) {
  if (!item || item?.text?.length === 0) return null;
  return (
    <div style={styles.sliderContainer}>
      <div style={styles.sliderCard}>
        {item?.asset && (
          <div style={styles.impactedAreas}>
            <TextComponent
              text={translate('ImpactedAreas')}
              variant={BODY_3}
              sx={{
                ...styles.impacted,
                color: score_colors[score].stroke,
                ...(side === RIGHT ? styles.R0 : styles.L0),
              }}
            />
            <img
              src={item?.asset}
              style={styles.image}
              alt="Impacted Areas"

            />
          </div>
        )}
        {Array.isArray(item?.text) ? (
          <div>
            {item?.text.map((recapItem, recapIndex) => (
              <div
                style={{
                  ...styles.recapContainer,
                  ...(multipleLines ? {} : { alignItems: 'center' }),
                }}
                key={recapIndex}>
                <div
                  style={{
                    ...styles.recapDotBackground,
                    backgroundColor: score_colors[score].scoreBackground,
                    ...(multipleLines ? { marginTop: 4 } : {}),
                  }}>
                  <div
                    style={{
                      ...styles.recapDot,
                      backgroundColor: score_colors[score].stroke,
                    }}
                  />
                </div>
                <TextComponent
                  key={recapIndex}
                  text={translate(recapItem) || recapItem}
                  sx={{ ...styles.recapText, ...(coloredText && { color: score_colors[score].stroke }) }}
                  variant={BODY_2}
                />
              </div>
            ))}
          </div>
        ) : (
          <div>
            <TextComponent
              text={item.text}
              variant={BODY_2}
              sx={{ textAlign: 'left', marginBottom: '2vh' }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const styles = {
  impactedAreas: {
    height: '30vh',
    marginBottom: '3vh',
    position: 'relative',
  },
  L0: {
    left: '2vw',
  },
  R0: {
    right: '2vw',
  },
  image: {
    alignSelf: 'center',
    height: '100%',
    marginBottom: 10,
    objectFit: 'contain',
    width: '100%',
  },
  impacted: {
    position: 'absolute',
    top: '50%',
  },
  recapContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: 5,
    width: '100%',
  },
  recapDot: {
    borderRadius: '50%',
    height: 5,
    width: 5,
  },
  recapDotBackground: {
    alignItems: 'center',
    borderRadius: '50%',
    display: 'flex',
    height: 12,
    justifyContent: 'center',
    width: 12,
  },
  recapText: {
    marginLeft: '0.8vw',
    textAlign: 'left',
    width: '100%'

  },
  sliderCard: {
    padding: 15,
    borderRadius: 1,
    width: '90%',
  },
  sliderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '1vh',
  },
  text: {
    marginBottom: 2,
    textAlign: 'left',
    fontWeight: BOLD,
  },
};

export default BodyContainerContentRecapDisplay;
