import React, { useEffect} from 'react';
import Lottie from 'lottie-react';

import './styles/AnalysisProgressCard.css';
import TextComponent from 'components/atoms/TextComponent';
import { BODY_2, BODY_3, FAILED, MEDIUM, SUCCESS } from 'constant/constants';
import { translate } from 'services/remoteConfig';
import { colors } from 'themes';
import { useAnalysisListener } from 'hooks/useAnalysisChanges';
import { updateListAnalysisDataItem } from 'slices/analysisSlice';
import { useDispatch } from 'react-redux';
import { getContentForBottomCard } from 'utils/analysis';


function AnalysisProgressCard({ id, analysisFromStore, setStatus }) {

  const dispatch = useDispatch();

  const { analysis } = useAnalysisListener(id, analysisFromStore?.data?.locked, true);

  useEffect(() => {
    if (analysis?.status === SUCCESS) {
      if (analysis?.analysis_type === 'pronation' && analysis?.json_version > 1) {
        dispatch(updateListAnalysisDataItem({ ...analysis, analysis_content: getContentForBottomCard(analysis?.analysis_content, analysis?.data?.tag?.full_body) }));
      }

      else dispatch(updateListAnalysisDataItem(analysis));
      setStatus(SUCCESS);

    }
    else if (analysis?.status === FAILED) {
      setStatus(FAILED);
    }
  }, [analysis?.status]);

  function handleRenderingProgress() {
    if ((analysis?.status === 'pending' && analysis?.percentage < 5) || analysis?.percentage === 1) {
      return (
        <TextComponent
          text={translate('AnalysisInProgress')}
          sx={styles.progressText}
          variant={BODY_3}
        />
      );
    }
    if (analysis?.percentage >= 90) {
      return (
        <TextComponent
          sx={styles.progressText}
          variant={BODY_3}
          text={translate('FinalizeAnalysis')}
        />
      );
    } else {
      return (
        <TextComponent
          text={analysis?.percentage + '%'}
          variant={BODY_2}
          weight={MEDIUM}
          sx={styles.progressText}
        />
      );
    }
  }
  return (
    <div className="loadingCard-container">
      <div
        className="loadingCard-imageHeight"
        style={{
          backgroundImage: `url(${require('assets/images/loadingBlur.png')})`
        }}
      >
        <Lottie
          animationData={require('assets/animations/loading_runner.json')}
          loop={true}
          style={styles.lottie}
        />
      </div>
      {(analysis?.percentage && parseFloat(analysis?.percentage) <= 100)
        ? handleRenderingProgress()
        : null}
    </div>
  );
}

export default AnalysisProgressCard;

const styles = {
  finalizeAnalysis: {
    color: colors.BLACK,
    margin: 10,
    marginLeft: 15,
    marginRight: 15,
    textAlign: 'center'
  },
  progressText: {
    display: 'flex',
    flexDirection: 'column',
    color: colors?.SECONDARY,
    textAlign: 'center',
  },
  lottie: {
    margin: 'auto',
    width: '50%'
  }
};
