import React from 'react';

import TextComponent from 'components/atoms/TextComponent';
import {translate} from 'services/remoteConfig';

export default function SubscriptionPageHeader({usage}) {
  return (
    <div style={styles.titlesContainer}>
      <TextComponent
        variant="h3"
        text={
          usage?.monthlyPlan === true || usage?.yearlyPlan === true
            ? translate('ManageSubscription')
            : translate('PurchaseSubscription')
        }
        sx={styles.title}
      />
    </div>
  );
}

const styles = {
  subtitle: {
    textAlign: 'center',
    marginTop: 2,
  },
  title: {
    textAlign: 'center',
  },
  titlesContainer: {
    marginTop: 60,
  },
};
