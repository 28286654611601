import {getAuth} from 'firebase/auth';
import {getFirestore} from 'firebase/firestore';
import {getRemoteConfig} from 'firebase/remote-config';
import {getStorage} from 'firebase/storage';
import {initializeApp} from 'firebase/app';
import firebaseConfig from 'config/firebaseConfig.json';
import {getFunctions} from 'firebase/functions';

export const firebase = initializeApp(firebaseConfig);
export const firestore = getFirestore(firebase);
export const auth = getAuth(firebase);
export const storage = getStorage(firebase);
export const remoteConfig = getRemoteConfig(firebase);
remoteConfig.settings = {
  minimumFetchIntervalMillis: 43200000, // 12 hours
  fetchTimeoutMillis: 30000, // 30 seconds
};
export const functions = getFunctions(firebase, 'europe-west3');
